import { getByDottedDiff } from "../../common/utils/jsonDiff";
import React, { createContext, FunctionComponent, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useJSONDiff } from "./JSONDiffContext";

export const JSONDiffRowSection: FunctionComponent<{
  children: ReactNode;
  title: string;
}> = ({ children, title }) => (
  <div className="diff-section">
    <h5 className="text-left section-title font-weight-bold">{title}</h5>
    <div className="diff-section-items">{children}</div>
  </div>
);

export interface JSONDiffRowProps {
  label: string;
  newData?: any;
  oldData?: any;
  path: string;
  diffData?: any;
  transform?: (diffData?: any) => any;
  wrapper?: FunctionComponent<{ children: ReactNode }>;
}

export interface JSONDiffRowContextValue {
  newData: any;
  oldData: any;
}

const JSONDiffRowContext = createContext<JSONDiffRowContextValue | null>(null);
export const useJSONDiffRow = ({
  path,
  transform,
}: {
  path: string;
  transform?: (diffData?: any, prevData?: any, newData?: any) => any;
}) => {
  const { diffData, delta, currData, prevData } = useJSONDiff();

  let newData, oldData;
  if (path) {
    ({ __new: newData, __old: oldData } = getByDottedDiff(diffData, path));
  }
  newData = transform?.(diffData, prevData, currData)?.__new ?? newData;
  oldData = transform?.(diffData, prevData, currData)?.__old ?? oldData;
  const showField = Boolean(delta && oldData === newData);

  return { newData, oldData, diffData, showField };
};

export const JSONDiffRowProvider: FunctionComponent<{
  children: ReactNode;
  value: JSONDiffRowContextValue;
}> = ({ children, value }) => {
  return (
    <JSONDiffRowContext.Provider value={value}>
      {children}
    </JSONDiffRowContext.Provider>
  );
};

export const JSONDiffRow: FunctionComponent<JSONDiffRowProps> = ({
  label,
  path,
  transform,
  wrapper: Wrapper = ({ children }) => <>{children}</>,
}) => {
  const { newData, oldData, showField } = useJSONDiffRow({ transform, path });
  const { t } = useTranslation();

  const value = { newData, oldData };

  const DataView: FunctionComponent<{ children: any }> = ({
    children: data,
  }) => {
    if (data == null || data === "") {
      return <>N/A</>;
    }

    const reSpace = /(^\s+)|(\s+$)/g;
    let dataStr = String(data);
    // Replace all spaces and tab characters with printable characters
    dataStr = dataStr.replace(reSpace, (sp) => {
      return sp.replaceAll("⇥", "⇥").replaceAll(" ", "␠");
    });
    if (Array.isArray(data)) {
      dataStr = `[${data.join(", ")}]`;
    }

    return <Wrapper>{dataStr}</Wrapper>;
  };

  return (
    <JSONDiffRowProvider value={value}>
      {showField ? null : (
        <div className="row pt-1">
          <div className="col-2 modal-report-log-sub-labels">{t<string>(label)}</div>
          <div className="col-1">:</div>
          <div className="col-4 p-0 modal-report-log-sub-values">
            <DataView>{oldData?.replace(/,/g, ", ")}</DataView>
          </div>
          <div className="col-4 p-0 modal-report-log-sub-values">
            <DataView>{newData?.replace(/,/g, ", ")}</DataView>
          </div>
        </div>
      )}
    </JSONDiffRowProvider>
  );
};
