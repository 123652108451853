import * as constants from "common/constants"

export const breadcrumbRoutes = {
  viewVendor: [
    { name: "Vendors", link: "/vendors" },
    { name: "View Vendor", link: "" },
  ],
  addVendor: [
    { name: "Vendors", link: "/vendors" },
    { name: "Add Vendor", link: "" },
  ],
  editVendor: [
    { name: "Vendors", link: "/vendors" },
    { name: "Edit Vendor", link: "" },
  ],
  viewAccreditation: [
    { name: "Accreditations", link: "/accreditations" },
    { name: "View Accreditation", link: "" },
  ],
  addAccreditation: [
    { name: "Accreditations", link: "/accreditations" },
    { name: "Add Accreditation", link: "" },
  ],
  editAccreditation: [
    { name: "Accreditations", link: "/accreditations" },
    { name: "Edit Accreditation", link: "" },
  ],
  viewCertificate: [
    { name: "Certificates", link: "/certificates" },
    { name: "View Certificates", link: "" },
  ],
  addCertificate: [
    { name: "Certificates", link: "/certificates" },
    { name: "Add Certificates", link: "" },
  ],
  editCertificate: [
    { name: "Certificates", link: "/certificates" },
    { name: "Edit Certificates", link: "" },
  ],
  viewClassification: [
    { name: "Classifications", link: "/classifications" },
    { name: "View Classification", link: "" },
  ],
  addClassification: [
    { name: "Classifications", link: "/classifications" },
    { name: "Add Classification", link: "" },
  ],
  editClassification: [
    { name: "Classifications", link: "/classifications" },
    { name: "Edit Classification", link: "" },
  ],
  editProgram: [
    { name: "Programs", link: "/programs" },
    { name: "Edit Program", link: "" },
  ],
  viewProgram: [
    { name: "Programs", link: "/programs" },
    { name: "View Program", link: "" },
  ],
  addProgram: [
    { name: "Programs", link: "/programs" },
    { name: "Add Program", link: "" },
  ],
  editUser: [
    { name: "User Management", link: "/users" },
    { name: "Edit User", link: "" },
  ],
  viewUser: [
    { name: "User Management", link: "/users" },
    { name: "View User", link: "" },
  ],
  addUser: [
    { name: "User Management", link: "/users" },
    { name: "Add User", link: "" },
  ],
  addLearner: [
    { name: "Learners", link: "/learners" },
    { name: "Add Learner", link: "" },
  ],
  editLearner: [
    { name: "Learners", link: "/learners" },
    { name: "Edit Learner", link: "" },
  ],
  viewLearner: [
    { name: "Learners", link: "/learners" },
    { name: "View Learner", link: "" },
  ],
  viewActivity: [{ name: "View Activity", link: "" }],
  viewActivityLog: [
    { name: "Activity Log", link: constants.Routes.ACTIVITY_LOG_LIST_PAGE },
    { name: "View Activity Log", link: "" },
  ],
  addScheduler: [
    { name: "Scheduler List", link: "/schedulers" },
    { name: "Add Report Scheduler", link: "" },
  ],
  editScheduler: [
    { name: "Scheduler List", link: "/schedulers" },
    { name: "Edit Report Scheduler", link: "" },
  ],
  viewScheduler: [
    { name: "Scheduler List", link: "/schedulers" },
    { name: "View Report Scheduler", link: "" },
  ],
  viewCustomReportScheduler: [
    { name: "Scheduler List", link: "/schedulers" },
    { name: "View Custom Report Scheduler", link: "" },
  ],
  addCustomScheduler: [
    { name: "Scheduler List", link: "/schedulers" },
    { name: "Add Custom Report Scheduler", link: "" },
  ],
  editCustomScheduler: [
    { name: "Scheduler List", link: "/schedulers" },
    { name: "Edit Custom Report Scheduler", link: "" },
  ],
  reportPreview: [
    { name: "Scheduler List", link: "/schedulers" },
    { name: "Report Preview", link: "" },
  ]
};
