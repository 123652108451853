const config: any = {
  env: process.env.REACT_APP_MY_ENV || "dev",
  local: {
    apiEndpoints: {
      account: "http://localhost:8080/account/v1",
      vendor: "http://localhost:8080/admin/v1/",
      auditLog: "http://localhost:8080/report/v1/",
      reporting: "http://localhost:8080/reporting/v1/",
      batch: "http://localhost:8080/batch/v1/",
    },
  },
  dev: {
    apiEndpoints: {
      account: "https://cmeapidev.heartblr.org/account/v1",
      vendor: "https://cmeapidev.heartblr.org/admin/v1/",
      auditLog: "https://cmeapidev.heartblr.org/report/v1/",
      reporting: "https://cmeapidev.heartblr.org/reporting/v1/",
      batch: "https://cmeapidev.heartblr.org/batch/v1/",
    },
  },
  qa: {
    apiEndpoints: {
      account: "https://cmeapiqa.heartblr.org/account/v1",
      vendor: "https://cmeapiqa.heartblr.org/admin/v1/",
      auditLog: "https://cmeapiqa.heartblr.org/report/v1/",
      reporting: "https://cmeapiqa.heartblr.org/reporting/v1/",
      batch: "https://cmeapiqa.heartblr.org/batch/v1/",
    },
  },
  stg: {
    apiEndpoints: {
      account: "https://cme-api-stg.heartblr.org/account/v1",
      vendor: "https://cme-api-stg.heartblr.org/admin/v1/",
      auditLog: "https://cme-api-stg.heartblr.org/report/v1/",
      reporting: "https://cme-api-stg.heartblr.org/reporting/v1/",
      batch: "https://cme-api-stg.heartblr.org/batch/v1/",
    },
  },
  preprod: {
    apiEndpoints: {
      account: "https://cme-api-preprod.heartblr.org/account/v1",
      vendor: "https://cme-api-preprod.heartblr.org/admin/v1/",
      auditLog: "https://cme-api-preprod.heartblr.org/report/v1/",
      reporting: "https://cme-api-preprod.heartblr.org/reporting/v1/",
      batch: "https://cme-api-preprod.heartblr.org/batch/v1/",
    },
  },
  prod: {
    apiEndpoints: {
      account: "https://cme-api.heart.org/account/v1",
      vendor: "https://cme-api.heart.org/admin/v1/",
      auditLog: "https://cme-api.heart.org/report/v1/",
      reporting: "https://cme-api.heart.org/reporting/v1/",
      batch: "https://cme-api.heart.org/batch/v1/",
    },
  },
  dr: {
    apiEndpoints: {
      account: "https://cme-api.heartblr.org/account/v1",
      vendor: "https://cme-api.heartblr.org/admin/v1/",
      auditLog: "https://cme-api.heartblr.org/report/v1/",
      reporting: "https://cme-api.heartblr.org/reporting/v1/",
      batch: "https://cme-api.heartblr.org/batch/v1/",
    },
  },
};

export default config;
