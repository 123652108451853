import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ProgramManagementState {
  pageNumber: number;
  search: string;
  pageSize: number;
  loader: boolean;
  pageOrder: string;
}

export const initialState: ProgramManagementState = {
  pageNumber: 1,
  search: "",
  pageSize: 25,
  loader: false,
  pageOrder: "updated_at DESC",
};

const programManagementSlice = createSlice({
  name: "program",
  initialState,
  reducers: {
    setPageNumber: (state, action: PayloadAction<ProgramManagementState>) => {
      state.pageNumber = action.payload.pageNumber;
    },
    searchProgram: (state, action: PayloadAction<ProgramManagementState>) => {
      state.pageNumber = 1;
      state.search = action.payload.search;
    },
    setPageSize: (state, action: PayloadAction<ProgramManagementState>) => {
      state.pageNumber = 1;
      state.pageSize = action.payload.pageSize;
    },
    setPageOrder: (state, action) => {
      state.pageOrder = action.payload.pageOrder;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
    clearProgramState: () => {
      return initialState;
    },
  },
});
const { actions, reducer } = programManagementSlice;

export const {
  setPageNumber,
  setLoader,
  setPageOrder,
  searchProgram,
  setPageSize,
  clearProgramState,
} = actions;
export default reducer;
