const API_PERMISSIONS = "apiPermissions";
const PERMISSIONS = "permissions";
const FEAT_REPORT_LOG_DIFF = "feat.reportLog.diff";
const ROLE = "role";
const ACCESS_TOKEN = "accessToken"
const REFRESH_TOKEN = "refreshToken"

export const LocalStorageKeys = {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  API_PERMISSIONS,
  PERMISSIONS,
  FEAT_REPORT_LOG_DIFF,
  ROLE,
} as const;
