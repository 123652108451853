import React, { useMemo, useRef, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import AdminHeaderWrapper from "./styled";
import { useTranslation } from "react-i18next";

import SvgCeCmeLogo from "../../components/SvgComponent/CeCmeLogo";
import { showToast } from "components/Toast/toast.slice";
import { Signout } from "services/api/AdminHeader.api";
import store from "app/store";
import { LocalStorageKeys } from "../../common/constants/localStorage";
import { useRBACContext } from "@impelsysinc/react-rbac";

const Global: any = global;

export const AdminHeader = (props: any) => {
  const { t } = useTranslation();

  const signout = async () => {
    const res = await Signout();
    if (res?.status === 200) {
      localStorage.removeItem("Authorization");
      localStorage.removeItem(LocalStorageKeys.ACCESS_TOKEN);
      localStorage.removeItem(LocalStorageKeys.REFRESH_TOKEN);
      localStorage.removeItem(LocalStorageKeys.PERMISSIONS);
      localStorage.removeItem(LocalStorageKeys.API_PERMISSIONS);
      Global.userManager.signoutRedirect();
    } else {
      store.dispatch(
        showToast({
          title: "Signout",
          message: res?.data,
        })
      );
    }
  };

  const { canAccess } = useRBACContext();

  const actions = useMemo(
    () => ({
      list: canAccess({
        resourceType: "component",
        resource: "userManagement",
        action: "get.all",
      }),
    }),
    [canAccess]
  );

  const actionRef = useRef({ ...actions });
  useEffect(() => {
    actionRef.current = actions;
  }, [actions]);

  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 991;

  return (
    <AdminHeaderWrapper
      className="aui-main-header aui-pri-header container"
      style={!isMobile ? { maxWidth: "100%", minWidth: "70%" } : {}}
    >
      <a href="#maincontent" className="aui-skip-content">
        <> {t<string>("skipToMainContent")} </>
      </a>
      <nav className="navbar navbar-expand-lg justify-content-between aui-header-content mx-auto aui-pri-header-t">
        <SvgCeCmeLogo className="main-content-logo logo-main headerLogo-style" />

        <button
          className="navbar-toggler ml-2 header-hamburger"
          type="button"
          data-toggle="collapse"
          data-target="#adminHeaderCollapse"
          aria-controls="adminHeaderCollapse1"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={props.toggle}
        >
          <img
            src={process.env.PUBLIC_URL + "/images/CME_hamburger.svg"}
            alt="content icon hamburger"
            className=""
          />
        </button>
        <div
          className="justify-content-lg-end align-items-baseline collapse navbar-collapse aui-pri-nav"
          id="adminHeaderCollapse1"
        >
          {actionRef.current.list && (
            <NavLink
              to="/users"
              aria-label="User Management"
              className="user-management"
            >
              <> {t<string>("userManagement")}</>
            </NavLink>
          )}
          <ul className="navbar-nav ml-lg-3 flex-lg-row flex-column">
            <li className="d-flex nav-item dropdown pl-lg-3 flex-column">
              <button
                type="button"
                className="btn btn-text dropdown-toggle flex-grow-1 text-left"
                id="accountdropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="pr-1">
                <svg id="Icons-Avatar" xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20"><g id="Path_615_1" data-name="Path 615" fill="none"><path d="M10,0A10,10,0,0,1,20,10a10.47,10.47,0,0,1-.534,3.231A10,10,0,1,1,10,0Z" stroke="none" /><path d="M 10 0.9999961853027344 C 5.037380218505859 0.9999961853027344 1 5.037385940551758 1 10.0000057220459 C 1 14.96262550354004 5.037380218505859 19.00001525878906 10 19.00001525878906 C 11.95411014556885 19.00001525878906 13.81962966918945 18.38884544372559 15.3948802947998 17.23257637023926 C 16.8944206237793 16.13187599182129 18.0007495880127 14.60708618164062 18.51007080078125 12.93909549713135 L 18.51155090332031 12.93431568145752 C 18.85391998291016 11.83283615112305 19 10.95528602600098 19 10.0000057220459 C 19 5.037385940551758 14.96261978149414 0.9999961853027344 10 0.9999961853027344 M 10 -3.814697265625e-06 C 15.52285003662109 -3.814697265625e-06 20 4.477155685424805 20 10.0000057220459 C 20 11.02173614501953 19.84676933288574 12.00767517089844 19.46648025512695 13.23113632202148 C 18.30765914916992 17.02617645263672 14.50111961364746 20.00001525878906 10 20.00001525878906 C 4.477149963378906 20.00001525878906 0 15.52285575866699 0 10.0000057220459 C 0 4.477155685424805 4.477149963378906 -3.814697265625e-06 10 -3.814697265625e-06 Z" stroke="none" fill="#222328" /></g><ellipse id="head1_1" cx="2.536" cy="2.536" rx="2.536" ry="2.536" transform="translate(7.562 5)" fill="none" stroke="#222328" strokeLinecap="round" strokeMiterlimit={10} strokeWidth={1} /><path id="Body1_1" d="M24.325,58.15a3.928,3.928,0,0,0-1.5,3.087h7.879a3.932,3.932,0,0,0-1.494-3.087" transform="translate(-16.671 -47.711)" fill="none" stroke="#222328" strokeLinecap="round" strokeMiterlimit={10} strokeWidth={1} /></svg>
                </span>
                <> {t<string>("myAccount")}</>
              </button>
              <div
                className="dropdown-menu p-lg-0 aui-header-dropdown"
                aria-labelledby="accountdropdown"
              >
                <div
                  onClick={signout}
                  className="dropdown-item py-2"
                  role="button"
                  onKeyUp={signout}
                  tabIndex={0}
                >
                  <> {t<string>("signOut")}</>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </AdminHeaderWrapper>
  );
};
export default AdminHeader;
