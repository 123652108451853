import styled from "styled-components";

const AdminFooterWrapper = styled.footer`
  background-color: #343a40;
  color: #fff;
  padding: 26px 24px;
  text-align: left;
  font: normal normal normal 14px/27px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  top: 657px;
  left: 0px;
  width: 100%;
  height: auto;

  @media (min-width: 991px) {
    .text-left {
      width: auto;
    }
  }

  .footer_text1 {
    text-align: left;
    font-size: 16px;
    letter-spacing: 0px;
    color: #ffffff;
  }

  .footer_text2 {
    text-align: left;
    font-size: 14px;
    letter-spacing: 0px;
    color: #c4d0dd;
    opacity: 1;
  }

  .paddinglr5 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .relative {
    position: relative;
  }

  .absolute {
    position: absolute;
  }
`;
export default AdminFooterWrapper;
