import styled from "styled-components";
export const SignInWrapper = styled.div`

footer {
	@media screen and (min-width: 992px){
		height: 7rem;
	}
}

.main-container {
	
	.relative {
    	bottom: 0;
    	@media screen and (max-width: 430px) {
      		bottom: -5rem;
    	}
    	top: auto !important;
    	@media screen and (min-width: 992px) {
			position: fixed;	
		}
  	}

	.ce-main-logo-div {
		width: 100%;
		border-bottom: 1.5px solid #E3E3E3;
		.main-content-logo {
			margin: 1rem 0 1rem 1rem;
			width: 15rem;
		}	
	}

	.mid-container {

		@media screen and (max-width: 991px) {
			flex-direction: column-reverse;
			justify-content: flex-end;
			margin-bottom: 18rem;
		}

		@media screen and (min-width: 768px) and (max-width: 991px) {
			margin-bottom: 16rem;
			position: relative;
			top: 5rem;
		}

		@media screen and (max-width: 576px) {
			margin-bottom: 10rem
		}

		@media screen and (min-width: 992px) {
			height: 68vh;
			height: calc(100vh - 13.5rem);
		}
	
	.box-container {
		margin-top: auto;
		margin-bottom: auto;

		@media screen and (min-width: 992px) {
			width: 30%;
		}

		@media screen and (max-width: 991px) {
			margin-bottom: 0;
			margin-left: auto;
			margin-right: auto;
		}
		
		@media screen and (min-width: 768px) and (max-width: 991px) {
			width: 100%;
		}

		.box {
			z-index: 10;
			opacity: 1;
			position: relative;
			flex-direction: column;
			box-shadow: -5px 10px 10px #00000029;;
			background: white;
			align-items: center;
			border-bottom: 10px solid #C10E21;

			@media screen and (min-width: 992px) and (max-width: 1199px) {
				left: 20%;
				min-width: 36rem;
				height: auto;
				
			}
			
			@media screen and (min-width: 1200px) {
				left: 37%;
				min-width: 36rem;
				height: auto;
			}

			@media screen and (max-width: 991px) {
				bottom: 0rem;
				margin: 3rem;
				top: 16rem;
			}

			@media screen and (max-width: 576px) {
				top: 9rem;
				bottom: 0rem;
			    margin: 2rem 1.5rem 2rem 1.5rem;
			}

			.box-header {
				.aha-title-div {
					flex-direction: column;
					justify-content: center;
					p {
						font: normal normal 600 18px/24px Montserrat;
						margin-top: auto;
						padding-top: 9px;
						margin-bottom: auto;
						margin-left: 10px;
						margin-right: 10px;
						@media screen and (max-width: 576px) {
							font: normal normal 600 16px/24px Montserrat;
							margin-left: 0px;
						}
						@media screen and (max-width: 359px) {
							font: normal normal 600 14px/26px Montserrat;
							margin-left: 0px;
						}
					}
				}
				.box-logo {
					img {
						padding: 1rem;
						margin-left: 0.5rem;
					}
				}
			}

			.box-body {
				.box-body-title {
					p {
						font: normal normal 600 30px/26px Montserrat;
						color: #222328;
						margin-left: 1.5rem;
						margin-right: 1.5rem;
						@media screen and (max-width: 576px) {
							font: normal normal 600 21px/26px Montserrat;
						}
						@media screen and (max-width: 359px) {
							font: normal normal 600 18px/26px Montserrat;
						}
					}
				}
				.box-body-text {
					p {
						font: normal normal medium 18px/33px Montserrat;
						margin: 0 1.5rem 1.0rem 1.5rem;
						color: #707070;
				      }
				}
			}

			button {
				margin-bottom: 1rem;
				align-self: flex-start;
				margin-left: 1.5rem;
				height: 40px;

				@media screen and (max-width: 359px) {
					width: 200px;
					margin: 5px;
					align-self: center;
					font-size: small;
				}
			}

			@media screen and (min-width: 768px) and (max-width: 991px) {
				width: max-content;
				left: 0;
				top: 5rem;
				margin-top: 19rem;
			}

		}
	}

	.backdrop-div {
		z-index: -1;
			img {
				width: 100%;
				height: 100%;
				padding: 2rem;

				@media screen and (min-width: 992px) {
					width: auto;
					height: auto;
					padding: 1rem;
				}

				@media screen and (max-width: 991px) {
					width: 100%;
					height: 100%;
					height: auto;
				}

				@media screen and (max-width: 576px) {
					height: auto;
					padding: 1rem;
				}

			}

			@media screen and (max-width: 991px) {
				position: absolute;
			}
			@media screen and (min-width: 992px) {
				width: 100%;
				display: flex;
				justify-content: center;
			}
			width: 100%;
		}
}
}

`;
