import React, { ElementType, ReactNode } from "react";
import classNames from "classnames";

interface ModalHeaderProps {
  as?: ElementType;
  children?: ReactNode;
  className?: any;
  showCloseButton?: boolean;
  component?: string;
  setShowError?: any;
  setRevisionName?: any,
  setReportNameErrorClass?: any,
  setSavedReportName? : any
  saveReportNameRef?: any
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  as: Tag = "h6",
  children = "Changes Description",
  className,
  showCloseButton = true,
  component = "",
  setRevisionName,
  setShowError,
  setReportNameErrorClass,
  setSavedReportName,
  saveReportNameRef
}: ModalHeaderProps) => {
  
  const isLogComponent: boolean = [
    "AuditLog",
    "ActivityLog",
    "BatchLog",
    "VendorLog",
    "ReportLog",
    "CustomReport",
    "Certificate"
  ].includes(component);

  return (
    <>
      <div className="modal-header">
        <Tag
          className={classNames("modal-title", className, {
            title: !isLogComponent,
            "title-logs": isLogComponent,
          })}
          data-testid="header-title"
        >
          {children}
        </Tag>
        {showCloseButton && (
          <button
            type="button"
            className={isLogComponent ? "close-logs" : "close"}
            data-dismiss="modal"
            onClick={() => {
              if(setReportNameErrorClass) {
                  setReportNameErrorClass(false)
                }
              if(setShowError) {
                setShowError("")
                }
              if(setRevisionName) {
                setRevisionName("")
              }
              if(setSavedReportName) {
                setSavedReportName("")
              }
              if (saveReportNameRef) {
                saveReportNameRef.current.value = "";
              }
            }}
            aria-label="Close"
          >
            <span aria-hidden="true">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                className="bi bi-x-lg cross-icon"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </span>
          </button>
        )}
      </div>
    </>
  );
};
