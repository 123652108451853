import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface LearnerManagementState {
  pageNumber: number;
  search: string;
  pageSize: number;
  loader: boolean;
  pageOrder: string;
  reFetch: boolean;
}

export const initialState: LearnerManagementState = {
  pageNumber: 1,
  search: "",
  pageSize: 25,
  loader: false,
  pageOrder: "updated_at DESC",
  reFetch: false,
};

const learnerManagementSlice = createSlice({
  name: "learner",
  initialState,
  reducers: {
    setPageNumber: (state, action: PayloadAction<LearnerManagementState>) => {
      state.pageNumber = action.payload.pageNumber;
    },
    searchLearner: (state, action: PayloadAction<LearnerManagementState>) => {
      state.pageNumber = 1;
      state.search = action.payload.search;
    },
    setPageSize: (state, action: PayloadAction<LearnerManagementState>) => {
      state.pageSize = action.payload.pageSize;
      state.pageNumber = 1;
    },
    setPageOrder: (state, action) => {
      state.pageOrder = action.payload;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
    toggleReFetch: (state, action: PayloadAction<boolean>) => {
      state.reFetch = !state.reFetch;
    },
    clearLearnerState: () => {
      return initialState;
    },
  },
});
const { actions, reducer } = learnerManagementSlice;

export const {
  setPageNumber,
  setLoader,
  setPageOrder,
  searchLearner,
  setPageSize,
  clearLearnerState,
  toggleReFetch,
} = actions;
export default reducer;
