import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IUser {
  cpUser: any;
  ssoUser: any;
  ssoAccessToken: null | string;
  menus: any;
}

export const initialState: IUser = {
  cpUser: {},
  ssoUser: {},
  ssoAccessToken: null,
  menus: {},
};

const userSlice = createSlice({
  name: "userManagement",
  initialState,
  reducers: {
    setSSOAccessToken: (state, action: PayloadAction<string>) => {
      state.ssoAccessToken = action.payload;
    },
    ssoLogin: (state, action: PayloadAction<any>) => {
      state.ssoUser = { ...action.payload };
    },
    userLogin: (state, action: PayloadAction<any>) => {
      state.cpUser = { ...action.payload };
    },
  },
});
const { actions, reducer } = userSlice;

export const { setSSOAccessToken, ssoLogin, userLogin } =
  actions;
export default reducer;
