import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListCustomReportSavedFilters } from "services/api/report.api";
import { showToast } from "components/Toast/toast.slice";
import _ from "lodash";
import { CONSTANTS } from "common/constants";

export interface SchedulerDetails {
  title: string;
  startDate: string;
  endDate: string;
  runTime: string;
  reportPeriod: any;
  omitReport: boolean;
  status: string;
}
export interface ReportDetails {
  vendors: string;
  accreditations: string;
  programs: string;
  classifications: string;
  certificates: string;
  frequency: string;
  startDate: string;
  endDate: string;
  mismatchedErrors: string;
  rejectedErrors: string;
  errorType: string;
}
export interface NotificationDetails {
  email: string;
  subject: string;
  sender?: string;
  message: string;
  format: string;
  notifyMe: boolean;
}

export interface ManagementState {
  loader: boolean;
  customReportFilters: [];
  schedulerDetails: SchedulerDetails;
  reportDetails: ReportDetails;
  notificationDetails: NotificationDetails;
  selectedFields: Array<string>;
  tableColumns: Array<any>;
  previewData: any;
  filters: any;
  initialSchedulerData: any;
  initialSelectedFields: Array<string>;
}

export const initialState: ManagementState = {
  loader: false,
  customReportFilters: [],
  schedulerDetails: {
    title: "",
    startDate: "",
    endDate: "",
    runTime: "",
    reportPeriod: {},
    omitReport: true,
    status: ""
  },
  reportDetails: {
    vendors: "",
    programs: "",
    accreditations: "",
    classifications: "",
    certificates: "",
    frequency: "",
    startDate: "",
    endDate: "",
    mismatchedErrors: "",
    rejectedErrors: "",
    errorType: "",
  },
  notificationDetails: {
    email: "",
    subject: "",
    sender: CONSTANTS.REPORT_SENDER_EMAIL,
    message: "",
    format: "xlsx",
    notifyMe: false,
  },
  selectedFields: [],
  tableColumns: [],
  previewData: {},
  filters: {},
  initialSchedulerData: {},
  initialSelectedFields: []
};

export const listCustomReportFiltersApiCall = createAsyncThunk(
  "/listCustomReportFilters",
  async (search: string, { dispatch }) => {
    try {
      const response = await ListCustomReportSavedFilters(search);
      return response?.data;
    } catch (error) {
      dispatch(
        showToast({
          title: "CustomReport",
          message: "Something went wrong",
        })
      );
    }
  }
);

const managementSlice = createSlice({
  name: "customReport",
  initialState,
  reducers: {
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
    clearCustomReportState: (state) => {
      return {
        ...initialState,
        customReportFilters: state.customReportFilters,
      };
    },
    customReportFilters: (state, action: PayloadAction<number>) => {
      return state;
    },
    setTitle: (state, action: PayloadAction<SchedulerDetails>) => {
      state.schedulerDetails = {
        ...state.schedulerDetails,
        title: action.payload.title,
      };
    },
    setSchedulerDates: (state, action: PayloadAction<SchedulerDetails>) => {
      state.schedulerDetails = {
        ...state.schedulerDetails,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    },
    setRunTime: (state, action: PayloadAction<SchedulerDetails>) => {
      state.schedulerDetails = {
        ...state.schedulerDetails,
        runTime: action.payload.runTime,
      };
    },
    setReportPeriod: (state, action: PayloadAction<SchedulerDetails>) => {
      state.schedulerDetails = {
        ...state.schedulerDetails,
        reportPeriod: action.payload.reportPeriod,
      };
    },
    setSchedulerState: (state, action: PayloadAction<any>) => {
      state.schedulerDetails = {
        ...state.schedulerDetails,
        [action.payload.key]: action.payload.value,
      };
    },
    setNotificationState: (state, action: PayloadAction<any>) => {
      state.notificationDetails = {
        ...state.notificationDetails,
        [action.payload.key]: action.payload.value,
      };
    },
    setVendor: (state, action: PayloadAction<ReportDetails>) => {
      state.reportDetails = {
        ...state.reportDetails,
        vendors: action.payload.vendors,
      };
    },
    setProgram: (state, action: PayloadAction<ReportDetails>) => {
      state.reportDetails = {
        ...state.reportDetails,
        programs: action.payload.programs,
      };
    },
    setAccreditation: (state, action: PayloadAction<ReportDetails>) => {
      state.reportDetails = {
        ...state.reportDetails,
        accreditations: action.payload.accreditations,
      };
    },
    setClassification: (state, action: PayloadAction<ReportDetails>) => {
      state.reportDetails = {
        ...state.reportDetails,
        classifications: action.payload.classifications,
      };
    },
    setCertificate: (state, action: PayloadAction<ReportDetails>) => {
      state.reportDetails = {
        ...state.reportDetails,
        certificates: action.payload.certificates,
      };
    },
    setMisMatchedErrors: (state, action: PayloadAction<ReportDetails>) => {
      let errorType = _.isEmpty(action.payload?.mismatchedErrors) ? "" : action.payload?.errorType
      state.reportDetails = {
        ...state.reportDetails,
        mismatchedErrors: action.payload?.mismatchedErrors,
        errorType: errorType
      };
    },
    setRejectedErrors: (state, action: PayloadAction<ReportDetails>) => {
      let errorType = _.isEmpty(action.payload?.rejectedErrors) ? "" : action.payload?.errorType
      state.reportDetails = {
        ...state.reportDetails,
        rejectedErrors: action.payload.rejectedErrors,
        errorType: errorType
      };
    },
    setFrequency: (state, action: PayloadAction<ReportDetails>) => {
      state.reportDetails = {
        ...state.reportDetails,
        frequency: action.payload.frequency,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    },
    setDateRange: (state, action: PayloadAction<ReportDetails>) => {
      state.reportDetails = {
        ...state.reportDetails,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    },
    setEmail: (state, action: PayloadAction<NotificationDetails>) => {
      state.notificationDetails = {
        ...state.notificationDetails,
        email: action.payload.email,
      };
    },
    setSubject: (state, action: PayloadAction<NotificationDetails>) => {
      state.notificationDetails = {
        ...state.notificationDetails,
        subject: action.payload.subject,
      };
    },
    setSender: (state, action: PayloadAction<NotificationDetails>) => {
      state.notificationDetails = {
        ...state.notificationDetails,
        sender: action.payload.sender,
      };
    },
    setMessage: (state, action: PayloadAction<NotificationDetails>) => {
      state.notificationDetails = {
        ...state.notificationDetails,
        message: action.payload.message,
      };
    },
    setFormat: (state, action: PayloadAction<NotificationDetails>) => {
      state.notificationDetails = {
        ...state.notificationDetails,
        format: action.payload.format,
      };
    },
    setNotifyMe: (state, action: PayloadAction<NotificationDetails>) => {
      state.notificationDetails = {
        ...state.notificationDetails,
        notifyMe: action.payload.notifyMe,
      };
    },
    setSelectedFields: (state, action: PayloadAction<ManagementState>) => {
      state.selectedFields = action.payload.selectedFields;
    },
    setTableColumns: (state, action: PayloadAction<ManagementState>) => {
      state.tableColumns = action.payload.tableColumns;
    },
    setPreviewData: (state, action: PayloadAction<ManagementState>) => {
      let reportData =
        JSON.parse(JSON.stringify(action.payload?.previewData))?.data
          ?.customReport || [];

      reportData.map((ele: any, index: any) => {
        if (ele.hasOwnProperty("classification")) {
          ele["classification"] = ele["classification"]?.replace(/,/g, ", ");
        }
        if (ele.hasOwnProperty("specialties")) {
          ele["specialties"] = ele["specialties"]?.replace(/,/g, ", ");
        }
        if (ele.hasOwnProperty("qualification")) {
          ele["qualification"] = ele["qualification"]?.replace(/,/g, ", ");
        }
      });

      state.previewData = {
        ...action.payload.previewData,
        data: { ...action.payload.previewData.data, customReport: reportData },
      };
    },
    setFilters: (state, action: PayloadAction<ManagementState>) => {
      let customField =
        !_.isEmpty(action.payload.filters?.customField) &&
        JSON.parse(JSON.stringify(action.payload.filters?.customField));

      for (const [key, value] of Object.entries(customField)) {
        if (_.isEmpty(value)) {
          customField[key] = [];
        }
      }

      state.filters = {
        ...action.payload.filters,
        customField,
      };
    },
    setReportDetails: (state, action: PayloadAction<any>) => {
      state.reportDetails = {
        ...state.reportDetails,
        [action.payload.key]: action.payload.value,
      }
    },
    setInitialSchedulerData: (state, action: PayloadAction<ManagementState>) => {
      state.initialSchedulerData = action.payload.initialSchedulerData
    },
    setInitialSelectedFields: (state, action: PayloadAction<ManagementState>) => {
      state.initialSelectedFields = action.payload.initialSelectedFields
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      listCustomReportFiltersApiCall.fulfilled,
      (state, action) => {
        state.customReportFilters = action.payload;
      }
    );
  },
});
const { actions, reducer } = managementSlice;

export const {
  setLoader,
  clearCustomReportState,
  setTitle,
  setSchedulerDates,
  setRunTime,
  setVendor,
  setProgram,
  setAccreditation,
  setClassification,
  setCertificate,
  setMisMatchedErrors,
  setRejectedErrors,
  setFrequency,
  setDateRange,
  setEmail,
  setSubject,
  setSender,
  setMessage,
  setFormat,
  setNotifyMe,
  setSchedulerState,
  setNotificationState,
  setSelectedFields,
  setReportPeriod,
  setTableColumns,
  setPreviewData,
  setFilters,
  setReportDetails,
  setInitialSchedulerData,
  setInitialSelectedFields
} = actions;
export default reducer;
