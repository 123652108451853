/* eslint-disable no-debugger, no-console */
import React from "react";
import { DashboardWrapper } from "./styled";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const { t } = useTranslation();

  // const dispatch = useDispatch();
  // const history = useHistory();
  // const [summary, setSummary] = useState<Summary>();
  // const dashboardSource = axios.CancelToken.source();
  // useEffect(() => {
  //   FetchContentSummary();
  //   return () => {
  //     dashboardSource.cancel();
  //   };
  // }, []);
  // const FetchContentSummary = async () => {
  //   dispatch(setPageLoadingStatus({ isPageLoading: true }));
  //   getdashboardContent(dashboardSource)
  //     .then((response) => {
  //       onSuccessResponse(response);
  //     })
  //     .catch((error) => {
  //       handleAPIError(error, history, dispatch);
  //     })
  //     .finally(() => {
  //       dispatch(setPageLoadingStatus({ isPageLoading: false }));
  //     });
  // };
  // const onSuccessResponse = (result: any) => {
  //   if (result.status === 200) {
  //     const summary = result.data.data.summary;
  //     setSummary(summary);
  //   }
  // };
  return (
    <DashboardWrapper>
      <div className="dashboard-wrapper d-flex w-100">
        <div className="container">
          <div className="row">
            <h1 className="w-100 cp-h2">{t<string>("dashboard")}</h1>
            {/* <section className="w-100" role="region" aria-label="dashboard with 6 records">
            <div className="col-lg-4 col-md-6 d-flex justify-content-start float-left">
              <div className="products products-one">
                <div className="icons d-flex justify-content-center align-items-center">
                   <img src="../images/Content.svg" alt="content icon" />
                   </div>
                <div className="icon-contents"  aria-labelledby="Contents">
                  <h2 id="Contents" aria-label="Total Number of Contents" className="mb-0">Contents</h2>
                  <p className="content-exp mb-0">{summary?.contents}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex justify-content-center float-left">
              <div className="products products-two">
                <div className="icons d-flex justify-content-center align-items-center">
                <img src="../images/CarePlan.svg" alt="careplan icon" /> 
                </div>
                <div className="icon-contents" role="region" aria-labelledby="CarePlans">
                  <h2 id="careplans" aria-label="Total Number of CarePlans" className="mb-0">CarePlans</h2>
                  <p className="content-exp mb-0">{summary?.careplans}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex justify-content-end float-left">
              <div className="products products-three">
                <div className="icons d-flex justify-content-center align-items-center">
                <img src="../images/Files.svg" alt="files icon" />
                </div>
                <div className="icon-contents" role="region" aria-labelledby="Assets">
                  <h2 id="Assets" aria-label="Total Number of Assets" className="mb-0">Assets</h2>
                  <p className="content-exp mb-0">{summary?.assets}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex justify-content-start float-left">
              <div className="products products-four">
                <div className="icons d-flex justify-content-center align-items-center">
                <img src="../images/Assessments.svg" alt="assessments icon" />
                </div>
                <div className="icon-contents" role="region" aria-labelledby="Assessments">
                  <h2 id="Assessments" aria-label="Total Number of Assessments" className="mb-0">Assessments</h2>
                  <p className="content-exp mb-0">{summary?.assessments}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex justify-content-center float-left">
              <div className="products products-five">
                <div className="icons d-flex justify-content-center align-items-center">
                <img src="../images/Video.svg" alt="video icon" />
                </div>
                <div className="icon-contents"  role="region" aria-labelledby="videofiles">
                  <h2 id="videofiles" aria-label="Total Number of video files" className="mb-0">Video files</h2>
                  <p className="content-exp mb-0">{summary?.videos}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex justify-content-end float-left">
              <div className="products products-six">
                <div className="icons d-flex justify-content-center align-items-center">
                <img src="../images/Audio.svg" alt="audio icon" />
                </div>
                <div className="icon-contents" role="region" aria-labelledby="Audiofiles">
                  <h2  id="Audiofiles" aria-label="Total Number of Audio files" className="mb-0">Audio files</h2>
                  <p className="content-exp mb-0">{summary?.audios}</p>
                </div>
              </div>
            </div>
           </section>  */}
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default Dashboard;
