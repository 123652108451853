import React from "react";
import { ModalPopupWrapper } from "./Styled";
import { useTranslation } from "react-i18next";

interface ModalProps {
  data?: any;
  childrenHeader?: any;
  childHeaderClass?: any;
  childrenBody?: any;
  childrenBodyClass?: any;
  displayButtons?: boolean;
  modalSize?: any;
  modalParent?: any;
  id?: string;
  backdrop?: "static" | Boolean;
  component?: string;
  popupDiscardFunc?: React.MouseEventHandler<HTMLElement>;
}

const ModalPopupComp: React.FC<ModalProps> = ({
  popupDiscardFunc,
  id,
  childrenHeader,
  childHeaderClass,
  childrenBody,
  childrenBodyClass,
  displayButtons = false,
  modalSize,
  modalParent,
  backdrop = "static",
  component = "",
}: ModalProps) => {
  const { t } = useTranslation();

  const isLogComponent = [
    "AuditLog",
    "ActivityLog",
    "BatchLog",
    "VendorLog",
    "ReportLog",
  ].includes(component);

  return (
    <ModalPopupWrapper>
      <div
        className={
          "modal aui-modal confirmationModal " +
          (modalParent ? modalParent : "fade show")
        }
        data-testid={id || "modalWrapper"}
        id={id || "modal_backbtn"}
        tabIndex={-1}
        aria-labelledby={id}
        aria-modal="true"
        role="dialog"
        data-backdrop={String(backdrop)}
        data-keyboard="true"
      >
        <div
          className={
            "modal-dialog modal-dialog-centered " +
            (modalSize ? modalSize : "modal-md ") +
            (isLogComponent ? " modal-dialog-logs" : " modal-dialog")
          }
        >
          <div className={"modal-content " + childHeaderClass}>
            {childrenHeader}
            <div
              className={
                "p-0 " + (isLogComponent ? "modal-body-logs" : "modal-body")
              }
            >
              <div className={"text-center " + childrenBodyClass}>
                {childrenBody}
                {displayButtons && (
                  <div className="mt-4 btnWrapper">
                    <button
                      data-testid="modalButtonNo"
                      className="btn btn-secondary btn-round btn-sm mx-2"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      {t<string>("no")}
                    </button>
                    <button
                      data-testid="modalButtonYes"
                      className="popupNavigate btn btn-primary btn-round btn-sm mx-2"
                      data-dismiss="modal"
                      onClick={popupDiscardFunc}
                    >
                      {t<string>("yes")}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalPopupWrapper>
  );
};

export default ModalPopupComp;
