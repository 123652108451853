import CONSTANTS from "common/constants/constants";
import { format } from "date-fns";

export const toggleNoOfRecordTableFilter = (e: any) => {
  e.currentTarget.nextSibling.classList.toggle("d-block");
  e.currentTarget.nextSibling.classList.toggle("z-index-3");
};

export const mouseDown = (e: any) => {
  if(e.currentTarget.previousSibling === e.relatedTarget) {
    e.currentTarget.classList.toggle("d-block");
  }
  if(!e.currentTarget.contains(e.relatedTarget)){
    e.currentTarget.classList.toggle("d-block")
  }
}

export const mouseDownParent = (e: any) => {  
  if(e.relatedTarget === null){
    e.currentTarget.nextSibling.classList.remove("d-block")
  }
  else if(e.relatedTarget.classList[0] === e.currentTarget.classList[0]) {
    e.currentTarget.nextSibling.classList.remove("d-block")
  }
  else if(e.relatedTarget.classList[0] !== e.currentTarget.classList[0]){
    if(!e.currentTarget.nextSibling.contains(e.relatedTarget))
        e.currentTarget.nextSibling.classList.remove("d-block")
  }
}

export const toggleTableFilterDropdown = (e: any) => {
  e.currentTarget.nextSibling.classList.toggle("d-block");
  e.currentTarget.nextSibling.classList.toggle("z-index-3");
};

export const handleKeyDown = (e: any) => {
  if (
    e.keyCode === CONSTANTS.KEY_ENTER ||
    e.keyCode === CONSTANTS.KEY_SPACEBAR
  ) {
    e.stopPropagation();
    e.preventDefault();
    e.currentTarget.nextSibling.classList.toggle("d-block");
    e.currentTarget.nextSibling.classList.contains("d-block") === true
      ? e.currentTarget.setAttribute("aria-expanded", "true")
      : e.currentTarget.setAttribute("aria-expanded", "false");
  }
};

export const keydowntoggleNoOfRecordTableFilter = (e: any) => {
  if (
    e.keyCode === CONSTANTS.KEY_ENTER ||
    e.keyCode === CONSTANTS.KEY_SPACEBAR
  ) {
    e.stopPropagation();
    e.preventDefault();
    e.currentTarget.nextSibling.classList.toggle("d-block");
    e.currentTarget.nextSibling.classList.contains("d-block") === true
      ? e.currentTarget.setAttribute("aria-expanded", "true")
      : e.currentTarget.setAttribute("aria-expanded", "false");
  }
};
export const KeyUpsetPageSizeHandler = (e: any) => {
  if (e.keyCode === CONSTANTS.KEY_UP) {
    if (e.currentTarget.previousSibling) {
      e.currentTarget.previousSibling.focus();
    }
  }
};

export const KeyDownsetPageSizeHandler = (e: any) => {
  if (e.keyCode === CONSTANTS.KEY_DOWN) {
    if (e.currentTarget.nextSibling) {
      e.currentTarget.nextSibling.focus();
    }
  }
  if (
    e.keyCode === CONSTANTS.KEY_ENTER ||
    e.keyCode === CONSTANTS.KEY_SPACEBAR
  ) {
    e.stopPropagation();
    e.preventDefault();
    e.currentTarget.click();
    document
      .querySelector<any>(".pagecontent-filter")
      .classList.remove("d-block");
    document
      .querySelector<any>(".keydown-recordfilter")
      .setAttribute("aria-expanded", "false");
  }
};

export const KeyUpChangeColumnHandler = (e: any) => {
  if (e.keyCode === CONSTANTS.KEY_UP) {
    e.stopPropagation();
    e.preventDefault();
    if (e.currentTarget.previousSibling) {
      e.currentTarget.previousSibling.focus();
    }
  }
};

export const KeyDownChangeColumnHandler = (e: any, name: any) => {
  if (e.keyCode === CONSTANTS.KEY_DOWN) {
    if (e.currentTarget.nextSibling) {
      e.currentTarget.nextSibling.focus();
    }
  }
  if (
    e.keyCode === CONSTANTS.KEY_ENTER ||
    e.keyCode === CONSTANTS.KEY_SPACEBAR
  ) {
    e.stopPropagation();
    e.preventDefault();
    e.currentTarget.querySelector("input").click();
    e.currentTarget.querySelector("input").checked === true
      ? e.currentTarget.setAttribute("aria-label", "checkbox checked " + name)
      : e.currentTarget.setAttribute(
          "aria-label",
          "checkbox unchecked " + name
        );
  }
};

export const onBlurPageSize = (e:any)=>{
  if(!e.relatedTarget || !e?.currentTarget.contains(e.relatedTarget)) {
    e?.target?.nextElementSibling?.classList.remove("d-block")
    e?.target?.nextElementSibling?.classList.remove("z-index-3")
  }
 }

 export const onChangePageSize = (e:any) =>{
  e?.target?.parentElement?.classList.remove("d-block")
  e?.target?.parentElement?.classList.remove("z-index-3")
 } 
 
 export const convertToLocalTime = (date: any) => {
  const dateObject = new Date(date);
  return format(dateObject, "yyyy-MM-dd HH:mm:ss");
 }

 export const camelToNormalCase = (str: any) => {
  const result = str.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
 }