import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface LearnerActivitiesState {
  pageNumber: number;
  learnerId: string;
  pageSize: number;
  loader: boolean;
  pageOrder: string;
}

export const initialState: LearnerActivitiesState = {
  pageNumber: 1,
  learnerId: "",
  pageSize: 25,
  loader: false,
  pageOrder: "",
};

const vendorManagementSlice = createSlice({
  name: "learnerActivities",
  initialState,
  reducers: {
    setPageNumber: (state, action: PayloadAction<LearnerActivitiesState>) => {
      state.pageNumber = action.payload.pageNumber;
    },
    searchLearnerActivitiesByLearnerId: (
      state,
      action: PayloadAction<LearnerActivitiesState>
    ) => {
      state.learnerId = action.payload.learnerId;
    },
    setPageSize: (state, action: PayloadAction<LearnerActivitiesState>) => {
      state.pageSize = action.payload.pageSize;
      state.pageNumber = 1;
    },
    setPageOrder: (state, action) => {
      state.pageOrder = action.payload;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
    clearVendorState: () => {
      return initialState;
    },
  },
});
const { actions, reducer } = vendorManagementSlice;

export const {
  setPageNumber,
  setLoader,
  setPageOrder,
  searchLearnerActivitiesByLearnerId,
  setPageSize,
  clearVendorState,
} = actions;
export default reducer;
