import React, { useState, useEffect, FunctionComponent, useRef } from "react";
import {
  NavLink,
  useHistory,
  useRouteMatch,
  useLocation,
  matchPath,
  Link,
} from "react-router-dom";
import PropTypes from "prop-types";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import LeftPanelWrapper from "./styled";
import { useTranslation } from "react-i18next";
import * as constants from "common/constants";
import {
  Accordion,
  AccordionBody,
  AccordionItem,
  AccordionHeader,
} from "components/Accordion";
import store from "../../app/store";
import { Signout } from "services/api/AdminHeader.api";
import { showToast } from "components/Toast/toast.slice";
import { useRBACContext } from "@impelsysinc/react-rbac";
import SvgCeCmeLogo from "../../components/SvgComponent/CeCmeLogo";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { useDebouncedCallback } from "use-debounce";
import { listCustomReportFiltersApiCall } from "pages/Report/CustomReport/CustomReportSlice";
import { Routes } from "common/constants";
import { setShowMyReports } from "./LeftPanelSlice";
import { DeleteCustomReportFilter } from "services/api/report.api";
import { handleAPIError } from "common/ErrorHandler";
import useFeatures from "common/utils/useFeatures";

export const useSubmenu = () => {
  const { t } = useTranslation();
  const { canAccess } = useRBACContext();
  const location = useLocation();
  const isCustomReportPage = matchPath(location.pathname, {
    path: Routes.CUSTOM_REPORTS_HOME_PAGE,
    exact: false,
  });
  const customReportState = useSelector((state: any) => {
    return state.customReport;
  });

  const features = useFeatures();

  let sidePanelList = {
    component: [
      {
        path: "/vendors",
        name: "Vendors",
        displayName: `${t<string>("vendors")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "vendors",
          action: ["get", "get.all"],
        }),
      },
      {
        path: "/accreditations",
        name: "Accreditations",
        displayName: `${t<string>("accreditations")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "accreditations",
          action: ["get", "get.all"],
        }),
      },
      {
        path: "/programs",
        name: "Programs",
        displayName: `${t<string>("programs")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "programs",
          action: ["get", "get.all"],
        }),
      },
      {
        path: "/classifications",
        name: "Classifications",
        displayName: `${t<string>("classifications")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "classifications",
          action: ["get", "get.all"],
        }),
      },
    ],

    accr_certificate: [
      {
        path: "/certificates",
        name: "Certificates",
        displayName: `${t<string>("certifications")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "certificates",
          action: ["get", "get.all"],
        }),
      },
    ],

    auditLog_menu: [
      {
        path: constants.Routes.AUDIT_LOG_PAGE,
        name: "AuditLog",
        displayName: `${t<string>("auditlog")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "auditLogs",
          action: ["get", "get.all"],
        }),
      },
      {
        path: constants.Routes.ACTIVITY_LOG_LIST_PAGE,
        name: "ActivityLogs",
        displayName: `${t<string>("activitylog")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "activityLogs",
          action: ["get", "get.all"],
        }),
      },
      {
        path: "/phdLogs",
        name: "phdLog",
        displayName: `${t<string>("phdlog")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "phdLogs",
          action: ["get", "get.all"],
        }),
      },
      {
        path: constants.Routes.REPORT_LOG_PAGE,
        name: "ReportLog",
        displayName: `${t<string>("preDefinedReportLog")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "reportLogs",
          action: ["get", "get.all"],
        }),
      },
      {
        path: constants.Routes.CUSTOM_REPORT_LOG_PAGE,
        name: "CustomReportLog",
        displayName: `${t<string>("customReportLog")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "reportLogs",
          action: ["get", "get.all"],
        }),
      },
      {
        path: constants.Routes.VENDOR_ACTIVITY_LOG_PAGE,
        name: "VendorActivityLog",
        displayName: `${t<string>("vendorActivityLog")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "vendorActivityLogs",
          action: ["get", "get.all"],
        }),
      },
    ],

    activitiesMenu: [
      {
        path: "/learners",
        name: "learnerDetails",
        displayName: `${t<string>("learnerDetails")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "learnerDetails",
          action: ["get", "get.all"],
        }),
      },
      {
        path: "/activities",
        name: "SuccessActivities",
        displayName: `${t<string>("successActivities")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "successActivities",
          action: ["get", "get.all"],
        }),
      },
      {
        path: "/activity-errors",
        name: "errorActivities",
        displayName: `${t<string>("errorList")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "errorActivities",
          action: ["get", "get.all"],
        }),
      },
    ],

    archivedFilesMenu: [
      {
        path: constants.Routes.ARCHIVE_LEARNER_LIST_PAGE,
        name: "learnerDetails",
        displayName: `${t<string>("learnerDetails")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "archivedLearnerDetails",
          action: ["get", "get.all"],
        }),
      },
      {
        path: "/archived-activities",
        name: "SuccessActivities",
        displayName: `${t<string>("successActivities")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "archivedActivities",
          action: ["get", "get.all"],
        }),
      },
      {
        path: "/archived-activity-errors",
        name: "errorActivities",
        displayName: `${t<string>("errorList")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "archivedErrorActivities",
          action: ["get", "get.all"],
        }),
      },
    ],
    reports: [
      {
        path: "/reports",
        name: "reports",
        displayName: `${t<string>("Predefined Reports")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "reports",
          action: ["get", "get.all"],
        }),
      },
      {
        path: "/custom-reports",
        name: "customReports",
        displayName: `${t<string>("Custom Reports")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "reports",
          action: ["get", "get.all"],
        }),
      },
      {
        path: "/schedulers",
        name: "SchedulerList",
        displayName: `${t<string>("schedulerList")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "schedulerList",
          action: ["get", "get.all"],
        }),
      },
    ],
    my_reports: [
      {
        path: "/myreports",
        name: "My Reports",
        displayName: `${t<string>("My Reports")}`,
        hide:
          !isCustomReportPage ||
          customReportState?.customReportFilters?.data?.customReportFilters?.length === 0 ||
          !canAccess({
            resourceType: "menu",
            resource: "reports",
            action: ["get", "get.all"],
          }),
      },
    ],
  };
  if (features?.adminPanel) {
    return sidePanelList;
  } else {
    const temp = { ...sidePanelList };
    const filteredReportList: any = sidePanelList.reports.filter(
      (obj: any) => obj?.name !== "customReports" && obj?.name !== "reports" && obj.name !== "SchedulerList"
    );
    const filteredLogsList: any = sidePanelList.auditLog_menu.filter(
      (obj: any) => obj?.name !== "CustomReportLog" && obj?.name !== "ReportLog"
    );
    sidePanelList = {
      ...temp,
      reports: [...filteredReportList],
      auditLog_menu: [...filteredLogsList],
    };
    return sidePanelList;
  }
};

export interface AdminLeftPanelProps {
  toggle: Function;
  setSidePanel: Function;
  showSidePanel: boolean;
}

export const AdminLeftPanel: FunctionComponent<AdminLeftPanelProps> = (
  props: any
) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { path: currUrlPath } = useRouteMatch();
  const isCustomReportPage = matchPath(currUrlPath, {
    path: Routes.CUSTOM_REPORTS_HOME_PAGE,
    exact: false,
  });
  const dispatch = useDispatch();
  const customReportState = useSelector((state: any) => {
    return state.customReport;
  });

  const leftPanelState = useSelector((state: any) => {
    return state.adminLeftPanel;
  });

  const [parentId, setParentId] = useState<string | string[]>("activityMenu");
  const activeMenuHandler = (
    event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => {
    props.toggle(event);
  };

  const [AccreSubmenu, setAccreSubmenu] = useState(false);
  const [custReportSubMenu, setCustReportSubMenu] = useState(false);
  const [myReportsExpanded, setMyReportsExpanded] = useState(false);
  const { canAccess, clearPermissions } = useRBACContext();
  const [show, setShow] = useState(false);
  const [active, setActive] = useState<any | null>(null);
  const [deletedReports, setDeletedReports] = useState<number[]>([]); //For custom report filters deletion
  const [disabledReports, setDisabledReports] = useState<number[]>([]);
  const [searchFilter, setSearchFilter] = useState<string>("");
  const filterSearchRef = useRef<HTMLInputElement>(null);

  const canAccessUserManagementList = canAccess({
    resourceType: "component",
    resource: "userManagement",
    action: "get.all",
  });

  const subMenuArray = useSubmenu();

  const Global: any = global;

  const signout = async () => {
    const res = await Signout();
    if (res?.status === 200) {
      localStorage.removeItem("Authorization");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("apiPermissions");
      clearPermissions();
      Global.userManager.signoutRedirect();
    } else {
      store.dispatch(
        showToast({
          title: "Signout",
          message: res?.data,
        })
      );
    }
  };

  useEffect(() => {
    if (!props.showSidePanel) {
      setParentId("");
    }
  }, [props.showSidePanel]);

  useEffect(() => {
    let activeMenu = "";

    [...subMenuArray.component, ...subMenuArray.accr_certificate].forEach(
      ({ path }) => {
        if (currUrlPath?.indexOf(path) !== -1) {
          activeMenu = "components";
        }
      }
    );

    subMenuArray.auditLog_menu.forEach(({ path }) => {
      if (currUrlPath?.indexOf(path) !== -1) {
        activeMenu = "auditLogMenu";
      }
    });

    subMenuArray.activitiesMenu.forEach(({ path }) => {
      if (currUrlPath?.indexOf(path) !== -1) {
        activeMenu = "activityMenu";
      }
    });

    subMenuArray.archivedFilesMenu.forEach(({ path }) => {
      if (currUrlPath?.indexOf(path) !== -1) {
        activeMenu = "archivedFilesMenu";
      }
    });

    subMenuArray.reports.forEach(({ path }) => {
      if (currUrlPath?.indexOf(path) !== -1) {
        activeMenu = "reports";
      }
    });

    subMenuArray.my_reports.forEach(({ path }) => {
      if (currUrlPath?.indexOf(path) !== -1) {
        activeMenu = "my_reports";
      }
    });

    setParentId(activeMenu);
    if (!isCustomReportPage) {
      setCustReportSubMenu(false);
    } else {
      setSearchFilter("");
      store.dispatch(listCustomReportFiltersApiCall(""));
    }
  }, [currUrlPath]);

  const toggle = (id: string) => {
    if (parentId === id) {
      setParentId("");
    } else {
      setParentId(id);
    }
  };

  const handleMyReportsClick = async (e: any) => {
    e.preventDefault();
    if (filterSearchRef.current) {
      filterSearchRef.current.value = "";
    }
    dispatch(setShowMyReports(true));
  };

  const handleSearchFilterChange = useDebouncedCallback(async (value) => {
    setSearchFilter(value);
    dispatch(listCustomReportFiltersApiCall(value));
  }, 500);

  const handleFilterSelect = (filter: any) => {
    setActive(filter);
    props.toggle();
  };

  const handleBack = (e: any) => {
    e.preventDefault();
    if (searchFilter !== "") {
      store.dispatch(listCustomReportFiltersApiCall(""));
      setSearchFilter("");
    }
    dispatch(setShowMyReports(false));
  };

  type customReportFiltersType = {
    id: number;
    name: string;
  };

  useEffect(() => {
    setShow(leftPanelState?.showMyReports);
  }, [leftPanelState]);

  const deleteReport = async () => {
    let toBeDeletedIds = deletedReports;
    setDeletedReports((deletedReports: any) => {
      return deletedReports.filter(
        (id: number) => toBeDeletedIds.indexOf(id) < 0
      );
    });
    try {
      await DeleteCustomReportFilter({ ids: toBeDeletedIds });
      await store.dispatch(listCustomReportFiltersApiCall(searchFilter));
      if (deletedReports.includes(active?.id)) {
        history.push(Routes.CUSTOM_REPORTS_HOME_PAGE);
      }
    } catch (err) {
      handleAPIError(err, history, dispatch);
    }
    setTimeout(() => {
      setDisabledReports((disabledReports: any) => {
        return disabledReports.filter(
          (id: number) => toBeDeletedIds.indexOf(id) < 0
        );
      });
    }, 500);
  };

  const debouncedDeleteReportApi = useDebouncedCallback(() => {
    deleteReport();
  }, 500);

  const handleReportDelete = async (id: number) => {
    if (id) {
      setDeletedReports([...deletedReports, id]);
      setDisabledReports([...disabledReports, id]);
      debouncedDeleteReportApi();
    }
  };

  return (
    <LeftPanelWrapper>
      <div className="aui-sidenav">
        <nav
          className="navbar navbar-expand-lg logo"
          id="navbar"
          aria-label="Main"
        >
          <a href="/learners" aria-label="CE Service logo">
            {props.showSidePanel ? (
              <SvgCeCmeLogo
                className="headerLogo-style"
                style={props.showSidePanel ? {} : { width: "60%" }}
              />
            ) : (
              <img
                src={process.env.PUBLIC_URL + "/images/Heart_Logo.svg"}
                alt="content icon"
                style={{ marginTop: "-45px" }}
              />
            )}
          </a>
          <span className="close" onClick={props.toggle}>
            X
          </span>
        </nav>
      </div>
      <div className="expandButton d-flex">
        <button
          aria-label="Expand or collapse navigation"
          data-testid="side-expand-btn"
          type="button"
          className="css-vn3wav d-none d-sm-none d-md-none d-lg-flex d-xl-flex"
          style={
            props.showSidePanel
              ? { backgroundColor: "#c33626", color: "white" }
              : {}
          }
          onClick={() => props.setSidePanel((state: boolean) => !state)}
        >
          {props.showSidePanel ? (
            <i className="acc-btn-arrow aha-icon-arrow-down leftDireaction"></i>
          ) : (
            <img
              src={process.env.PUBLIC_URL + "/images/CME_hamburger.svg"}
              alt="content icon hamburger"
              width="14px"
            />
          )}
        </button>
      </div>
      <div className="aui-sidenav left-panel-contents">
        <nav
          style={
            props.showSidePanel ? { display: "block" } : { display: "none" }
          }
          className="navbar-expand-lg position-relative"
          id="main-nav"
          aria-label="Component Navbar"
        >
          <div className="expandButton d-flex">
            <button
              aria-label="Expand or collapse navigation"
              type="button"
              className="css-vn3wav d-lg-none d-xl-none"
              onClick={props.toggle}
              style={
                props.showSidePanel
                  ? { backgroundColor: "#c33626", color: "white" }
                  : {}
              }
              // onClick={() => props.setSidePanel((state: boolean) => !state)}
            >
              <i className="acc-btn-arrow aha-icon-arrow-down leftDireaction"></i>
            </button>
          </div>

          {/* My reports sidebar starts */}

          {show && (
            <div className={`report-filter-nav-wrapper`}>
              <div className="report-filter-header">
                <div className="back-btn">
                  <a
                    href="#"
                    onClick={handleBack}
                    data-testid="myreports-back-btn"
                  >
                    <i className="acc-btn-arrow aha-icon-arrow-down" />
                    <span className="pl-3">My Reports {`(${customReportState?.customReportFilters?._pagination?.totalCount || 0})`}</span>
                  </a>
                </div>
                <div className="position-relative">
                  <input
                    className="w-100 text-truncate form-control"
                    type="text"
                    data-testid="searchVendor"
                    placeholder={t<string>("Search Filter")}
                    aria-label="search Filter"
                    ref={filterSearchRef}
                    onChange={(e) => handleSearchFilterChange(e.target.value)}
                  />
                  <a
                    className="icon-search"
                    tabIndex={-1}
                    href="#"
                    aria-label="Search Filter"
                  />
                </div>
              </div>

              {customReportState?.customReportFilters?.data?.customReportFilters?.length > 0 &&
                customReportState?.customReportFilters?.data?.customReportFilters && (
                      <TransitionGroup component = "ul" className="report-filter-nav">
                      {customReportState?.customReportFilters?.data?.customReportFilters?.map(
                        (filter: customReportFiltersType) => (
                          <CSSTransition
                            key={filter.id}
                            timeout={500}
                            classNames="item"
                            in={true}
                            appear={true}
                          >
                            <li
                              className={classNames("filter-li", {
                                disabled:
                                  disabledReports.includes(filter.id) === true,
                              })}
                            >
                              <Link
                                to={`/custom-reports/${filter.id}`}
                                onClick={() => handleFilterSelect(filter)}
                                className={`${active == filter && "active"}`}
                              >
                                {filter.name}
                              </Link>
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  `/images/delete-icon.svg`
                                }
                                alt={t<string>("delete")}
                                className="delete-icon"
                                data-testid="delete-report-btn"
                                onClick={() => handleReportDelete(filter?.id)}
                              />
                            </li>
                          </CSSTransition>
                        )
                      )}
                  </TransitionGroup>
                )}
              {customReportState?.customReportFilters?.data?.customReportFilters?.length === 0 && (
                <React.Fragment key={13}>
                  <div className="mt-3 pb-3 text-center justify-content-center">
                    {t<string>("noDataFound")}
                  </div>
                </React.Fragment>
              )}
            </div>
          )}

          {/* My reports sidebar ends */}

          <div
            id="sideNavbar"
            className={`side-nav-wrapper ${show ? " hide" : ""}`}
          >
            <Accordion
              flush
              open={parentId}
              tag="ul"
              className="w-100 main-nav-acc"
              toggle={toggle}
            >
              {/* Components Menu Item */}
              {subMenuArray.component.some((item) => !item.hide) && (
                <AccordionItem tag="li" id="sidenav-accordion-dropdown_1">
                  <AccordionHeader
                    targetId="components"
                    tag="button"
                    className={classNames(
                      "w-100 btn btn-text aui-sidenav-acc-header",
                      {
                        parentHighlight: parentId === "components",
                      }
                    )}
                    id="sidenavHeadingTwo"
                    aria-expanded={parentId === "components"}
                    aria-controls="sidenavcollapseOne"
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="18"
                        viewBox="0 0 20 18"
                      >
                        <g
                          id="Group_7894"
                          data-name="Group 7894"
                          transform="translate(20043 21546)"
                        >
                          <g
                            id="Group_7880"
                            data-name="Group 7880"
                            transform="translate(1 -1)"
                          >
                            <g
                              id="Rectangle_5517"
                              data-name="Rectangle 5517"
                              transform="translate(-20032 -21545)"
                              fill="none"
                              stroke="#222328"
                              strokeWidth="1.5"
                            >
                              <rect width="6" height="5" rx="2" stroke="none" />
                              <rect
                                x="0.75"
                                y="0.75"
                                width="4.5"
                                height="3.5"
                                rx="1.25"
                                fill="none"
                              />
                            </g>
                            <g
                              id="Rectangle_5518"
                              data-name="Rectangle 5518"
                              transform="translate(-20044 -21541)"
                              fill="none"
                              stroke="#222328"
                              strokeWidth="1.8"
                            >
                              <rect
                                width="20"
                                height="14"
                                rx="2"
                                stroke="none"
                              />
                              <rect
                                x="0.9"
                                y="0.9"
                                width="18.2"
                                height="12.2"
                                rx="1.1"
                                fill="none"
                              />
                            </g>
                            <g
                              id="Rectangle_5528"
                              data-name="Rectangle 5528"
                              transform="translate(-20042 -21545)"
                              fill="none"
                              stroke="#222328"
                              strokeWidth="1.5"
                            >
                              <rect width="6" height="5" rx="2" stroke="none" />
                              <rect
                                x="0.75"
                                y="0.75"
                                width="4.5"
                                height="3.5"
                                rx="1.25"
                                fill="none"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </span>
                    <span className="nav-item-text">
                      <>{t<string>("components")}</>
                    </span>
                    <i className="acc-btn-arrow aha-icon-arrow-down"></i>
                  </AccordionHeader>
                  <AccordionBody
                    tag="ul"
                    accordionId="components"
                    id="sidenavcollapseOne"
                    className={"aui-sidenav-acc-body"}
                    aria-labelledby="sidenavHeadingTwo"
                  >
                    {subMenuArray.component.map((list, i) => {
                      return list.hide ? null : (
                        <li
                          key={i}
                          className={classNames({
                            "Accr_parentMenu certificate_submenu":
                              list.name === "Accreditations",
                          })}
                        >
                          <NavLink
                            activeClassName="active"
                            className=""
                            key={list.path}
                            aria-label={list.name}
                            to={list.path}
                            onClick={(event) => {
                              setParentId("components");
                              activeMenuHandler(event);
                            }}
                          >
                            {list.displayName}

                            {/* Accreditation Menu Item */}
                            {list.name === "Accreditations" &&
                              subMenuArray.accr_certificate.some(
                                (item) => !item.hide
                              ) && (
                                <span
                                  className={classNames("submenu_toggleIcon", {
                                    iconMinus_style: AccreSubmenu,
                                    iconPlus_style: !AccreSubmenu,
                                  })}
                                  onClick={(
                                    e: React.MouseEvent<HTMLSpanElement>
                                  ) => {
                                    e.preventDefault();
                                    if (e.stopPropagation) {
                                      e.stopPropagation(); // W3C model
                                    } else {
                                      e.cancelable = true; // IE model
                                    }
                                    setAccreSubmenu(
                                      (isAccreSubmenu) => !isAccreSubmenu
                                    );
                                    setParentId("components");
                                  }}
                                >
                                  {AccreSubmenu ? "-" : "+"}
                                </span>
                              )}
                          </NavLink>
                          {list.name === "Accreditations" &&
                            subMenuArray.accr_certificate.map(
                              (list_inner, i_inner) => {
                                return list_inner.hide ? null : (
                                  <ul
                                    key={i_inner}
                                    id={`certificate_submenu_${i}`}
                                    className={classNames(
                                      "pb-0 collapse certi_substyle ",
                                      {
                                        show: AccreSubmenu,
                                      }
                                    )}
                                  >
                                    <li key={i_inner}>
                                      <NavLink
                                        activeClassName="active"
                                        className=""
                                        key={list_inner.path}
                                        aria-label={list_inner.name}
                                        to={list_inner.path}
                                        onClick={(event) => {
                                          setParentId("components");
                                          activeMenuHandler(event);
                                        }}
                                      >
                                        {list_inner.displayName}
                                      </NavLink>
                                    </li>
                                  </ul>
                                );
                              }
                            )}
                        </li>
                      );
                    })}
                  </AccordionBody>
                </AccordionItem>
              )}

              {/* Activities Menu Item */}
              {subMenuArray.activitiesMenu.some((item) => !item.hide) && (
                <AccordionItem tag="li" id="sidenav-accordion-dropdown_2">
                  <AccordionHeader
                    tag="button"
                    className={classNames(
                      "btn btn-text aui-sidenav-acc-header ",
                      {
                        parentHighlight: parentId === "activityMenu",
                      }
                    )}
                    id="headingActivities"
                    targetId="activityMenu"
                    aria-expanded={parentId === "activityMenu"}
                    aria-controls="sidenavActivityContainer"
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21"
                        height="21"
                        viewBox="0 0 21 21"
                      >
                        <path
                          id="Icon_awesome-id-card"
                          data-name="Icon awesome-id-card"
                          d="M18.333,2.25H1.667A1.951,1.951,0,0,0,0,4.393v.714H20V4.393A1.951,1.951,0,0,0,18.333,2.25ZM0,20.107A1.951,1.951,0,0,0,1.667,22.25H18.333A1.951,1.951,0,0,0,20,20.107V6.536H0ZM12.222,9.75a.326.326,0,0,1,.278-.357h5a.326.326,0,0,1,.278.357v.714a.326.326,0,0,1-.278.357h-5a.326.326,0,0,1-.278-.357L17.5,9.992Zm0,2.857a.326.326,0,0,1,.278-.357h5a.326.326,0,0,1,.278.357v.714a.326.326,0,0,1-.278.357h-5a.326.326,0,0,1-.278-.357L17.5,12.93Zm0,2.857a.326.326,0,0,1,.278-.357h5a.326.326,0,0,1,.278.357v.714a.326.326,0,0,1-.278.357h-5a.326.326,0,0,1-.278-.357l5.174-.317ZM6.111,9.393c1.226,0,2.222,1.281,2.222,2.857s-1,2.857-2.222,2.857S3.889,13.826,3.889,12.25,4.885,9.393,6.111,9.393ZM2.33,18.509a2.362,2.362,0,0,1,2.115-1.973h.285a2.852,2.852,0,0,0,2.764,0h.285a2.362,2.362,0,0,1,2.115,1.973.681.681,0,0,1-.542.884H2.872A.683.683,0,0,1,2.33,18.509Z"
                          transform="translate(0.5 -1.75)"
                          fill="none"
                          stroke="#222328"
                          strokeWidth="1"
                        />
                      </svg>
                    </span>
                    <span className="nav-item-text">
                      <>{t<string>("sideNavActivitylbl")}</>
                    </span>
                    <i className="acc-btn-arrow aha-icon-arrow-down"></i>
                  </AccordionHeader>
                  <AccordionBody
                    tag="ul"
                    id="sidenavActivityContainer"
                    accordionId="activityMenu"
                    className={"aui-sidenav-acc-body"}
                    aria-labelledby="headingActivities"
                  >
                    {subMenuArray.activitiesMenu.map((list, i) => {
                      return list.hide ? null : (
                        <li key={i} className="">
                          <NavLink
                            activeClassName="active"
                            className=""
                            key={list.path}
                            aria-label={list.name}
                            to={list.path}
                            onClick={(event) => {
                              setParentId("activityMenu");
                              return activeMenuHandler(event);
                            }}
                          >
                            {list.displayName}
                          </NavLink>
                        </li>
                      );
                    })}
                  </AccordionBody>
                </AccordionItem>
              )}

              {/* Archived Files Menu Item */}
              {subMenuArray.archivedFilesMenu.some((item) => !item.hide) && (
                <AccordionItem tag="li" id="sidenav-accordion-dropdown_4">
                  <AccordionHeader
                    tag="button"
                    className={classNames(
                      "btn btn-text aui-sidenav-acc-header",
                      {
                        parentHighlight: parentId === "archivedFilesMenu",
                      }
                    )}
                    id="headingArchivedFiles_1"
                    targetId="archivedFilesMenu"
                    aria-expanded={parentId === "archivedFilesMenu"}
                    aria-controls="sidenavheadingArchivedFilesContainer"
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21.5"
                        height="21.5"
                        viewBox="0 0 21.5 21.5"
                      >
                        <path
                          id="Icon_ionic-md-archive"
                          data-name="Icon ionic-md-archive"
                          d="M22.875,5.875,21.319,3.986a1.58,1.58,0,0,0-1.278-.611H6.708a1.58,1.58,0,0,0-1.278.611L3.875,5.875a1.934,1.934,0,0,0-.5,1.389V21.153A2.229,2.229,0,0,0,5.6,23.375H21.153a2.229,2.229,0,0,0,2.222-2.222V7.264A1.934,1.934,0,0,0,22.875,5.875Zm-9.5,13.611L7.264,13.375h3.889V11.153H15.6v2.222h3.889ZM5.708,5.6,6.6,4.486H19.93L20.986,5.6Z"
                          transform="translate(-2.625 -2.625)"
                          fill="none"
                          stroke="#222328"
                          strokeWidth="1.5"
                        />
                      </svg>
                    </span>
                    <span className="nav-item-text">
                      <>{t<string>("sideNavArchiveFilelbl")}</>
                    </span>
                    <i className="acc-btn-arrow aha-icon-arrow-down"></i>
                  </AccordionHeader>
                  <AccordionBody
                    tag="ul"
                    id="sidenavheadingArchivedFilesContainer"
                    accordionId="archivedFilesMenu"
                    className={"aui-sidenav-acc-body"}
                    aria-labelledby="headingArchivedFiles"
                  >
                    {subMenuArray.archivedFilesMenu.map((list, i) => {
                      return list.hide ? null : (
                        <li key={i} className="">
                          <NavLink
                            activeClassName="active"
                            className=""
                            key={list.path}
                            aria-label={list.name}
                            to={list.path}
                            onClick={(event) => {
                              setParentId("archivedFilesMenu");
                              return activeMenuHandler(event);
                            }}
                          >
                            {list.displayName}
                          </NavLink>
                        </li>
                      );
                    })}
                  </AccordionBody>
                </AccordionItem>
              )}

              {/* Reports Menu Item */}
              {subMenuArray.reports.some((item) => !item.hide) && (
                <AccordionItem tag="li" id="sidenav-accordion-dropdown_5">
                  <AccordionHeader
                    tag="button"
                    className={classNames(
                      "btn btn-text aui-sidenav-acc-header",
                      {
                        parentHighlight: parentId === "reports",
                      }
                    )}
                    id="headingreports"
                    data-testid="headingreports"
                    targetId="reports"
                    aria-expanded={parentId === "reports"}
                    aria-controls="sidenavheadingReportContainer"
                  >
                    <span className="fill-icon">
                      <svg
                        id="Icons_Documentation"
                        data-name="Icons/Documentation"
                        xmlns="http://www.w3.org/2000/svg"
                        width="19.729"
                        height="22"
                        viewBox="0 0 19.729 22"
                      >
                        <path
                          id="Path_3485"
                          data-name="Path 3485"
                          d="M64.615,135.556l-4.745-3.845a3.278,3.278,0,0,0-2.07-.706H48.816a2.831,2.831,0,0,0-3.013,2.582v16.837a2.832,2.832,0,0,0,3.014,2.582h13.7a2.831,2.831,0,0,0,3.013-2.582V137.41a2.441,2.441,0,0,0-.916-1.855m-5.327,2.286h4.128v12.582a.842.842,0,0,1-.9.768l-13.7,0a.845.845,0,0,1-.9-.769V133.587a.845.845,0,0,1,.9-.769h8.435V136.1a1.915,1.915,0,0,0,2.037,1.74m.078-1.811v-2.236l2.758,2.236Z"
                          transform="translate(-45.803 -131.005)"
                          fill="#222328"
                        />
                        <path
                          id="Path_3486"
                          data-name="Path 3486"
                          d="M48.465,139.517a.868.868,0,0,0,.925.793H60.152a.8.8,0,1,0,0-1.586H49.39a.868.868,0,0,0-.925.793"
                          transform="translate(-45.053 -130.233)"
                          fill="#222328"
                        />
                        <path
                          id="Path_3487"
                          data-name="Path 3487"
                          d="M60.153,141.769H49.389a.8.8,0,1,0,0,1.585H60.153a.8.8,0,1,0,0-1.585"
                          transform="translate(-45.053 -129.929)"
                          fill="#222328"
                        />
                        <path
                          id="Path_3488"
                          data-name="Path 3488"
                          d="M52.948,144.814H49.39a.8.8,0,1,0,0,1.585h3.558a.8.8,0,1,0,0-1.585"
                          transform="translate(-45.053 -129.624)"
                          fill="#222328"
                        />
                      </svg>
                    </span>
                    <span className="nav-item-text">
                      <>{t<string>("reports")}</>
                    </span>
                    <i className="acc-btn-arrow aha-icon-arrow-down"></i>
                  </AccordionHeader>
                  <AccordionBody
                    tag="ul"
                    id="sidenavheadingReportContainer"
                    accordionId="reports"
                    className={"aui-sidenav-acc-body"}
                    aria-labelledby="headingreports"
                  >
                    {subMenuArray.reports.map((list, i) => {
                      return list.hide ? null : (
                        <li
                          key={i}
                          className="Accr_parentMenu custom-report-menu"
                        >
                          <NavLink
                            activeClassName="active"
                            className=""
                            key={list.path}
                            data-testid={list.name}
                            aria-label={list.name}
                            to={list.path}
                            onClick={(event) => {
                              setParentId("reports");
                              return activeMenuHandler(event);
                            }}
                          >
                            {list.displayName}

                            {list.name === "customReports" &&
                              subMenuArray.my_reports.some(
                                (item) => !item.hide
                              ) &&
                              customReportState?.customReportFilters?.data?.customReportFilters?.length >
                                0 && (
                                <span
                                  className={classNames("submenu_toggleIcon", {
                                    iconMinus_style: custReportSubMenu,
                                    iconPlus_style: !custReportSubMenu,
                                  })}
                                  data-testid="plus-icon-report"
                                  onClick={(
                                    e: React.MouseEvent<HTMLSpanElement>
                                  ) => {
                                    e.preventDefault();
                                    if (e.stopPropagation) {
                                      e.stopPropagation(); // W3C model
                                    } else {
                                      e.cancelable = true; // IE model
                                    }
                                    setCustReportSubMenu(
                                      (isCustReportSubmenu) =>
                                        !isCustReportSubmenu
                                    );

                                    custReportSubMenu &&
                                      setMyReportsExpanded(false);

                                    setParentId("reports");
                                  }}
                                >
                                  {custReportSubMenu ? "-" : "+"}
                                </span>
                              )}
                          </NavLink>

                          {list.name === "customReports" &&
                            subMenuArray.my_reports.map(
                              (list_inner, i_inner) => {
                                return list_inner.hide
                                  ? null
                                  : custReportSubMenu && (
                                      <ul
                                        className="pb-0 collapse certi_substyle show"
                                        onClick={() =>
                                          setMyReportsExpanded(
                                            !myReportsExpanded
                                          )
                                        }
                                      >
                                        <li>
                                          <a
                                            href="#"
                                            onClick={handleMyReportsClick}
                                          >
                                            {t<string>("myReports")}
                                          </a>
                                        </li>
                                      </ul>
                                    );
                              }
                            )}
                        </li>
                      );
                    })}
                  </AccordionBody>
                </AccordionItem>
              )}

              {/* Logs Menu Item */}
              {subMenuArray.auditLog_menu.some((item) => !item.hide) && (
                <AccordionItem tag="li" id="sidenav-accordion-dropdown_3">
                  <AccordionHeader
                    tag="button"
                    className={classNames(
                      "btn btn-text aui-sidenav-acc-header",
                      {
                        parentHighlight: parentId === "auditLogMenu",
                      }
                    )}
                    id="sidenavHeadingOne_id"
                    targetId="auditLogMenu"
                    data-testid="logHeader"
                    aria-expanded={parentId === "auditLogMenu"}
                    aria-controls="sidenavcollapseLog"
                  >
                    <span className="fill-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="22"
                        viewBox="0 0 20 22"
                      >
                        <path
                          id="Icon_metro-files-empty"
                          data-name="Icon metro-files-empty"
                          d="M23.557,9.6a19.719,19.719,0,0,0-1.946-2.142,20.038,20.038,0,0,0-2.226-1.873,3.633,3.633,0,0,0-2.03-.907H9.142A1.755,1.755,0,0,0,7.356,6.4V22.209a1.755,1.755,0,0,0,1.786,1.719H22.713A1.755,1.755,0,0,0,24.5,22.209V11.553c0-.308-.1-.845-.942-1.953ZM20.6,8.43a19.007,19.007,0,0,1,1.62,1.748H18.784V6.871A19.251,19.251,0,0,1,20.6,8.43Zm2.469,13.78a.355.355,0,0,1-.357.344H9.142a.355.355,0,0,1-.357-.344V6.4a.355.355,0,0,1,.357-.344h8.214v4.812a.7.7,0,0,0,.714.688h5ZM16.528,2.835a3.633,3.633,0,0,0-2.03-.907H6.284A1.755,1.755,0,0,0,4.5,3.647V19.459a1.739,1.739,0,0,0,1.429,1.684V3.647A.355.355,0,0,1,6.284,3.3H17.152c-.215-.17-.425-.328-.624-.468Z"
                          transform="translate(-4.499 -1.928)"
                          fill="#222328"
                        />
                      </svg>
                    </span>
                    <span className="nav-item-text">
                      <>{t<string>("log")}</>
                    </span>
                    <i className="acc-btn-arrow aha-icon-arrow-down"></i>
                  </AccordionHeader>
                  <AccordionBody
                    tag="ul"
                    id="sidenavcollapseLog"
                    accordionId="auditLogMenu"
                    className={"aui-sidenav-acc-body"}
                    aria-labelledby="sidenavHeadingOne_id"
                  >
                    {subMenuArray.auditLog_menu.map((list, i) => {
                      return list.hide ? null : (
                        <li key={i} className="">
                          <NavLink
                            data-testid={list.name}
                            activeClassName="active"
                            className=""
                            key={list.path}
                            aria-label={list.name}
                            to={list.path}
                            onClick={(event) => {
                              return activeMenuHandler(event);
                            }}
                          >
                            {list.displayName}
                          </NavLink>
                        </li>
                      );
                    })}
                  </AccordionBody>
                </AccordionItem>
              )}
            </Accordion>
            <Accordion
              flush
              open={parentId}
              tag="ul"
              className="w-100 mb-0 mbl-header-nav d-lx-none d-lg-none"
              toggle={toggle}
            >
              {canAccessUserManagementList && (
                <li
                  id="sidenav-accordion-dropdown-mobile-link"
                  className="d-lg-none d-xl-none"
                >
                  <button
                    className={classNames(
                      "btn btn-text aui-sidenav-acc-header",
                      {
                        parentHighlight: parentId === "users",
                      }
                    )}
                    id="headingArchivedFiles"
                    onClick={(event) => {
                      activeMenuHandler(event);
                      history.push("/users");
                    }}
                  >
                    <span className="fill-icon">
                      <svg
                        id="Icons_View_Permissions"
                        data-name="Icons/View Permissions"
                        xmlns="http://www.w3.org/2000/svg"
                        width="23.287"
                        height="23"
                        viewBox="0 0 23.287 23"
                      >
                        <path
                          id="Path_3472"
                          data-name="Path 3472"
                          d="M4.623,16.594H2.134a.714.714,0,0,1-.562-.267.735.735,0,0,1-.149-.616,7.768,7.768,0,0,1,7.4-6.152c.06,0,.119,0,.179,0s.121,0,.181,0a7.7,7.7,0,0,1,2.54.489.7.7,0,1,0,.494-1.316q-.175-.066-.351-.123a4.781,4.781,0,1,0-5.723,0A9.143,9.143,0,0,0,.046,15.424,2.136,2.136,0,0,0,2.134,18H4.623a.7.7,0,1,0,0-1.406Zm1-11.813A3.375,3.375,0,1,1,9.165,8.152L9,8.15l-.165,0A3.379,3.379,0,0,1,5.625,4.781Z"
                          transform="translate(0)"
                          fill="#222328"
                        />
                        <g
                          id="Icons_Settings"
                          data-name="Icons/Settings"
                          transform="translate(8.891 8)"
                        >
                          <path
                            id="Path_3483"
                            data-name="Path 3483"
                            d="M52.6,105.931h0a3.923,3.923,0,0,0,0-7.845h0a3.923,3.923,0,0,0,0,7.845m.113-1.765-.106,0a2.053,2.053,0,1,1,.106,0"
                            transform="translate(-45.412 -94.508)"
                            fill="#222328"
                          />
                          <path
                            id="Path_3484"
                            data-name="Path 3484"
                            d="M56.863,100.626l-.029-.023h-.005l-.582-.484a.8.8,0,0,1-.106-1.112.849.849,0,0,1,.106-.107l.612-.509a1.24,1.24,0,0,0,.28-1.576L55.932,94.7a1.212,1.212,0,0,0-1.491-.541l-.741.28a.765.765,0,0,1-.6-.02.775.775,0,0,1-.408-.442.83.83,0,0,1-.039-.147l-.125-.789a1.221,1.221,0,0,0-1.206-1.036H48.9A1.222,1.222,0,0,0,47.7,93.044l-.131.789a.777.777,0,0,1-1.045.61l-.741-.28a1.211,1.211,0,0,0-1.492.541l-1.206,2.111a1.239,1.239,0,0,0,.281,1.576l.611.508a.795.795,0,0,1,.106,1.112.8.8,0,0,1-.106.107l-.611.509a1.239,1.239,0,0,0-.281,1.576l1.206,2.111a1.212,1.212,0,0,0,1.492.541l.741-.28a.786.786,0,0,1,1.045.61l.13.788a1.221,1.221,0,0,0,1.205,1.034h2.423a1.22,1.22,0,0,0,1.205-1.035l.13-.789a.786.786,0,0,1,.316-.512.771.771,0,0,1,.583-.137.739.739,0,0,1,.146.04l.741.28a1.212,1.212,0,0,0,1.491-.541l1.207-2.111a1.24,1.24,0,0,0-.281-1.577m-11.311-.914a2.111,2.111,0,0,0-.467-1.544,2.15,2.15,0,0,0-.284-.287l-.56-.465,1.139-2,.678.257a2.057,2.057,0,0,0,1.6-.055,2.083,2.083,0,0,0,1.09-1.179,2.163,2.163,0,0,0,.1-.393l.119-.722h2.279l.119.722a2.1,2.1,0,0,0,.845,1.369,2.043,2.043,0,0,0,1.555.365,2.011,2.011,0,0,0,.39-.106l.681-.258,1.14,1.995-.559.465a2.127,2.127,0,0,0-.284,2.969,2.155,2.155,0,0,0,.284.287l.56.465-1.141,2-.681-.258a2.082,2.082,0,0,0-2.685,1.235,2.159,2.159,0,0,0-.1.393l-.119.722H48.969l-.119-.722a2.088,2.088,0,0,0-2.4-1.733,2.053,2.053,0,0,0-.389.1l-.679.257-1.139-2,.56-.465a2.1,2.1,0,0,0,.75-1.425"
                            transform="translate(-42.914 -92.008)"
                            fill="#222328"
                          />
                        </g>
                      </svg>
                    </span>
                    <span className="nav-item-text">
                      <>{t<string>("userManagement")}</>
                    </span>
                  </button>
                </li>
              )}
              <AccordionItem tag="li" id="sidenav-accordion-dropdown_0">
                <AccordionHeader
                  tag="button"
                  targetId="myaccount"
                  className={classNames(
                    "btn btn-text aui-sidenav-acc-header d-lg-none d-xl-none",
                    {
                      parentHighlight: parentId === "myaccount",
                    }
                  )}
                  id="sidenavHeadingOne"
                  aria-label="myaccount"
                  aria-expanded={parentId === "myaccount"}
                  aria-controls="myaccount"
                >
                  <span>
                    <svg
                      id="Icons-Avatar-1"
                      xmlns="http://www.w3.org/2000/svg"
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                    >
                      <g id="Path_615" data-name="Path 615" fill="none">
                        <path
                          d="M10,0A10,10,0,0,1,20,10a10.47,10.47,0,0,1-.534,3.231A10,10,0,1,1,10,0Z"
                          stroke="none"
                        />
                        <path
                          d="M 10 0.9999961853027344 C 5.037380218505859 0.9999961853027344 1 5.037385940551758 1 10.0000057220459 C 1 14.96262550354004 5.037380218505859 19.00001525878906 10 19.00001525878906 C 11.95411014556885 19.00001525878906 13.81962966918945 18.38884544372559 15.3948802947998 17.23257637023926 C 16.8944206237793 16.13187599182129 18.0007495880127 14.60708618164062 18.51007080078125 12.93909549713135 L 18.51155090332031 12.93431568145752 C 18.85391998291016 11.83283615112305 19 10.95528602600098 19 10.0000057220459 C 19 5.037385940551758 14.96261978149414 0.9999961853027344 10 0.9999961853027344 M 10 -3.814697265625e-06 C 15.52285003662109 -3.814697265625e-06 20 4.477155685424805 20 10.0000057220459 C 20 11.02173614501953 19.84676933288574 12.00767517089844 19.46648025512695 13.23113632202148 C 18.30765914916992 17.02617645263672 14.50111961364746 20.00001525878906 10 20.00001525878906 C 4.477149963378906 20.00001525878906 0 15.52285575866699 0 10.0000057220459 C 0 4.477155685424805 4.477149963378906 -3.814697265625e-06 10 -3.814697265625e-06 Z"
                          stroke="none"
                          fill="#222328"
                        />
                      </g>
                      <ellipse
                        id="head1"
                        cx="2.536"
                        cy="2.536"
                        rx="2.536"
                        ry="2.536"
                        transform="translate(7.562 5)"
                        fill="none"
                        stroke="#222328"
                        strokeLinecap="round"
                        strokeMiterlimit={10}
                        strokeWidth={1}
                      />
                      <path
                        id="Body1"
                        d="M24.325,58.15a3.928,3.928,0,0,0-1.5,3.087h7.879a3.932,3.932,0,0,0-1.494-3.087"
                        transform="translate(-16.671 -47.711)"
                        fill="none"
                        stroke="#222328"
                        strokeLinecap="round"
                        strokeMiterlimit={10}
                        strokeWidth={1}
                      />
                    </svg>
                  </span>
                  <span className="nav-item-text">
                    <>{t<string>("myAccount")}</>
                  </span>
                  <i className="acc-btn-arrow aha-icon-arrow-down"></i>
                </AccordionHeader>
                <AccordionBody
                  tag="ul"
                  className={"aui-sidenav-acc-body"}
                  accordionId="myaccount"
                  id="myaccount"
                  aria-labelledby="sidenavHeadingOne"
                >
                  <li className="">
                    <a
                      onClick={signout}
                      className="dropdown-item py-2"
                      role="button"
                      data-testid= "signout"
                      // onKeyUp={signout}
                      tabIndex={0}
                    >
                      <>{t<string>("signOut")}</>
                    </a>
                  </li>
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
        </nav>
        <div
          className="nav-side-icons-only"
          style={
            props.showSidePanel ? { display: "none" } : { display: "block" }
          }
        >
          {subMenuArray.component.some((item) => !item.hide) && (
            <div>
              <button
                aria-label={t<string>("components")}
                className="btn btn-text"
                onClick={() => {
                  props.setSidePanel(true);
                  setParentId("components");
                }}
              >
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/side-nav-component-blk.svg"
                  }
                  alt="components"
                />
              </button>
            </div>
          )}
          {subMenuArray.activitiesMenu.some((item) => !item.hide) && (
            <div>
              <button
                aria-label={t<string>("sideNavActivitylbl")}
                className="btn btn-text"
                onClick={() => {
                  props.setSidePanel(true);
                  setParentId("activityMenu");
                }}
              >
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/side-nav-activiies-blk.svg"
                  }
                  alt="activity menu icon"
                />
              </button>
            </div>
          )}
          {subMenuArray.archivedFilesMenu.some((item) => !item.hide) && (
            <div>
              <button
                aria-label={t<string>("sideNavArchiveFilelbl")}
                className="btn btn-text"
                onClick={() => {
                  props.setSidePanel(true);
                  setParentId("archivedFilesMenu");
                }}
              >
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/side-nav-archive-blk.svg"
                  }
                  alt="archived files menu icon"
                />
              </button>
            </div>
          )}
          {subMenuArray.reports.some((item) => !item.hide) && (
            <div>
              <button
                aria-label={t<string>("reports")}
                className="btn btn-text"
                onClick={() => {
                  props.setSidePanel(true);
                  setParentId("reports");
                }}
              >
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/side-nav-reports-blk.svg"
                  }
                  alt="reports"
                />
              </button>
            </div>
          )}

          {subMenuArray.auditLog_menu.some((item) => !item.hide) && (
            <div>
              <button
                aria-label={t<string>("log")}
                className="btn btn-text"
                onClick={() => {
                  props.setSidePanel(true);
                  setParentId("auditLogMenu");
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/images/side-nav-logs-blk.svg"}
                  alt="logs icon"
                />
              </button>
            </div>
          )}
        </div>
      </div>
    </LeftPanelWrapper>
  );
};

export default AdminLeftPanel;

AdminLeftPanel.propTypes = {
  toggle: PropTypes.func.isRequired,
};
