class ResponseError extends Error {
  public target?: string;

  constructor(public code: string, public statusCode: number) {
    super(code);
  }
}

export const BAD_REQUEST = new ResponseError("BAD_REQUEST", 400);

export const ACCESS_DENIED = new ResponseError("ACCESS_DENIED", 403);

export const NOT_FOUND = new ResponseError("NOT_FOUND", 404);

export const INTERNAL_SERVER_ERROR = new ResponseError(
  "INTERNAL_SERVER_ERROR",
  500
);
