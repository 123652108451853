import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface LeftPanelState {
  showMyReports: boolean;
}

export const initialState: LeftPanelState = {
  showMyReports: false,
};

const adminLeftPanelSlice = createSlice({
  name: "leftPanel",
  initialState,
  reducers: {
    setShowMyReports: (state, action) => {
      state.showMyReports = action.payload;
    },
  },
});

const { actions, reducer } = adminLeftPanelSlice;

export const { setShowMyReports } = actions;
export default reducer;
