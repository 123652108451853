import React, { useState, FunctionComponent, useEffect, useRef } from "react";
import { ViewReportWrapper } from "./Styled";
import { useTranslation } from "react-i18next";
import { Routes } from "common/constants";
import _ from "lodash";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import { breadcrumbRoutes } from "components/Breadcrumb/BreadcrumbRoutes";
import { RouterProps } from "react-router";
import CustomisedTable from "components/CustomisedTable";
import CONSTANTS from "common/constants/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  handleKeyDown,
  KeyDownsetPageSizeHandler,
  keydowntoggleNoOfRecordTableFilter,
  KeyUpChangeColumnHandler,
  KeyUpsetPageSizeHandler,
  mouseDown,
  mouseDownParent,
  onBlurPageSize,
  onChangePageSize,
  toggleNoOfRecordTableFilter,
  toggleTableFilterDropdown,
} from "common/utils/commonFunctionality";
import Pagination from "components/Pagination";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { ListCustomReportByFilters } from "services/api/report.api";
import { setPreviewData } from "pages/Report/CustomReport/CustomReportSlice";
import store from "app/store";
import { showToast } from "components/Toast/toast.slice";
import { useHistory } from "react-router-dom";

export type ReportPreview = RouterProps;

const PreviewReport: FunctionComponent<ReportPreview> = (props) => {
  const { t } = useTranslation();
  const [exportTypeErrorClass, setExportTypeErrorClass] =
    useState<boolean>(false);
  const [cme_pageID] = useState(CONSTANTS.CUSTOM_REPORT_PAGEID);

  const countRef = useRef(0);
  const dispatch = useDispatch();

  const customReportingState = useSelector((state: any) => state.customReport);

  const [paginations, setPaginations] = useState({
    pageNumber: 1,
    pageSize: 25,
  });

  const history = useHistory();

  const onChangePageNumber = (pageNumber: number) => {
    setPaginations({ ...paginations, pageNumber: pageNumber });
    window.scrollTo(0, 0);
  };

  const setPageSizeHandler = (size: number) => {
    setPaginations({ ...paginations, pageSize: size });
  };

  const generateReportData = async () => {
    dispatch(setPageLoadingStatus({ isPageLoading: true }));

    try {
      const response = await ListCustomReportByFilters(
        customReportingState.filters,
        paginations
      );

      if (response?.data?.status === 200) {

        dispatch(setPreviewData({
          ...customReportingState,
          previewData: response?.data
        }))
      }
    } catch (err: any) {
      store.dispatch(
        showToast({
          title: "CustomReport",
          message: err.response?.data?.error?.details[0]?.message,
        })
      );
    }

    dispatch(setPageLoadingStatus({ isPageLoading: false }));
  };

  useEffect(() => {
    countRef.current !== 0 && generateReportData();

    countRef.current++;
  }, [paginations]);

  useEffect(() => {
    if(_.isEmpty(customReportingState?.previewData)){
      history.go(-2)
    }
  },[])

  return (
    <ViewReportWrapper>
      <div className="view-report-wrapper w-100">
        <div className="view-report-container ">
          <Breadcrumb {...props} paths={breadcrumbRoutes.reportPreview} />
          <div className="sub-heads-pr view-report justify-content-between align-items-center d-block w-100 float-left mb-1">
            <h5 className="view-report-title" data-testid="viewReportTitle">
              {t<string>("reportPreview")}
            </h5>
          </div>

          {/* Table */}
          <div
            className="table-responsive aui-table table-Container custom-report-table"
            data-testid="customisedTable"
          >
            {" "}
            {customReportingState.tableColumns?.length > 0 &&
              !_.isEmpty(customReportingState.previewData) && (
                <CustomisedTable
                  columnFields={customReportingState.tableColumns}
                  viewContent={() => {
                    /* do nothing */
                  }}
                  editContent={() => {
                    /* do nothing */
                  }}
                  tableLoader={false}
                  contents={
                    customReportingState.previewData?.data?.customReport
                  }
                  //actions={actionRef.current}
                  isActiveToggle={() => {
                    /* do nothing */
                  }}
                  cme_pageID={cme_pageID}
                >
                  <div className="filerHeader filter-nav d-flex w-100 cp-mrgnb-20 align-items-end cp-flex-direction align-items-center">
                    <div
                      className="mr-auto page-number filterHeaderChildElement"
                      onBlur={onBlurPageSize}
                    >
                      {t<string>("showing")}{" "}
                      {`${
                        customReportingState.previewData?._pagination &&
                        customReportingState.previewData?._pagination
                          ?.pageOffset ||  0
                      } - ${
                        customReportingState.previewData?._pagination
                          ?.totalPages !== paginations.pageNumber
                          ? paginations.pageNumber * paginations.pageSize
                          : customReportingState.previewData?._pagination
                              ?.totalCount
                      }`}
                      <div className="svg-parent position-relative">
                        <label
                          htmlFor="pagenumberitems1"
                          className="position-absolute invisible-cls"
                        >
                          {t<string>("pagenumberitems")}
                        </label>
                        <div
                          className="keydown-recordfilter d-inline-block"
                          tabIndex={0}
                          onClick={toggleNoOfRecordTableFilter}
                          aria-label="Please choose number of items should be displayed in one page"
                          role="combobox"
                          aria-controls="pagenumberitems1"
                          aria-owns="pagenumberitems1"
                          aria-expanded="true"
                          aria-haspopup="listbox"
                        >
                          <input
                            type="text"
                            id="pagenumberitems1"
                            aria-label="pagenumberitems1"
                            data-testid="pagenumberitems1"
                            className="position-absolute invisible-cls"
                            tabIndex={-1}
                          />
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="position-relative bi bi-chevron-down"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                            />
                          </svg>
                        </div>
                        <div
                          className="page-sort position-absolute m-0 d-none pagecontent-filter"
                          id="pagelistitems_filter"
                          role="listbox"
                          aria-label={t<string>("filter")}
                        >
                          {CONSTANTS.TableRecordSizeList.map((page) => (
                            <div
                              tabIndex={0}
                              role="option"
                              data-testid={page}
                              className={
                                paginations?.pageSize === page ? "active" : ""
                              }
                              key={page}
                              onClick={(e) => {
                                setPageSizeHandler(page);
                                onChangePageSize(e);
                              }}
                            >
                              {page}
                            </div>
                          ))}
                        </div>
                      </div>
                      {`of ${
                        customReportingState.previewData?._pagination
                          ?.totalCount || 0
                      } ${t<string>("items")}`}
                    </div>

                    <div className="sort-by filterHeaderChildElement text-right">
                      {t<string>("sortByLatest")}
                      <div className="svg-parent position-relative">
                        <div
                          className="d-inline-block"
                          onKeyDown={() => {
                            /* do nothing */
                          }}
                          tabIndex={0}
                          onClick={() => {
                            /* do nothing */
                          }}
                          aria-label={t<string>(
                            "pleaseChooseNumberOfItemsShouldBeDisplayedInOnePage"
                          )}
                          role="combobox"
                          aria-owns="pagenumberitems"
                          aria-controls="pagenumberitems"
                          aria-expanded="true"
                          aria-haspopup="listbox"
                        >
                          <input
                            type="text"
                            id="pagenumberitems"
                            aria-label="pagenumberitems"
                            className="position-absolute invisible-cls"
                            tabIndex={-1}
                          />
                          {"Latest"}
                        </div>
                      </div>
                    </div>
                    
                    {customReportingState?.previewData?.data?.customReport &&
                      customReportingState?.previewData?.data?.customReport
                        ?.length > 0 && (
                        <div className="nav-pagination-div">
                          <Pagination
                            {...customReportingState?.previewData._pagination}
                            setPageNumber={onChangePageNumber}
                            paginationSetLimit={CONSTANTS.PAGINATION_LIMIT}
                          ></Pagination>
                        </div>
                      )}
                  </div>
                </CustomisedTable>
              )}
          </div>
          {customReportingState.previewData?.data?.customReport &&
            customReportingState.previewData?.data?.customReport?.length >
              0 && (
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 px-0">
                <Pagination
                  {...customReportingState.previewData?._pagination}
                  setPageNumber={onChangePageNumber}
                  paginationSetLimit={CONSTANTS.PAGINATION_LIMIT}
                ></Pagination>
              </div>
            )}

          <div className="float-right btn-div ml-auto mob_btnLayout mt-2">
            <div
              aria-label={t<string>("addUserCancel")}
              data-testid="cancel"
              onClick={() => history.goBack()}
            >
              <img src="/images/back-icon.svg" alt="back icon" />
              {t<string>("back")}
            </div>
            <button
              className="btn btn-round btn-primary ml-3"
              aria-label={t<string>("addUserSave")}
              data-testid="save"
              onClick={() => history.go(-2)}
            >
              {t<string>("save")}
            </button>
          </div>
        </div>
      </div>
    </ViewReportWrapper>
  );
};

export default PreviewReport;
