import { diff } from "json-diff";
import getByDot from "lodash/get";

export const jsonDiff = ({
  oldData = {},
  newData = {},
  delta = false,
}: {
  oldData?: any;
  newData?: any;
  delta?: boolean;
}) => {
  const diffObj = diff(oldData || {}, newData || {}, {
    full: !delta,
  });

  return diffObj;
};

export const getByDottedDiff = (diffData: any, path: string) => {
  const pathParts = path.split(".");

  let __old: any;
  let __new: any;

  for (
    let idx = 0, partialPath = pathParts[idx];
    idx < pathParts.length && __old === undefined && __new === undefined;
    idx += 1
  ) {
    const currPartialPath = pathParts[idx + 1];

    const laterPaths = pathParts.slice(idx + 1);

    const normalPath = [partialPath, ...laterPaths].join(".");
    let foundValue = getByDot(diffData, normalPath);
    if (foundValue !== undefined) {
      __old = foundValue?.__old;
      __new = foundValue?.__new;
    }

    const addedPath = [`${partialPath}__added`, ...laterPaths].join(".");
    foundValue = getByDot(diffData, addedPath);
    if (foundValue !== undefined) {
      __new = foundValue?.__new ?? foundValue;
    }

    const deletedPath = [`${partialPath}__deleted`, ...laterPaths].join(".");
    foundValue = getByDot(diffData, deletedPath);
    if (foundValue !== undefined) {
      __old = foundValue?.__old ?? foundValue;
    }

    partialPath = [partialPath, currPartialPath].join(".");
  }

  return {
    __old,
    __new,
  };
};
