import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ErrorListState {
  pageNumber: number;
  search: string;
  filterByVendor: string;
  filterByDate: string;
  pageSize: number;
  loader: boolean;
  pageOrder: string;
  reFetch: boolean;
}

export const initialState: ErrorListState = {
  pageNumber: 1,
  search: "",
  filterByVendor: "",
  filterByDate: "",
  pageSize: 25,
  loader: false,
  pageOrder: "updated_at DESC",
  reFetch: false,
};

const ErrorListSlice = createSlice({
  name: "archiveErrorList",
  initialState,
  reducers: {
    setPageNumber: (state, action: PayloadAction<ErrorListState>) => {
      state.pageNumber = action.payload.pageNumber;
    },
    searchAtivityErrors: (state, action: PayloadAction<string>) => {
      state.pageNumber = 1;
      state.search = action.payload;
    },
    filterByVendor: (state, action: PayloadAction<string>) => {
      state.filterByVendor = action.payload;
    },
    filterBydate: (state, action: PayloadAction<string>) => {
      state.filterByDate = action.payload;
    },
    setPageSize: (state, action: PayloadAction<ErrorListState>) => {
      state.pageSize = action.payload.pageSize;
      state.pageNumber = 1;
    },
    setPageOrder: (state, action) => {
      state.pageOrder = action.payload;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
    toggleReFetch: (state, action: PayloadAction<boolean>) => {
      state.reFetch = !state.reFetch;
      state.pageNumber = 1;
    },
    clearErrorState: () => {
      return initialState;
    },
  },
});

const { actions, reducer } = ErrorListSlice;

export const {
  setPageNumber,
  setLoader,
  setPageOrder,
  searchAtivityErrors,
  filterByVendor,
  filterBydate,
  setPageSize,
  clearErrorState,
  toggleReFetch,
} = actions;

export default reducer;
