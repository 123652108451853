import styled from "styled-components";

const PaginationStyle = styled.div`
  .page-disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .pagination-block {
    margin-top: 0px !important;
    justify-content: flex-end;
    @media screen and (max-width: 991px) {
      width: 100%;
      justify-content: flex-end;
    }
    @media screen and (max-width: 575px) {
      width: 100%;
      justify-content: center;
      margin-left: 0;
    }
  }
  .page-item.active .page-link {
    z-index: 1 !important;
  }
  .page-item .page-link{
    @media screen and (max-width: 767px){
      padding: 0.525rem 15px !important;
    }
  }
`;
export default PaginationStyle;
