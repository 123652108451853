import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "styles/global.scss";
import Routes from "routes";
import { LoaderContextProvider } from "../components/ReactLoader/LoaderContext";
import { RBACProvider, useRBAC } from "@impelsysinc/react-rbac";
import { LocalStorageKeys } from "../common/constants/localStorage";

function App() {
  const [permissions, setPermissions] = useState(() => {
    const perms = localStorage.getItem(LocalStorageKeys.PERMISSIONS);
    if (perms) {
      return JSON.parse(perms);
    }
    return null;
  });

  useEffect(() => {
    const permHandler = (e) => {
      if (e.key !== LocalStorageKeys.PERMISSIONS) return;
      const perms = e.newValue;
      if (perms) {
        setPermissions(JSON.parse(perms));
      } else {
        setPermissions(null);
      }
    };

    window.addEventListener("storage", permHandler);

    return () => {
      window.removeEventListener("storage", permHandler);
    };
  }, []);

  const rbac = useRBAC({
    permissions,
  });

  return (
    <RBACProvider rbac={rbac}>
      <LoaderContextProvider>
        <BrowserRouter>
          <Suspense fallback={null}>
            <Routes />
          </Suspense>
        </BrowserRouter>
      </LoaderContextProvider>
    </RBACProvider>
  );
}

export default App;
