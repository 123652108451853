import { useEffect, useState } from "react";

const useFeatures = () => {
  const [features, setFeatures] = useState(() => ({
    certificateTemplateManagement: localStorage.getItem("features:certificateTemplateManagement") === "true",
    moc: true,
    cmePassport: true,
    reports: localStorage.getItem("features:reports") === "true",
    adminPanel: localStorage.getItem("features:adminPanel") === "true",
  }));
  useEffect(() => {
    window.addEventListener("storage", featureListener)
    function featureListener(ev: any) {
      if (ev.key === "features:certificateTemplateManagement") {
        ;
        setFeatures((feat) => ({
          ...feat,
          certificateTemplateManagement: ev.newValue === "true"
        }))
      } if (ev.key === "features:moc") {
        setFeatures((feat) => ({
          ...feat,
          moc: ev.newValue === "true"
        }))
      } if (ev.key === "features:cmePassport") {
        setFeatures((feat) => ({
          ...feat,
          cmePassport: ev.newValue === "true"
        }))
      } if (ev.key === "features:reports") {
        setFeatures((feat) => ({
          ...feat,
          reports: ev.newValue === "true"
        }))
      }  if (ev.key === "features:adminPanel") {
        setFeatures((feat) => ({
          ...feat,
          adminPanel: ev.newValue === "true",
        }))
      } if (ev.key === "features:customReportRoute") {
        setFeatures((feat) => ({
          ...feat,
          customReportRoute: ev.newValue === "true"
  
        }))
      } else {
          return
      }
    }
    return () => window.removeEventListener("storage", featureListener)
  }, [])
  return features
};
export default useFeatures;
