import styled from "styled-components";
export const SignInWrapper = styled.div`
  .login {
    display: flex;
    flex-direction: column;
  }

  .text-left {
    margin-top: 2%;
  }
`;
