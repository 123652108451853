import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Auth {
  isAuthenticated: boolean;
  permission: Permission;
}

export interface Permission {
  menus?: MenusEntity[] | null;
}
export interface MenusEntity {
  url: string;
  code: string;
  name: string;
  actions: Actions;
  landingPage?: boolean | null;
  menus?: MenusEntity[] | null;
}
export interface Actions {
  add?: boolean;
  edit?: boolean;
  view?: boolean;
  delete?: boolean;
}

export const initialState: Auth = {
  isAuthenticated: false,
  permission: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthStatus: (
      state,
      action: PayloadAction<{ isAuthenticated: boolean }>
    ) => {
      state.isAuthenticated = action.payload.isAuthenticated;
    },
    setPermission: (state, action: PayloadAction<Permission>) => {
      state.permission = action.payload;
    },
  },
});
const { actions, reducer } = authSlice;

export const { setAuthStatus, setPermission } = actions;
export default reducer;
