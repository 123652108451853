import React, {
  createContext,
  FunctionComponent,
  ReactNode,
  useContext,
} from "react";

export interface JSONDiffContextValue {
  diffData?: any;
  delta?: boolean;
  currData: any;
  prevData: any;
}

const JSONDiffContext = createContext<JSONDiffContextValue | null>(null);

export interface JSONDiffProviderProps {
  children: ReactNode;
  value: Partial<JSONDiffContextValue>;
}

export const JSONDiffProvider: FunctionComponent<JSONDiffProviderProps> = ({
  children,
  value,
}) => {
  return (
    <JSONDiffContext.Provider
      value={{ ...value, prevData: value.prevData, currData: value.currData }}
    >
      {children}
    </JSONDiffContext.Provider>
  );
};

export class JSONDiffContextError extends Error {
  constructor() {
    super("JSONDiffContext must be used within JSONDiffContextProvider");
  }
}

export const useJSONDiff = () => {
  const value = useContext(JSONDiffContext);

  if (!value) {
    throw new JSONDiffContextError();
  }

  return value;
};
