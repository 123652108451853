import styled from "styled-components";

export const InvalidUserWrapper = styled.div`

  .header {
    border-bottom: 1px solid rgb(188, 195, 202);
    padding-left: 2%;
    justify-content: space-between;
    > img {
      height: 6rem;
      width: 8rem;
    }
    .sign-out-div {
      align-self: center;
      span {
        cursor: pointer;
        margin-left: 10px;
        margin-right: 2rem;
        font-weight: 500;
      }
      img {
        cursor: pointer;
      }
    }
  }

  .access-denied {
    background-color: #F8F8F8;
    margin-top: 80px;
    height: calc(100vh - 25rem);
    margin-left: 4%;
    margin-right: 4%;
    border: 1px solid #BDC3C9;
    img {
        height: 5rem;
        width: 5rem;
      }
    h3 {
        text-align: center;
        width: 70%;
        .red-font {
          color: #C10E21;
        }
      }
    @media only screen and (min-width: 992px) {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-bottom: 2%;
      font-size: 18px Montserrat;
    }

    @media only screen and (max-width: 991px) {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
      h3 {
        font-size: 18px;
        text-align: center;
      }
    }

    @media only screen and (max-width: 576px) {
      height: 25rem;
      margin-top: 4.5rem;
    }
  }

  .relative {
    bottom: 0;
    @media screen and (max-width: 576px) {
      bottom: -5rem;
      position: relative;
    }
    top: auto !important;
    position: fixed;
  }
`;
