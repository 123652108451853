import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ManagementState {
  pageNumber: number;
  search: string;
  filterByDate: string;
  pageSize: number;
  loader: boolean;
  pageOrder: string;
}

export const initialState: ManagementState = {
  pageNumber: 1,
  search: "",
  filterByDate: "",
  pageSize: 25,
  loader: false,
  pageOrder: "created_at DESC",
};

const managementSlice = createSlice({
  name: "auditLog",
  initialState,
  reducers: {
    setPageNumber: (state, action: PayloadAction<ManagementState>) => {
      state.pageNumber = action.payload.pageNumber;
    },
    searchAuditLog: (state, action: PayloadAction<string>) => {
      state.pageNumber = 1;

      state.search = action.payload;
    },
    filterBydate: (state, action: PayloadAction<string>) => {
      state.filterByDate = action.payload;
    },
    setPageSize: (state, action: PayloadAction<ManagementState>) => {
      state.pageNumber = 1;
      state.pageSize = action.payload.pageSize;
    },
    setPageOrder: (state, action) => {
      state.pageOrder = action.payload;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
    clearAuditLogState: () => {
      return initialState;
    },
  },
});
const { actions, reducer } = managementSlice;

export const {
  setPageNumber,
  setLoader,
  setPageOrder,
  searchAuditLog,
  filterBydate,
  setPageSize,
  clearAuditLogState,
} = actions;
export default reducer;
