import styled from "styled-components";

export const ReportPeriodWrapper = styled.div`

  .end-input-div {
    display: none !important;
  }

  .daily-interval-div {
    display: none !important;
  }

  & .px-0 {
    @media screen and (max-width: 575px) {
      width: 19rem !important;
      height: 25rem;
      overflow-y: auto;
      overflow-x: hidden;
      .text-sm-right {
        margin-right: 0px;
        padding-left: 1rem !important;
      }
      .px3:nth-child(2) {
        margin-left: 1.5rem !important;
      }
      strong {
        word-break: keep-all !important;
      }
      .form-control {
        width: 255px !important;
      }
      select[name='repeat.frequency'],
      select[name='repeat.yearly.on.day'], 
      select[name='repeat.yearly.onThe.day'],
      select[name='repeat.monthly.onThe.day'],
      input[name='repeat.weekly.interval'],
      select[name='repeat.daily.interval'],
      select[name='repeat.monthly.interval'],
      select[name='repeat.yearly.on.month'],
      select[name='repeat.yearly.onThe.which']
       {
        position: relative;
        left: 0px !important;
      }
      select[name='repeat.yearly.on.month'],
      select[name='repeat.yearly.onThe.which'],
      select[name='repeat.monthly.onThe.which']{
        margin-bottom: 10px;
      }
      input[name='repeat.frequency'] {
        margin-bottom: 1rem;
      }
      input[name='repeat.weekly.interval'] {
        left: -3.7rem !important;
        top: -1.3rem;
      }
      div:nth-child(5) {
        margin-left: 2rem;
      }
      input[name='repeat.weekly.interval'] + div {
        margin-top: -20px;
      }
      input[name="end.onDate.date"] + .rdtPicker {
        position: absolute;
        bottom: 0rem;
      }
      .offset-sm-2 {
        left: -1rem !important;
        top: 15px;
      }
      .col-sm-1 {
        text-align: left !important;
        left: 2.4rem;
        bottom: 8px;
      }
      .row .col-sm-3 + .col-sm-1 {
        left: 6.5rem !important;
        margin-top: 18px;
      }  
      .row .col-sm-2 + .col-sm-1 {
        left: 6.5rem !important;
        margin-top: 18px;
      }
      input[name='end.after'] {
        margin-left: 27px;
      }
      input[name='end.onDate.date'] {
        margin-left: 0px!important; ;
        margin-top: 10px;
        margin-bottom: 0px !important;
      }
      .col-9 {
        margin-top: 4rem;
      }      
      .btn-group {
        display: contents;
        .btn-primary {
          width: 5rem !important;
          margin: 2px;
        }
      }
    }
    @media screen and (min-width: 768px) and (max-width: 991px) {
      input[name='end.after']{
        margin-left: -12px;
      }
      .col-sm-1.offset-sm-2 + .col-sm-1 {
        text-align: left;
      }
           
      input[name="end.onDate.date"] + .rdtPicker {
          position: absolute;
          right: -3rem;
        }
        
        input[name="repeat.yearly.mode"], input[name="repeat.monthly.mode"] {
          position: relative;
          top: 2px;
        }
    }
    .col-sm-1.offset-sm-2 + .col-sm-1 {
        text-align: left;
      }

      select[name='repeat.yearly.onThe.day'],select[name='repeat.monthly.onThe.day']  {
        font-size: 13.2px;
      }

      @media screen and (min-width: 992px) {
        input[name="end.onDate.date"] + .rdtPicker {
          position: absolute;
          left: 7rem;
          top: -3rem;
        }
        input[name="repeat.yearly.mode"], input[name="repeat.monthly.mode"] {
          position: relative;
          top: 2px;
        }    
      }

    
    @media screen and (min-width: 576px) and (max-width: 767px) {
      width: 27rem;
      .aui-modal {
        word-break: keep-all !important;
      }
      .col-sm-3 + .col-sm-4 {
        position: relative;
        left: 3.5rem;
      }
      input[name='end.onDate.date'] {
        margin-left: -66px !important;
      } 
      input[name="end.onDate.date"] + .rdtPicker {
        position: absolute;
        right: -6rem;
      }

      input[name="repeat.yearly.mode"]{
          position: relative;
          top: 2px;
        } 

      .form-group.row.d-flex.align-items-sm-center > .col-sm-2 +.col-sm-2 {
        position: relative;
        left: 5rem;
      }
      div:nth-child(4) {
        position: relative;
        left: 5rem;
      }
      .col-sm-3 + .col-6.col-sm-3 {
        position: relative;
        left: 5rem;
      }
      strong {
        word-break: keep-all !important;
      }
      select[name='repeat.yearly.on.day'] {
        width: 4rem;
        position: relative;
        left: -2.5rem;
      }
      select[name='repeat.yearly.onThe.day'] {
        position: relative;
        left: -2.5rem;
      }
      select[name='repeat.monthly.onThe.day'] {
        position: relative;
        left: -2.5rem;
      }
      input[name='repeat.weekly.interval'] {
        position: relative;
        left: -25px;
      }
      select[name='repeat.daily.interval'] {
        left: 2rem !important;
      }
      input[name='end.onDate.date'] {
        left: -0.9rem !important;
        position: relative;
        height: 2.5rem;
      }
      input[name='end.after'] {
        width: 4rem !important;
        left: -1.9rem !important;
        position: relative;
        min-height: 2.5rem;
      }
      .align-items-sm-center {
        margin-left: -25px;
      }
      .px-3 > .form-group {
        margin-left: -25px;
      }
      
      div:nth-child(6) {
        left: 6rem;
        top: 5px;
      }
      select[name='repeat.monthly.interval'] {
        left: 2rem !important;
      }
      .row .col-sm-3 + .col-sm-1 {
        left: 5.5rem !important;
      }
      .row .col-sm-2 + .col-sm-1 {
        left: 5.5rem !important;
      }
      .btn-group {
        display: contents;
        .btn-primary {
          width: 5rem !important;
          margin: 2px;
        }
      }
      .col-9 {
      word-break: keep-all;
      margin-left: 0px;
      left: -1.2rem !important;
    }

    }

    width: 45rem;
    background-color: #fff;
    input[type="radio"] {
      accent-color: #c10E21;
      height: auto !important;
      outline: none !important;
    }
    position: relative;
    select[name='repeat.monthly.interval'] {
        position: relative !important;
        left: 3.6rem;
      }
      input[name='repeat.weekly.interval'] {
        position: relative !important;
        margin-left: 44px;
      }
      select[name='repeat.daily.interval'] {
        position: relative !important;
        left: 3.6rem;
      }
    .row {
      width: 100%;
      .col-sm-1 {
        padding-left: 0px;
        padding-right: 0px;
        margin-right: 0px;
        text-align: center;
        word-break: keep-all;
      }
      .col-sm-2 {
        margin-right: 32px;
      }
      .col-sm-3 {
        padding-right: 0px;
      }
      .col-sm-4 {
        position: relative;
        right: 27px;
      }
      .rdt {
        position: relative;
        right: 27px;
        input[name='start.onDate.date'] {
          cursor: default;
        }
      }
      .col-sm-3 + .col-sm-1 {
        position: relative;
        left: 3.5rem;
      }
      .col-sm-2 + .col-sm-1 {
        position: relative;
        left: 3.5rem;
      }

      @media screen and (min-width: 768px) {
        div:nth-child(5) {
          left: -0.5rem !important;  
        }
      }
      .btn-group {
        margin-left: 18px !important;
        .active {
          background-color: #c10E21 !important;
          color: white !important;
          border-color: #C10E21 !important;
        }
        .btn-primary {
          background-color: white;
          color: #c10E21;
          width: 5rem;
          margin-right: 10px;
          padding-left: 1.5rem;
          border-radius: 0px;
          input {
            width: 5rem;
          }
        }
      }
      .offset-sm-2 {
        margin-left: 32px
      }
      .form-control {
          padding-right: 10px;
          width: 132px;
        }
    }
    .col-9 {
      word-break: keep-all;
      margin-left: 0px;
      position: relative;
      left: 2rem;
    }
    .text-sm-right {
      text-align: left !important;
      position: relative;
      padding-left: 2rem;
    }
  }

  & .px-3 {
    .btn {
      word-break: normal;
    }
    .row {
      .col-sm-3 {
        margin-right: 0px;
      }
      .col-6 {
        .rdt {
          margin-left: 27px;
          input[name='end.onDate.date'] {
          cursor: default;
          margin-left: 0px;
        }
        }
      }
    }
  }
  & .overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    height: -webkit-fill-available;
    background: rgba(0, 0, 0, 0.56);
    overflow: auto;
    z-index: 1060;
    justify-content: center;
    width: -webkit-fill-available;
    align-items: center;
  }
  & .child-overlay {
    background: white;
    button {
      margin: 10px;
      /* float: right; */
    }
  }
  & .background-overlay {
    display: flex;
    justify-content: flex-start;
    width: 27rem;
   /*  height: 28rem; */
    background-color: #fff;
    margin-top: 205px;
    border-radius: 5px;
    margin-left: 250px;
  }

  & .overlay-header {
    border-bottom: 1px solid #CECECE;
    width: 100%;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  & .date-div {
    width: 90%;
    display: flex;
    align-self: flex-start;
    margin-top: 10px;
     input {
        width: 100%;
        /* white-space: pre-wrap !important; */
        height: auto !important;
        min-height: 40px;
        padding-left: 5px;
        padding-right: 5px;
        border-radius: 0px;
        border: 1.5px solid #CECECE;
    }
  }

  & .overlay-body {
    align-self: flex-start;
    /* height: 40%; */
    width: 90%;
    margin-top: 15px;
    margin-bottom: 10px;
    .form-group {
      margin-top: 18px;
      display: flex;
      justify-content: space-between;
      label {
        padding-top: 6px;
      }
    }
    input, select {
      border: 1.5px solid #CECECE;
      width: 50%;
    }
    .week-buttons {
      padding: 8px 12px;
      margin-right: 2px;
      border-radius: 50%;
      width: 3rem;
    }
  }

  & .overlay-button-div {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .btn-primary {
      margin-left: 15px;
    }
  }

  & .rr-button-div {
    justify-content: flex-end;
  }
  

  & .red-x {
    color: #C10E21;
  }
 
`;