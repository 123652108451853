import React, { FunctionComponent, ReactNode, useState } from "react";
import classNames from "classnames";
import { omit } from 'lodash'
import { Transition } from "react-transition-group";
import {
  mapToCssModules,
  pick,
  TransitionTimeouts,
  TransitionPropTypeKeys,
  TransitionStatuses,
} from "./utils";
import { CSSModule, InnerRef, TagPropType } from "./types";
import { TransitionProps } from "react-transition-group/Transition";

export type CollapsePropTypes = TransitionProps & {
  /** Make content animation appear horizontally */
  horizontal?: boolean;
  /** Set if Collapse is open or closed */
  isOpen?: boolean;
  children?: ReactNode;
  /** Set a custom element for this component */
  tag?: TagPropType;
  /** Add custom class */
  className?: string;
  navbar?: boolean;
  /** Change underlying component's CSS base class name */
  cssModule?: CSSModule;
  innerRef?: InnerRef;
  testId?: string;

  [key: string]: any;
};

const defaultProps = {
  // ...Transition.defaultProps,
  appear: false,
  enter: true,
  exit: true,
  timeout: TransitionTimeouts.Collapse,
};

const transitionStatusToClassHash = {
  [TransitionStatuses.ENTERING]: "collapsing",
  [TransitionStatuses.ENTERED]: "collapse show",
  [TransitionStatuses.EXITING]: "collapsing",
  [TransitionStatuses.EXITED]: "collapse",
};

function getTransitionClass(status: string) {
  return transitionStatusToClassHash[status] || "collapse";
}

export const Collapse: FunctionComponent<CollapsePropTypes> = (props) => {
  props = Object.assign({}, defaultProps, props);
  const {
    tag: Tag = "div",
    horizontal = false,
    isOpen = false,
    className,
    navbar,
    cssModule,
    children,
    innerRef,
    testId = props["data-testid"] || props.id,
    ...otherProps
  } = props;

  const nodeRef = innerRef || React.createRef();

  const [dimension, setDimension] = useState<number | null>(null);

  const onEntering = (isAppearing: boolean) => {
    const node = getNode();
    setDimension(getDimension(node));
    props.onEntering?.(node, isAppearing);
  };

  const onEntered = (isAppearing: boolean) => {
    const node = getNode();
    setDimension(null);
    props.onEntered?.(node, isAppearing);
  };

  const onExit = () => {
    const node = getNode();
    setDimension(getDimension(node));
    props.onExi?.(node);
  };

  const onExiting = () => {
    const node = getNode();
    // getting this variable triggers a reflow
    getDimension(node); // eslint-disable-line no-unused-vars
    setDimension(0);
    props.onExiting?.(node);
  };

  const onExited = () => {
    const node = getNode();
    setDimension(null);
    props.onExited?.(node);
  };

  const getNode = (): HTMLElement => {
    // @ts-ignore
    return nodeRef?.current;
  };

  const getDimension = (node: HTMLElement) => {
    return props.horizontal ? node.scrollWidth : node.scrollHeight;
  };

  const transitionProps = pick(otherProps, TransitionPropTypeKeys);

  const childProps = omit(otherProps, TransitionPropTypeKeys);

  return (
    // @ts-ignore
    <Transition
      {...transitionProps}
      in={isOpen}
      nodeRef={nodeRef}
      onEntering={onEntering}
      onEntered={onEntered}
      onExit={onExit}
      onExiting={onExiting}
      onExited={onExited}
    >
      {(status) => {
        const collapseClass = getTransitionClass(status);
        const classes = mapToCssModules(
          classNames(
            className,
            horizontal && "collapse-horizontal",
            collapseClass,
            navbar && "navbar-collapse"
          ),
          cssModule
        );
        const style =
          dimension === null
            ? null
            : { [horizontal ? "width" : "height"]: dimension };
        return (
          <Tag
            {...childProps}
            data-testid={testId}
            // @ts-ignore
            style={{ ...childProps.style, ...style }}
            className={classes}
            ref={nodeRef}
          >
            {children}
          </Tag>
        );
      }}
    </Transition>
  );
};
