export const programTypeMapping : any = {
    "1" : "Live activity",
    "2" : "Enduring material",
}

export const programSubTypeMapping : any = {
    "3" : "Internet live",
    "4" : "Journals",
    "5" : "Performance Improvement Module",
    "6" : "Course",
    "7" : "Manuscript Review Activity"
}
