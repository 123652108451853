import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface PredefinedReportState {
  pageNumber: number;
  vendor: string;
  startDate:string;
  endDate:string;
  accreditation: string;
  program: string;
  programName: string[];
  programType: string;
  reportType: string;
  frequency: string;
  notifyMe: boolean;
  mocType: string;
  pageSize: number;
  loader: boolean;
  pageOrder: string;
}

export const initialState: PredefinedReportState = {
  pageNumber: 1,
  vendor: "",
  startDate:"",
  endDate:"",
  accreditation: "",
  program: "",
  programName: [],
  programType: "",
  mocType: "",
  reportType: "",
  frequency: "",
  notifyMe: false,
  pageSize: 25,
  loader: false,
  pageOrder: "updated_at DESC",
};

const predefinedReportSlice = createSlice({
  name: "predefinedReport",
  initialState,
  reducers: {
    setPageNumber: (
      state,
      action: PayloadAction<PredefinedReportState>
    ) => {
      state.pageNumber = action.payload.pageNumber;
    },
    searchVendor: (
      state,
      action: PayloadAction<PredefinedReportState>
    ) => {
      state.vendor = action.payload.vendor;
    },
    searchStartDate: (state, action: PayloadAction<PredefinedReportState>) => {
      state.startDate = action.payload.startDate;
    },
    searchEndDate: (state, action: PayloadAction<PredefinedReportState>) => {
      state.endDate = action.payload.endDate;
    },
    searchAccreditation: (
      state,
      action: PayloadAction<PredefinedReportState>
    ) => {
      state.accreditation = action.payload.accreditation;
    },
    searchProgram: (
      state,
      action: PayloadAction<PredefinedReportState>
    ) => {
      state.program = action.payload.program;
      state.programName = action.payload.programName;
    },
    searchProgramType: (
      state,
      action: PayloadAction<PredefinedReportState>
    ) => {
      state.programType = action.payload.programType;
    },
    setReportType: (
      state,
      action: PayloadAction<PredefinedReportState>
    ) => {
      state.reportType = action.payload.reportType;
    },
    setFrequency: (
      state,
      action: PayloadAction<PredefinedReportState>
    ) => {
      state.frequency = action.payload.frequency;
    },
    setNotifyMe: (
      state,
      action: PayloadAction<PredefinedReportState>
    ) => {
      state.notifyMe = action.payload.notifyMe;
    },
    setMocType: (
      state,
      action: PayloadAction<PredefinedReportState>
    ) => {
      state.mocType = action.payload.mocType;
    },
    setPageSize: (
      state,
      action: PayloadAction<PredefinedReportState>
    ) => {
      state.pageSize = action.payload.pageSize;
      state.pageNumber = 1;
    },
    setPageOrder: (
      state,
      action: PayloadAction<PredefinedReportState>
      ) => {
      state.pageOrder = action.payload.pageOrder;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
    clearPredefinedReportState: () => {
      return initialState;
    },
    clearSelectedAccreditation : (state) => {
      state.accreditation = "";
    },
    clearSelectedStartDate : (state) => {
      state.startDate = "";
    },
    clearSelectedEndDate : (state) => {
      state.endDate = "";
    }
  },
});
const { actions, reducer } = predefinedReportSlice;

export const {
  setPageNumber,
  setLoader,
  setPageOrder,
  searchVendor,
  searchStartDate,
  searchEndDate,
  searchAccreditation,
  searchProgram,
  searchProgramType,
  setReportType,
  setFrequency,
  setNotifyMe,
  setMocType,
  setPageSize,
  clearPredefinedReportState,
  clearSelectedAccreditation,
  clearSelectedStartDate,
  clearSelectedEndDate,
} = actions;
export default reducer;
