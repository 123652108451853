import axios from "axios";
import config from "Config";
import LocalStore from "common/LocalStore";
import { APIRoutes } from "../../common/constants";
import { LocalStorageKeys } from "../../common/constants/localStorage";
import * as adminAPI from "./adminLogin.api";

export const axiosVendor = axios.create({
  baseURL: config[config.env].apiEndpoints.vendor,
});

export const axiosAccount = axios.create({
  baseURL: config[config.env].apiEndpoints.account,
});

const refreshAccessToken = async () => {
  const refreshToken = await LocalStore.get(LocalStorageKeys.REFRESH_TOKEN);
  if (refreshToken) {
    const response = await adminAPI.refreshToken(refreshToken);
    const token = response.data?.data?.token;
    if (response.status === 200) {
      await LocalStore.set(
        LocalStorageKeys.ACCESS_TOKEN,
        token?.accessToken
      );
      await LocalStore.set(
        LocalStorageKeys.REFRESH_TOKEN,
        token?.refreshToken
      );
    }
  }
};

axiosAccount.interceptors.request.use((request) => {
  if (request.url?.includes(APIRoutes.SSO_LOGIN)) {
    return request;
  }

  LocalStore.get(LocalStorageKeys.ACCESS_TOKEN).then((response) => {
    if (response) {
      request.headers["Authorization"] = `Bearer ${response}`;
    } else throw new Error("Unauthorized");
  });
  return request;
});

axiosAccount.interceptors.response.use((response) => {
  return response;
});

// ------------------

axiosVendor.interceptors.request.use((request) => {
  const accessToken = localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN);
  if (accessToken) {
    request.headers["Authorization"] = `Bearer ${accessToken}`;
  } else {
    throw new Error("Unauthorized");
  }
  return request;
});

async function errorResponseHandler(error: any) {
  const originalConfig = error.config;
  if (error.response) {
    // Access Token was expired
    if (error.response.status === 403 && !originalConfig._retry) {
      originalConfig._retry = true;

      try {
        await refreshAccessToken();

        return axiosVendor(originalConfig);
      } catch (_error) {
        if (_error.response && _error.response.data) {
          window.location.pathname = "/";
          return Promise.reject(_error.response.data);
        }

        return Promise.reject(_error);
      }
    }

    if (error.response.status === 403 && error.response.data) {
      window.location.pathname = "/";
      return Promise.reject(error.response.data);
    }
  }

  return Promise.reject(error);
}

axiosVendor.interceptors.response.use(async (response) => {
  return response;
}, errorResponseHandler);

export const axiosAuditLog = axios.create({
  baseURL: config[config.env].apiEndpoints.auditLog,
});

axiosAuditLog.interceptors.request.use((request) => {
  LocalStore.get(LocalStorageKeys.ACCESS_TOKEN).then((response) => {
    if (response) {
      request.headers["Authorization"] = `Bearer ${response}`;
    } else throw new Error("Unauthorized");
  });
  return request;
});

axiosAuditLog.interceptors.response.use((response) => {
  return response;
}, errorResponseHandler);

export const axiosReporting = axios.create({
  baseURL: config[config.env].apiEndpoints.reporting,
});

axiosReporting.interceptors.request.use((request) => {
  const accessToken = localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN);

  if (accessToken) {
    request.headers["Authorization"] = `Bearer ${accessToken}`;
  } else {
    throw new Error("Unauthorized");
  }
  return request;
});

axiosReporting.interceptors.response.use((response) => {
  return response;
}, errorResponseHandler);
