import React, { FunctionComponent } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { TableColumnsInterface } from "./index";
import ModalPopupComp from "../../components/ModalPopup/index";
import { CONSTANTS } from "common/constants";

export type ActionPredicate<Content = any> = (content: Content) => boolean;

export interface Actions<Content = any> {
  add?: boolean;
  edit?: boolean;
  view?: boolean;
  delete?: boolean;
  restore?: boolean;
  download?: boolean | ActionPredicate<Content>;
}

export type ActionFn = (id: number) => any;

export interface ActionProps<Content = any> {
  id?: string;
  actions?: Actions<Content>;
  content: Content;
  iconTextOnly?: boolean;
  editContent?: ActionFn;
  viewContent?: ActionFn;
  downloadContent?: ActionFn;
  index: number;
  isVisibleDelBtn?: boolean;
  onStatusToggle: (event: any, index: number, name: string) => any;
  columns: TableColumnsInterface[];
  deleteRecords?: Function;
  MsgBody?: Function;
  cme_pageID?: string;
  isToggleAllowed?: boolean;
}

export const Action: FunctionComponent<ActionProps> = (props) => {
  const { t } = useTranslation();


  const {
    actions,
    id,
    content,
    iconTextOnly,
    editContent,
    onStatusToggle,
    downloadContent,
    columns,
    viewContent,
    index,
    isVisibleDelBtn = false,
    deleteRecords,
    MsgBody = () => {
      return null;
    },
    cme_pageID,
    isToggleAllowed,
  } = props;

  const UID =
    id ??
    Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "");

  const isDownload =
    typeof actions?.download === "function"
      ? actions?.download(content)
      : actions?.download;

  return actions?.add || actions?.edit || actions?.view || isDownload ? (
    <td data-title="Action" className="action_column">
      <div className="aui-td">
        <ul className="cpaction-icons p-0 mb-0 d-flex justify-content-start w-100">
          {/* Download Icon */}
          {isDownload ? (
            <li
              className={classNames("position-relative", {
                "text-only-icon-item": iconTextOnly,
              })}
            >
              <a
                href="#"
                data-testid="download"
                aria-label={t<string>("downloadPage")}
                onClick={(e) => {
                  e.preventDefault();
                  downloadContent?.(
                    typeof cme_pageID !== "undefined" &&
                      (cme_pageID === CONSTANTS.REPORTLOG_PAGEID ||
                        cme_pageID === CONSTANTS.CUSTOMREPORTLOG_PAGEID)
                      ? content.targetId
                      : content.id
                  );
                }}
              >
                {iconTextOnly ? (
                  t<string>("download")
                ) : (
                  <img
                    src="../images/download.png"
                    alt={t<string>("viewContentIcon")}
                  />
                )}
              </a>
              <span className="position-absolute">{t<string>("download")}</span>
            </li>
          ) : null}

          {/* View Icon */}
          {actions?.view && (
            <li
              className={classNames("position-relative", {
                "text-only-icon-item": iconTextOnly,
              })}
            >
              <a
                href="#"
                aria-label={t<string>("viewPage")}
                onClick={() => {
                  viewContent?.(content.id);
                }}
              >
                {iconTextOnly ? (
                  t<string>("view")
                ) : (
                  <img
                    src="../images/View.svg"
                    alt={t<string>("viewContentIcon")}
                  />
                )}
              </a>
              <span className="position-absolute">{t<string>("view")}</span>
            </li>
          )}

          {/* Edit Icon */}
          {actions?.edit && (
            <li className="position-relative">
              <a
                aria-label={t<string>("editOrModifyPage")}
                onClick={() => {
                  editContent?.(content.id);
                }}
              >
                <img src="../images/edit.svg" alt={t<string>("editContent")} />
              </a>
              <span className="position-absolute">
                {t<string>("edit/Modify")}
              </span>
            </li>
          )}

          {/* Toggle Icon */}
          {actions?.edit && (
            <li className="position-relative">
              <div className="custom-control custom-switch aui-custom-switch">
                <input
                  type="checkbox"
                  disabled={isToggleAllowed ? !actions?.edit : true}
                  onChange={(event) => {
                    onStatusToggle(
                      event,
                      index,
                      content["name"] || content["title"]
                        ? content["name"] || content["title"]
                        : content[columns[0].propertyName]
                    );
                  }}
                  className="custom-control-input"
                  aria-label={
                    content.isActive
                      ? t<string>("deactivate")
                      : t<string>("activate")
                  }
                  checked={content.isActive}
                  id={content.id}
                  data-testid={content.id}
                />
                <label className="custom-control-label" htmlFor={content.id} />
                <span className="position-absolute">
                  {content.isActive
                    ? t<string>("active")
                    : t<string>("inactive")}
                </span>
              </div>
            </li>
          )}

          {/* Delete Icon */}
          {actions?.delete && (
            <li
              className={isVisibleDelBtn ? "position-relative del" : "d-none"}
              id={"del_" + UID}
            >
              <a
                href="#"
                aria-label={t<string>("delete")}
                data-toggle="modal"
                data-target={`#del_modal_${UID}`}
              >
                <img
                  src={process.env.PUBLIC_URL + `/images/delete-icon.svg`}
                  alt={t<string>("delete")}
                />
              </a>
              <span className="position-absolute">{t<string>("delete")}</span>
            </li>
          )}

          {/* Restore Icon */}
          {actions?.restore && (
            <li
              className={isVisibleDelBtn ? "position-relative del" : "d-none"}
              id={"restore_" + index}
            >
              <a
                href="#"
                aria-label={t<string>("restore")}
                data-toggle={"modal"}
                data-target={`#del_modal_${UID}`}
                className="restore_icon"
              >
                <img src="../images/restore.svg" alt={t<string>("restore")} />
              </a>
              <span className="position-absolute">{t<string>("restore")}</span>
            </li>
          )}
        </ul>
        {actions?.delete && (
          <ModalPopupComp
            childrenBody={<MsgBody />}
            displayButtons={true}
            id={`del_modal_${UID}`}
            popupDiscardFunc={(e: React.MouseEvent) => {
              if (deleteRecords) {
                deleteRecords(e, [content?.id]);
              }
            }}
          />
        )}
      </div>
    </td>
  ) : (
    <td data-title="Action" className="action_column">
      <div className="aui-td no-data-display-class">N/A</div>
    </td>
  );
};
