import React from "react";
import { components, OptionProps } from "react-select";
import type { GroupBase as GroupTypeBase } from "react-select";
import { InputOptionStyle } from "./Styled";

const Option = <
  OptionType,
  IsMulti extends boolean,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>(
  props: OptionProps<OptionType, IsMulti, GroupType>
) => {
  return (
    <InputOptionStyle>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          className="multi-select-checkbox"
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </InputOptionStyle>
  );
};

export default Option;
