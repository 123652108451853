import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ManagementState {
  pageNumber: number;
  status: string;
  filterByVendor: string;
  filterByDate: string;
  pageSize: number;
  loader: boolean;
  pageOrder: string;
}

export const initialState: ManagementState = {
  pageNumber: 1,
  status: "",
  filterByVendor: "",
  filterByDate: "",
  pageSize: 25,
  loader: false,
  pageOrder: "updated_at DESC",
};

const managementSlice = createSlice({
  name: "batchLog",
  initialState,
  reducers: {
    setPageNumber: (state, action: PayloadAction<ManagementState>) => {
      state.pageNumber = action.payload.pageNumber;
    },
    filterByStatus: (state, action: PayloadAction<string>) => {
      state.pageNumber = 1;
      state.status = action.payload;
    },
    filterByVendor: (state, action: PayloadAction<string>) => {
      state.filterByVendor = action.payload;
    },
    filterBydate: (state, action: PayloadAction<string>) => {
      state.filterByDate = action.payload;
    },
    setPageSize: (state, action: PayloadAction<ManagementState>) => {
      state.pageNumber = 1;
      state.pageSize = action.payload.pageSize;
    },
    setPageOrder: (state, action) => {
      state.pageOrder = action.payload;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
    clearBatchLogState: () => {
      return initialState;
    },
  },
});
const { actions, reducer } = managementSlice;

export const {
  setPageNumber,
  setLoader,
  setPageOrder,
  filterByStatus,
  filterByVendor,
  filterBydate,
  setPageSize,
  clearBatchLogState,
} = actions;
export default reducer;
