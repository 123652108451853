import React from "react";
import PropTypes from "prop-types";
import InputTextStyle from "./Styled";

interface Props {
  elementObject: any;
}

export const CheckBox = ({ elementObject }: Props) => {
  return (
    <InputTextStyle>
      <input
        type="checkbox"
        className="form-control"
        id={elementObject.id}
        data-testid={elementObject.id}
        name={elementObject.name}
        checked={elementObject.defaultChecked}
        onChange={elementObject.handleData}
        disabled={elementObject.disabled ?? false}
      />
      <label
        htmlFor={elementObject.id}
        className="customCheck_nextSpan"
      ></label>
    </InputTextStyle>
  );
};

CheckBox.propTypes = {
  elementObject: PropTypes.object,
};

CheckBox.defaultProps = {
  elementObject: { id: "", name: "", value: "", type: "" },
};

export default CheckBox;
