import styled from "styled-components";

export const InputTextStyle = styled.div`
  .input--readonly {
    white-space: pre-wrap !important;
    max-width: 200px;
    height: auto !important;
    min-height: 40px;
    overflow-wrap: break-word;
  }
  .multi {
    input:focus {
      outline: 0 !important;
      box-shadow: none !important;
    }

    [class*="control"] {

      [class*="placeholder"] {
        color: #222328;
      }
      [class*="indicatorContainer"] {
        color: #6b6b6b;
        position: sticky;
        top: 19px;
        bottom: 18px;
        > svg {
          width: 25px;
          height: 25px;
        }
      }

      [class*="indicatorSeparator"] {
        display: none;
      }
    }
  }
`;

export const InputOptionStyle = styled.div`
  .multi-select-checkbox[type="checkbox"]:checked {
    accent-color: #c10e21;
  }
`;

export default InputTextStyle;
