import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ClassificationManagementState {
  pageNumber: number;
  search: string;
  pageSize: number;
  loader: boolean;
  pageOrder: string;
}

export const initialState: ClassificationManagementState = {
  pageNumber: 1,
  search: "",
  pageSize: 25,
  loader: false,
  pageOrder: "updated_at DESC",
};

const classificationManagementSlice = createSlice({
  name: "classification",
  initialState,
  reducers: {
    setPageNumber: (
      state,
      action: PayloadAction<ClassificationManagementState>
    ) => {
      state.pageNumber = action.payload.pageNumber;
    },
    searchClassification: (
      state,
      action: PayloadAction<ClassificationManagementState>
    ) => {
      state.pageNumber = 1;
      state.search = action.payload.search;
    },
    setPageSize: (
      state,
      action: PayloadAction<ClassificationManagementState>
    ) => {
      state.pageSize = action.payload.pageSize;
      state.pageNumber = 1;
    },
    setPageOrder: (state, action) => {
      state.pageOrder = action.payload.pageOrder;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
    clearClassificationState: () => {
      return initialState;
    },
  },
});
const { actions, reducer } = classificationManagementSlice;

export const {
  setPageNumber,
  setLoader,
  setPageOrder,
  searchClassification,
  setPageSize,
  clearClassificationState,
} = actions;
export default reducer;
