import React, { FunctionComponent, ReactNode, useEffect } from "react";

import { AdminLayout as Layout } from "components/AdminLayout";
import { Wrapper } from "styled";
import { RouterProps } from "react-router-dom";
import { LocalStorageKeys } from "../../../common/constants/localStorage";
import { useRBACContext } from "@impelsysinc/react-rbac";

type AdminLayoutProps = RouterProps & { children: ReactNode };

const AdminLayout: FunctionComponent<AdminLayoutProps> = (props) => {
  const { setPermissions } = useRBACContext();

  useEffect(() => {
    const storagePermissions = localStorage.getItem(
      LocalStorageKeys.PERMISSIONS
    );
    if (storagePermissions) {
      const permissions = JSON.parse(storagePermissions);
      setPermissions(permissions);
    }
  }, [setPermissions]);

  return (
    <Wrapper className="d-flex flex-column">
      <Layout {...props}>{props.children}</Layout>
    </Wrapper>
  );
};

export default AdminLayout;
