import React, { FunctionComponent, useEffect, useState } from "react";
import InputTextStyle from "./Styled";
import classNames from "classnames";

export interface RenderTextFieldProps {
  elementObject?: any;
  handleData?: (e: any) => any;
  [key: string]: any;
}

export const RenderTextField: FunctionComponent<RenderTextFieldProps> = ({
  elementObject,
  handleData,
  children,
  ...props
}) => {
  const [inputValue, setInputValue] = useState("");
  const stripHtml = (str: any) => {
    setInputValue(str.replace(/(<([^>]+)>)/gi, ""));
  };
  useEffect(() => {
    setInputValue(elementObject.value);
  }, [elementObject.value]);
  return (
    <InputTextStyle>
      <input
        type={elementObject.type ?? "text"}
        disabled={elementObject.disabled ?? false}
        className={classNames("form-control", elementObject.className)}
        id={elementObject.id}
        data-testid={elementObject.id}
        name={elementObject.name}
        value={
          typeof inputValue === "string"
            ? inputValue.replace(/(<([^>]+)>)/gi, "")
            : inputValue ?? ""
        }
        maxLength={elementObject.maxLength}
        onChange={(e) => stripHtml(e.target.value)}
        onBlur={handleData}
        required={
          elementObject.required !== undefined ? elementObject.required : true
        }
        {...props}
      />
    </InputTextStyle>
  );
};

RenderTextField.defaultProps = {
  elementObject: { id: "", name: "", value: "", type: "", required: true },
  handleData: () => null,
};

export default RenderTextField;
