import React from "react";
import PaginationStyle from "./styled";

export interface IPagination {
  pageNumber: number;
  pageSize: number;
  totalCount?: number;
  totalPages?: number;
  isFirst: number;
  isLast: number;
  setPageNumber: any;
  paginationSetLimit: number;
}

const Pagination = ({
  pageNumber,
  pageSize,
  totalCount,
  totalPages,
  isFirst,
  isLast,
  setPageNumber,
  paginationSetLimit,
}: IPagination) => {
  let paginationSetIndex = 0;
  let paginationSetMaxLimit = paginationSetLimit;

  if (totalPages && totalPages < paginationSetLimit) {
    paginationSetMaxLimit = totalPages;
  } else if (pageNumber / paginationSetLimit > 1) {
    paginationSetIndex =
      Math.floor((pageNumber - 1) / paginationSetLimit) * paginationSetLimit;
    if (Number(totalPages) - (pageNumber - 1) < paginationSetLimit) {
      paginationSetMaxLimit = Number(totalPages) - paginationSetIndex;
      if (paginationSetMaxLimit > paginationSetLimit) {
        paginationSetMaxLimit = paginationSetLimit;
      }
    }
  } else if (totalPages === 0) {
    paginationSetMaxLimit = 1;
  }

  return (
    <PaginationStyle>
      <div className="d-flex pagination-block">
        <nav aria-label="Page pagination">
          <ul className="pagination aui-pagination mt-4 mb-5 pb-3">
            <li className="page-item">
              <button
                className={
                  isFirst === 1 ? "page-link page-disabled" : "page-link"
                }
                aria-label="Previous"
                disabled={isFirst === 1}
                data-testid="previous"
                onClick={() => setPageNumber(pageNumber - 1)}
              >
                <span aria-hidden="true">&laquo;</span>
              </button>
            </li>
            {[...Array(paginationSetMaxLimit)].map((x, i) => (
              <li
                key={i}
                className={
                  1 + i + paginationSetIndex === pageNumber
                    ? "page-item active"
                    : "page-item"
                }
              >
                <button
                  role="link"
                  className="page-link"
                  aria-label={
                    1 + i + paginationSetIndex === pageNumber
                      ? "current page " + (1 + i + paginationSetIndex)
                      : "page " + (1 + i + paginationSetIndex)
                  }
                  onClick={() => {
                    setPageNumber(1 + i + paginationSetIndex);
                  }}
                >
                  {1 + i + paginationSetIndex}
                </button>
              </li>
            ))}
            <li className="page-item">
              <button
                className={
                  isLast === 1 || pageNumber === totalPages
                    ? "page-link page-disabled"
                    : "page-link"
                }
                aria-label="Next"
                data-testid="next"
                disabled={isLast === 1}
                onClick={() => {
                  setPageNumber(pageNumber + 1);
                }}
              >
                <span aria-hidden="true">&raquo;</span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </PaginationStyle>
  );
};

export default React.memo(Pagination);
