import React from 'react'
import { ReportPeriodWrapper } from './styled'
import RRuleGenerator from './lib'
import moment from 'moment'

interface ReportPeriodProps {
    show: boolean
    setShow: Function
    ConvertToJson: Function
    placeHolder: any
    setPlaceHolder: Function
    addSchedulerData: any
    setSchedulerData: Function
    formState: any
}

const ReportPeriodPopover: React.FC<ReportPeriodProps> = ({ show, setShow, ConvertToJson, placeHolder, setPlaceHolder, addSchedulerData, setSchedulerData, formState }: ReportPeriodProps) => {

    const stateOrder = ["Daily","Weekly","Yearly","Monthly"]
    const end = ["On date"]
    const defaultValue = ['FREQ:DAILY', 'INTERVAL:1']
    const [rules, setRules] = React.useState<string>("")
                
    function ruleToJSON(rule: string) {
        const rrule = rule.split(/[;\n]RRULE:/)
        rrule[1] = rrule[1].replace(/=/g,":")
        const final = rrule[1].split(/[;]/)
        final.push(rrule[0])
        const jsonRule = JSON.parse(JSON.stringify(final))
        setRules(jsonRule);
    }

    function setPlaceHolderData(addSchedulerData: any) {
        if(addSchedulerData.startDate){
            setPlaceHolder(placeHolder === "Custom" ? "Custom" : "Select");
        } else {
            setPlaceHolder("Select");
        }
    }

    const todayFormat = moment.utc().format("YYYY-MM-DD")

  return (
    <ReportPeriodWrapper >              
        <div className={ show ? `modal aui-modal overlay` : `d-none`}>
            <div className='child-overlay'> 
                <RRuleGenerator 
                    onChange={(rrule: any) => ruleToJSON(rrule)} 
                    config={{repeat: stateOrder, hideStart: false, end: end}} 
                    addSchedulerData={addSchedulerData}
                    setSchedulerData={setSchedulerData}
                    formState={formState}
                    />
            <div className='rr-button-div d-flex'>
                <button className='button btn btn-secondary btn-round' onClick={() => {
                    setPlaceHolderData(addSchedulerData)
                    setShow(false);
                    }}>Cancel</button>
                <button className='button btn btn-primary btn-round' data-testid ='popOver_save' onClick={() => {
                    
                    if((!addSchedulerData.startDate && !addSchedulerData.endDate)){
                        setSchedulerData({
                            ...addSchedulerData, startDate: todayFormat, endDate: todayFormat
                        })
                        
                    } else if(addSchedulerData.startDate && !(addSchedulerData.endDate)){
                        setSchedulerData({
                            ...addSchedulerData, endDate: addSchedulerData.startDate
                        })
                    } else if(!addSchedulerData.startDate) {
                        setSchedulerData({
                            ...addSchedulerData, startDate: todayFormat,
                        })
                    }
                    ConvertToJson(rules || defaultValue)
                    setShow(false)
                }}>Save</button>
            </div>
            </div>
        </div>
    </ReportPeriodWrapper>
  )
}

export default ReportPeriodPopover;
