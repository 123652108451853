export const AccreditationCodes = {
  CAPCE: "CAPCE / CECBEMS",
  AMA_PRA_CAT_1 :"AMA PRA Category 1",
  AARC: "AARC",
  AOTA: "AOTA",
  CDR: "CDR",
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type AccreditationCodes =
  typeof AccreditationCodes[keyof typeof AccreditationCodes];
