import styled from "styled-components";

export const JSONDiffStyleWrapper = styled.div`
  .diff-container {
    text-align: left;
    padding: 1rem;
    .diff-section {
      margin-top: 1rem;
    }
    .section-title {
      font-size: 1rem;
    }
    .diff-section-items {
      font-size: 0.87rem;
    }
  }
`;
