import React, { Component, ReactNode } from "react";

const isDev = process.env.NODE_ENV === "development";

export default class ErrorBoundary extends Component<{ children: ReactNode }> {
  state: { error: Error | null; errorInfo: any } = {
    error: null,
    errorInfo: null,
  };

  componentDidCatch(error: Error, errorInfo: any) {
    // Catch errors in any components below and re-render with an error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <h2>Something went wrong.</h2>
          {isDev && (
            <details style={{ whiteSpace: "pre-wrap" }}>
              {this.state.error && this.state.error?.toString()}
              <br />
              {this.state.errorInfo?.componentStack}
            </details>
          )}
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
