import { format as dateFnsFormat, parseISO} from 'date-fns';

const invalidDates = ["01-01-1", "1/1/1", "1-1-1", "0001-01-01T00:00:00Z"];
const NA = "N/A";

export const DateWithTimeByLocale = (
  dateStr: any,
  delimeter: string,
  splitBy: string,
  format = "iso",
  hasTimeZone = false
) => {
  const date_str = dateStr ? dateStr : "";

  if (invalidDates.indexOf(date_str) > -1) {
    return NA;
  }

  const newDate = new Date(date_str);
  const seperator = delimeter ? delimeter : "/";
  const split = splitBy ? splitBy : "-";

  // eslint-disable-next-line no-self-compare
  let date: any = newDate.getTime() === newDate.getTime() ? newDate : NA;

  if (date === NA) {
    return NA;
  }

  switch (format) {
    case "iso":
      date = newDate.toISOString();
      break;
    case "locale":  
      date = dateFnsFormat(new Date(date), 'yyyy-MM-dd HH:mm:ss');   
      break;
    default:
      date = newDate.toISOString();
      break;
  }

  if (!hasTimeZone) {
    const ind = date?.lastIndexOf(".");
    if (ind > 0) {
      date = date?.substring(0, ind);
    }
  }

  date = date?.replace("T", " ");

  return date;
};

export const DateByLocale = (
  dateStr: string,
  delimeter: string,
  splitBy: string,
  format = "iso"
) => {
  const timestamp = DateWithTimeByLocale(
    dateStr,
    delimeter,
    splitBy,
    format,
    false
  );

  if (timestamp === NA) {
    return timestamp;
  } else {
    return timestamp.split(" ")?.[0];
  }
};

export const DateFormat = (dateStr: string) => {
  const newDate = new Date(dateStr);
  return `${("0" + (newDate.getMonth() + 1)).slice(-2)}/${(
    "0" + newDate.getDate()
  ).slice(-2)}`;
};

export const DateOnly = (date: string | Date): string => {
  const d = new Date(date).toISOString();
  const [dPart1] = d.split(/[T\s]/);
  return dPart1;
};

/**
 * Converts a UTC date string to local date without time.
 * @param date - The UTC date string in ISO 8601 format. e.g: 2023-06-11T05:30:00Z (UTC)
 * @returns The formatted local date without seconds. 2023-06-11 (Local)
 */
export const UTCtoDateOnlyLocale = (date: string): string => {  
  const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{3})?Z$/
  if(!date || !regex.test(date)) {
    return date
  }
  date = dateFnsFormat(parseISO(date), 'yyyy-MM-dd'); 
  return date;
};

export const DateFullFormat = (dateStr: string, format: string) => {
  const newDate = new Date(dateStr);

  let formattedDate= "";
  if (format === "MM/DD/YYYY"){
    formattedDate = `${("0" + (newDate.getMonth() + 1)).slice(-2)}/${("0" + newDate.getDate()).slice(-2)}/${newDate.getFullYear()}`;
  } else if (format === "YYYY-MM-DD"){
    formattedDate = `${newDate.getFullYear()}-${("0" + (newDate.getMonth() + 1)).slice(-2)}-${("0" + newDate.getDate()).slice(-2)}`;
  } if (format === "MM/DD"){
    formattedDate = `${("0" + (newDate.getMonth() + 1)).slice(-2)}/${("0" + newDate.getDate()).slice(-2)}`;
  } else if (format === "MM"){
    formattedDate = `${("0" + (newDate.getMonth() + 1)).slice(-2)}`;
  } else if (format === "DD"){
    formattedDate = `${("0" + newDate.getDate()).slice(-2)}`;
  }
  return formattedDate
};

export const getDateRange = () => {     //returns today's date in this string format: 2023-02-01~2023-02-14
  const dates = new Date();

  return `${dates.getFullYear()}-${("0" + (dates.getMonth() + 1)).slice(
    -2
  )}-${("0" + dates.getDate()).slice(-2)}~${dates.getFullYear()}-${(
    "0" +
    (dates.getMonth() + 1)
  ).slice(-2)}-${("0" + dates.getDate()).slice(-2)}`;
};


/**
 * Converts a UTC date string to local time and returns the formatted local time string.
 * @param dateTime - The UTC date string in ISO 8601 format. e.g: 2023-06-11T05:30:00Z (UTC)
 * @returns The formatted local time string in ISO 8601 format without seconds. 2023-06-11T11:00 (Local)
 */
export const UTCDateTimetoLocalDateTime = (dateTime: string): string => {
  if(!dateTime){
    return ""
  }
  const utcDate = new Date(dateTime);
  const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000);
  const localTimeString = localDate.toISOString().slice(0, 16);
  return localTimeString;
}

export const convertISOtoDateTime = (dateStr : string) =>{
  if(dateStr == "" || !dateStr) return ""
  return dateStr?.substring(0, 16) 
}

export const convertISOtoDate = (dateStr : string) =>{
  if(dateStr == "" || !dateStr) return ""
  return dateStr?.substring(0, 10) 
}

export const convertStringToDateTime = (dateStr : string) =>{
  if(dateStr == "" || !dateStr) return ""
  let arr = dateStr.split("T");
  dateStr = arr[0] + " " + arr[1];
  return dateStr.replace(/[T,Z]/g,"");
}


