import { axiosVendor } from "./ApiServices";
import { compactObject } from "../../common/utils/compactObject";
import * as CertificateTemplatesResponse from "../../common/types/CertificateTemplatesResponse";
import * as CertificateTemplatesGETResponse from "../../common/types/CertificateTemplatesGETResponse";
import { AxiosResponse } from "axios";

export const GetCertificationByFilter = async (
  certifications: any
): Promise<AxiosResponse<CertificateTemplatesResponse.Root>> => {
  const queryParams = compactObject({
    pageSize: certifications.pageSize,
    pageOrder: certifications.pageOrder,
    search: certifications.search,
    pageNumber: certifications.pageNumber,
    active: certifications.active
  });

  return axiosVendor.get(`/certificateTemplates`, {
    params: queryParams,
  });
};

export const GetCertificationById = async (
  id: string
): Promise<AxiosResponse<CertificateTemplatesGETResponse.Root>> => {
  return axiosVendor.get(`/certificateTemplates/${id}`);
};

export const PatchCertificationById = async (
  id: number,
  body: any
): Promise<any> => {
  return axiosVendor.patch(`/certificateTemplates/${id}`, body);
};

export const PostCertificationApi = async (data: any): Promise<any> => {
  return axiosVendor.post(`/certificateTemplates`, data);
};

export const PutCertificationById = async (
  id: number,
  data: any
): Promise<any> => {
  return axiosVendor.put(`/certificateTemplates/${id}`, data);
};

export const GetCertificationByCode = async (
  id: string
): Promise<AxiosResponse<CertificateTemplatesGETResponse.Root>> => {
  return axiosVendor.get(`/certificateTemplateByCode/${id}`);
};

export const GetcertificateTempRevisionsByCertTempId = async (
  certificateTemplateId: string,
  pageNumber?: any,
  pageSize?: any,
  $search?: string,
): Promise<AxiosResponse<CertificateTemplatesGETResponse.Root>> => {
  const queryParams = compactObject({
    certificateTemplateId,
    programCreditId: 0,
    $search,
    pageNumber,
    pageSize,
  });
  return axiosVendor.get(`/certificateTemplateRevisions`, {
    params: queryParams,
  });
};

export const GetcertificateTempRevisionsList = async (
  certificateTemplateId: string,
  programCreditId: string,
  select?:string
): Promise<AxiosResponse<CertificateTemplatesGETResponse.Root>> => {
  const queryParams = compactObject({
    certificateTemplateId,
    programCreditId,
    $select: select
  });
  return axiosVendor.get(`/certificateTemplateRevisions`, {
    params: queryParams,
  });
};

export const GetcertificateTempRevisionByRevisionId = async (
  revisionId: number,
  select?: string
): Promise<AxiosResponse<CertificateTemplatesGETResponse.Root>> => {
  const queryParams = compactObject({
    $select: select
  });
  return axiosVendor.get(`/certificateTemplateRevisions/${revisionId}`,{ params: queryParams})
};

export const PostCertificateTemplateRevision = async (
  data: any
): Promise<any> => {
  return axiosVendor.post(`/certificateTemplateRevisions`, data);
};
