import React, { FunctionComponent, ReactNode, useContext } from "react";
import classNames from "classnames";
import { mapToCssModules } from "./utils";
import { Collapse } from "./Collapse";
import { AccordionContext } from "./AccordionContext";
import { CSSModule, InnerRef, TagPropType } from "./types";

export interface AccordionBodyPropTypes {
  /** Unique key used to control item's collapse/expand */
  accordionId: string;
  /** To add custom class */
  className?: string;
  children?: ReactNode;
  /** Change existing base class name with a new class name */
  cssModule?: CSSModule;
  /** Pass ref to the component */
  innerRef?: InnerRef;
  /** Set a custom element for this component */
  tag?: TagPropType;

  testId?: string;

  [key: string]: any;
}

export const AccordionBody: FunctionComponent<AccordionBodyPropTypes> = (
  props
) => {
  const {
    className,
    cssModule,
    tag = "div",
    innerRef,
    children,
    accordionId,
    testId = props["data-testid"] || props.id,
    ...attributes
  } = props;

  const { open } = useContext(AccordionContext);

  const classes = mapToCssModules(
    classNames(className, "accordion-body accordion-collapse"),
    cssModule
  );

  return (
    // @ts-ignore
    <Collapse
      {...attributes}
      data-testid={testId}
      className={classes}
      ref={innerRef}
      tag={tag}
      isOpen={
        Array.isArray(open) ? open.includes(accordionId) : open === accordionId
      }
    >
      {children}
    </Collapse>
  );
};
