import React, {
  createContext,
  FunctionComponent, ReactNode,
  Reducer,
  useReducer,
} from "react";

export interface LoaderState {
  isLoading: boolean;
  setLoading?: () => boolean;
  setLoaded?: () => boolean;
  toggleLoading?: () => boolean;
}

export const initLoaderState: LoaderState = {
  isLoading: false,
};

export const LoaderContext = createContext<LoaderState>(initLoaderState);

export const LoaderActions = {
  LOADING: "loading",
  LOADED: "loaded",
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type LoaderActions = typeof LoaderActions[keyof typeof LoaderActions];

export interface LoaderActionType {
  type: LoaderActions;
}

export const loaderReducer: Reducer<LoaderState, LoaderActionType> = (
  state,
  action
) => {
  switch (action.type) {
    case LoaderActions.LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case LoaderActions.LOADED:
      return {
        ...state,
        isLoading: false,
      };
  }
};

export const useLoaderState = () => {
  const [{ isLoading }, dispatch] = useReducer(loaderReducer, initLoaderState);

  const value: LoaderState = {
    isLoading,
    setLoaded() {
      dispatch({ type: LoaderActions.LOADED });
      return true;
    },
    setLoading() {
      dispatch({ type: LoaderActions.LOADING });
      return true;
    },
    toggleLoading() {
      const actions = [LoaderActions.LOADING, LoaderActions.LOADED];
      // isLoading = 1 (true), => actions[1] => Toggle to LOADED
      // isLoading = 0 (false), => actions[0] => Toggle to LOADING
      dispatch({ type: actions[Number(this.isLoading)] });
      return true;
    },
  };

  return value;
};

export const LoaderContextProvider: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  return (
    <LoaderContext.Provider value={useLoaderState()}>
      {children}
    </LoaderContext.Provider>
  );
};
