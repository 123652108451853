import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface VendorManagementState {
  pageNumber: number;
  search: string;
  pageSize: number;
  loader: boolean;
  pageOrder: string;
}

export const initialState: VendorManagementState = {
  pageNumber: 1,
  search: "",
  pageSize: 25,
  loader: false,
  pageOrder: "updated_at DESC",
};

const vendorManagementSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    setPageNumber: (state, action: PayloadAction<VendorManagementState>) => {
      state.pageNumber = action.payload.pageNumber;
    },
    searchVendor: (state, action: PayloadAction<VendorManagementState>) => {
      state.pageNumber = 1;
      state.search = action.payload.search;
    },
    setPageSize: (state, action: PayloadAction<VendorManagementState>) => {
      state.pageSize = action.payload.pageSize;
      state.pageNumber = 1;
    },
    setPageOrder: (state, action) => {
      state.pageOrder = action.payload.pageOrder;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
    clearVendorState: () => {
      return initialState;
    },
  },
});
const { actions, reducer } = vendorManagementSlice;

export const {
  setPageNumber,
  setLoader,
  setPageOrder,
  searchVendor,
  setPageSize,
  clearVendorState,
} = actions;
export default reducer;
