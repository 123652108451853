import React, { FunctionComponent, ReactNode } from "react";
import { JSONDiffStyleWrapper } from "./styled";
import { jsonDiff } from "../../common/utils/jsonDiff";
import { JSONDiffRow, JSONDiffRowSection } from "./JSONDiffRow";
import { JSONDiffProvider, useJSONDiff } from "./JSONDiffContext";
import { omitAll, isEmpty } from "lodash/fp";

export interface JSONDiffProps {
  children: ReactNode;
  diffData:
    | {
        [key: string]: any;
      }
    | undefined;
  delta?: boolean;
  headline?: boolean;
  newData: any;
  oldData: any;
}

export const JSONDiffProviderChildren: FunctionComponent<{
  children: ReactNode;
  headline?: boolean;
}> = ({ children, headline }) => {
  const { diffData } = useJSONDiff();

  return (
    <div className="diff-container">
      {/* Old/New Headline */}
      {headline && !isEmpty(diffData) ? (
        <>
          <div className="row">
            <div className="col-2 modal-report-log-sub-labels"></div>
            <div className="col-1"></div>
            <div className="col-4 pl-0 pr-0">
              <div className="font-weight-bold">Old Value</div>
            </div>
            <div className="col-4 pl-0 pr-0">
              <div className="font-weight-bold">New Value</div>
            </div>
          </div>
          <>{children}</>
        </>
      ) : (
        <span className="no-data">No Changes</span>
      )}
    </div>
  );
};

export type JSONDiffFn = FunctionComponent<JSONDiffProps> & {
  Row: typeof JSONDiffRow;
  Section: typeof JSONDiffRowSection;
};

export const JSONDiff: JSONDiffFn = ({
  diffData,
  delta = false,
  children,
  headline = true,
  oldData,
  newData,
}) => {
  return (
    <JSONDiffProvider
      value={{
        diffData,
        delta,
        prevData: oldData,
        currData: newData
      }}
    >
      <JSONDiffStyleWrapper>
        <JSONDiffProviderChildren headline={headline}>
          {children}
        </JSONDiffProviderChildren>
      </JSONDiffStyleWrapper>
    </JSONDiffProvider>
  );
};

JSONDiff.Row = JSONDiffRow;
JSONDiff.Section = JSONDiffRowSection;

export interface JSONDiffWrapperProps {
  oldData: any;
  newData: any;
  delta?: boolean;
  children: ReactNode;
}

const omitFields = omitAll([
  "updatedAt",
  "createdAt",
  "tenantId",
  "userId",
  "userName",
  "id",
  "isActive",
  "meta",
  "reportPeriod.BYSETPOS",
  "reportPeriod.INTERVAL",
  "reportPeriod.BYDAY",
  "reportPeriod.DTSTART",
  "reportPeriod.UNTIL",
  "reportPeriod.BYMONTHDAY",
  "reportPeriod.BYMONTH",
]);

export const JSONDiffWrapper: FunctionComponent<JSONDiffWrapperProps> = ({
  oldData,
  newData,
  delta = false,
  children,
}) => {
  oldData = omitFields(oldData);
  newData = omitFields(newData);

  const diffData = jsonDiff({ oldData, newData, delta });

  return (
    <JSONDiff diffData={diffData} delta={delta} newData={newData} oldData={oldData}>
      {children}
    </JSONDiff>
  );
};
