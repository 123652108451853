import React, { useEffect } from "react";
import { SignInWrapper } from "./style";
import { useTranslation } from "react-i18next";
import SvgCeCmeLogo from "components/SvgComponent/CeCmeLogo";
import AdminFooter from "components/AdminFooter";
import _ from "lodash";

const HomePage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const tokenData = localStorage.getItem("accessToken");
    if (!_.isEmpty(tokenData)) {
      window.userManager
        .signinRedirect()
        .then(function (data: any) {/* Do nothing */})
        .catch(function (err: any) {/* Do nothing */});
    }
  }, []);

  const signin = () => {
    window.userManager
      .signinRedirect()
      .then(function (data: any) {})
      .catch(function (err: any) {});
  };
  return (
    <SignInWrapper>
      <div className="main-container">
        <div className="header-div d-flex">
          <div className="ce-main-logo-div d-flex">
            <SvgCeCmeLogo className="main-content-logo logo-main headerLogo-style" />
          </div>
        </div>
        <div className="mid-container d-flex">
          <div className="box-container d-flex">
            <div className="box d-flex">
              <div className="box-header d-flex w-100">
                <div className="box-logo">
                  <img src={process.env.PUBLIC_URL + "/images/aha-logo-heart.svg"} alt="" 
                  aria-label={t<string>("American Heart Association Logo")}/>
                </div>
                <div className="aha-title-div d-flex" aria-label={t<string>("American Heart Association")}> 
                  <p>{t<string>("American Heart Association")}</p>
                </div>
              </div>
              <div className="box-body d-flex w-100 flex-column">
                <div className="box-body-title d-flex">
                  <p>
                    {t<string>("Welcome to CE Service")}
                  </p>
                </div>
                <div className="box-body-text d-flex">
                  <p>
                  {t<string>("Continuing education (CE) services application is a stand-alone system that interfaces with multiple vendor systems (Internal and external LMS), manages accreditation credit claim program details, performs a real time validation on individual learner activity received from each system and generate reports for AHA.")}
                  </p>
                </div>

              </div>
              <button
                type="button"
                onClick={signin}
                className="btn btn-round btn-primary d-flex"
              >
                {/* mt-lg-n3 px-lg-5 px-md-5 px-sm-0 mx-auto mx-lg-0 d-block mb-md-9 */}
                {t<string>("signIn")} &nbsp;|&nbsp; {t<string>("signUp")}
              </button>
            </div>
          </div>
          <div className="backdrop-div">
            <img src={process.env.PUBLIC_URL + "/images/iStock-941446382_super.jpg"} alt="" />
          </div>
        </div>
        <AdminFooter />
      </div>
    </SignInWrapper>
  );
};

export default HomePage;
