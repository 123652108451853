import styled from "styled-components";

export const DashboardWrapper = styled.div`
  body {
    overflow-x: hidden;
  }
  .cp-h2 {
    font-size: 30px;
    margin: 40px 0px 10px 15px;
  }
  .aha-heart-logo.aha-contentone {
    background: url(../images/aha-logo.svg) no-repeat;
    background-size: 200px 109px;
    background-position: -10px 0px;
    width: 200px;
    height: 109px;
  }
  .l-padding-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .cp-bdr-resp {
    border-bottom: 1px solid #e3e3e3;
  }
  .dropdown-item.dropdown-noclr {
    color: #222328 !important;
  }
  .l-padding-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .cp-spl-lione {
    display: none;
  }
  a.li-active:before {
    visibility: visible !important;
    -webkit-transform: translateY(0px) !important;
    transform: translateY(0px) !important;
    opacity: 1 !important;
  }
  @media only screen and (max-width: 991.5px) {
    .aui-main-header .aui-header-b > * {
      width: 100%;
    }
    .cp-spl-lione {
      display: block;
    }
    .cp-bdr-resp {
      border-bottom-style: none;
    }
    .aui-main-header .dropdown-toggle:after {
      right: 5px;
      top: 45%;
      position: absolute;
    }
  }
  @media only screen and (max-width: 767.5px) {
    h1.cp-h2 {
      font-size: 24px;
      margin: 36px 0px 0px 15px;
    }
  }
  /* .dashboard-wrapper{padding:0px 0px 60vh 0px;} */
  .products {
    height: 120px;
    width: 337px;
    margin: 30px 0px;
    @media only screen and (max-device-width: 991px) {
      width: 100%;
    }
    .icons {
      width: 109px;
      height: 120px;
      float: left;
      margin: -2px;
    }
    ul,
    .icon-contents {
      float: left;
      list-style-type: none;
      float: left;
      color: #222328;
      margin: 21px 0px 0px 24px;
      padding: 0px;
      @media only screen and (min-width: 992px) and (max-width: 1100px) {
        margin-left: 10px;
      }
      @media only screen and (max-width: 360px) {
        margin-left: 10px;
      }
      * {
        font-family: "Montserrat", sans-serif;
      }
    }
    .icon-contents h2 {
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
    }
    ,
    .content-exp {
      font-weight: 700;
      font-size: 29px;
      margin-top: 17px;
      line-height: 30px;
    }
  }
  .products-one {
    border: 2px solid #c10e21;
    .icons {
      background: #c10e21;
    }
  }
  .products-two {
    border: 2px solid #f03f37;
    .icons {
      background: #f03f37;
    }
  }
  .products-three {
    border: 2px solid #ff9131;
    .icons {
      background: #ff9131;
    }
  }
  .products-four {
    border: 2px solid #39a24d;
    .icons {
      background: #39a24d;
    }
  }
  .products-five {
    border: 2px solid #525163;
    .icons {
      background: #525163;
    }
  }
  .products-six {
    border: 2px solid #001e71;
    .icons {
      background: #001e71;
    }
  }
`;
export const AnotherWraper = styled.div``;
