import { isEmpty } from "./isEmpty";

export const compactObject = (
  obj: {
    [key: string]: any;
  },
  fn = isEmpty
): { [key: string]: any } => {
  return Reflect.ownKeys(obj).reduce((accObj, curr) => {
    if (typeof curr !== "string" || fn(obj[curr])) return accObj;

    accObj[curr] = obj[curr];
    return accObj;
  }, {} as any);
};
