import { axiosReporting } from "./ApiServices";
import { axiosVendor } from "./ApiServices";
import { compactObject } from "../../common/utils/compactObject";
import * as SchedulerResponse from "common/types/SchedulerResponse";
import * as SchedulerGETResponse from "common/types/SchedulerGETResponse";
import { AxiosResponse } from "axios";
import { ExportCustomReportApiReqBody } from "pages/Report/CustomReport/CustomReportConfig";
import { da } from "date-fns/locale";
export const ListActivityByFilter = async (activities: any): Promise<any> => {
  const queryParams = compactObject({
    pageSize: activities.pageSize,
    pageOrder: activities.pageOrder,
    pageNumber: activities.pageNumber,
    vendor: activities.vendor,
    startDate: activities.startDate,
    endDate: activities.endDate,
    accreditation: activities.accreditation,
    program: activities.program,
    programType: activities.programType,
    reportType: activities.reportType,
    mocType: activities.mocType,
  });
  return axiosReporting.get(`/activities`, {
    params: queryParams,
  });
};

export const ListCreditSummaryByFilter = async (
  creditSummary: any
): Promise<any> => {
  const queryParams = compactObject({
    pageSize: creditSummary.pageSize,
    pageOrder: creditSummary.pageOrder,
    pageNumber: creditSummary.pageNumber,
    vendor: creditSummary.vendor,
    startDate: creditSummary.startDate,
    endDate: creditSummary.endDate,
    accreditation: creditSummary.accreditation,
    program: creditSummary.program,
  });
  return axiosReporting.get(`/creditSummaryReports`, {
    params: queryParams,
  });
};

export const ExportReport = async (filter: any): Promise<any> => {
  return axiosReporting.post(`/exports`, {
    vendor: filter.vendor,
    accreditation: filter.accreditation,
    program: filter.program,
    programType: filter.programType,
    startDate: filter.startDate,
    endDate: filter.endDate,
    reportType: filter.reportType,
    exportType: filter.exportType,
    notifyMe: filter.notifyMe,
    mocType: filter.mocType,
  });
};

export const GetExportStatus = async (id: string): Promise<any> => {
  return axiosReporting.get(`/exportStatus/${id}`);
};

export const DownloadReport = async (id: string): Promise<any> => {
  return axiosReporting.get(`/downloads/${id}`);
};

export const ListVendorByFilter = async (vendors: any): Promise<any> => {
  const queryParams = compactObject({
    pageSize: vendors.pageSize,
    pageOrder: vendors.pageOrder,
    pageNumber: vendors.pageNumber,
    active: vendors.active,
  });
  return axiosVendor.get(`/vendors`, {
    params: queryParams,
  });
};

export const ListAccreditationByFilter = async (
  accreditations: any
): Promise<any> => {
  const queryParams = compactObject({
    pageSize: accreditations.pageSize,
    pageOrder: accreditations.pageOrder,
    pageNumber: accreditations.pageNumber,
    isActive: accreditations.isActive,
  });
  return axiosVendor.get(`/accreditations`, {
    params: queryParams,
  });
};

export const ListClassificationByFilter = async (
  classifications: any
): Promise<any> => {
  const queryParams = compactObject({
    pageSize: classifications.pageSize,
    pageOrder: classifications.pageOrder,
    pageNumber: classifications.pageNumber,
    isActive: classifications.isActive,
  });
  return axiosVendor.get(`/categories`, {
    params: queryParams,
  });
};

export const ListProgramByFilter = async (programs: any): Promise<any> => {
  const queryParams = compactObject({
    pageSize: programs.pageSize,
    pageOrder: programs.pageOrder,
    pageNumber: programs.pageNumber,
    vendor: programs.vendor,
    accreditation: programs.accreditation,
    classification: programs.classification,
    certificate: programs.certificate,
    reportType: programs.reportType,
    startDate: programs.startDate,
    endDate: programs.endDate,
    search: programs.searchProgram,
    program: programs.program,
  });
  return axiosReporting.get(`/programs`, {
    params: queryParams,
  });
};

export const ListSchedulerByFilter = async (filterQuery: {
  name?: string;
  search?: string;
  active?: string;
  pageSize?: number;
  pageOrder?: number;
  pageNumber?: number;
  [key: string]: any;
}): Promise<AxiosResponse<SchedulerResponse.RootObject>> => {
  return axiosReporting.get(`/schedulers`, {
    params: compactObject(filterQuery),
  });
};

export const PatchSchedulerById = async (
  id: number,
  formData: any
): Promise<AxiosResponse<SchedulerResponse.RootObject>> => {
  return axiosReporting.patch(`/schedulers/${id}`, formData);
};

export const AddSchedulerApi = async (data: any): Promise<any> => {
  return axiosReporting.post(`/schedulers`, data);
};

export const AddCustomReportSchedulerApi = async (data: any): Promise<any> => {
  return axiosReporting.post(`/customSchedulers`, data);
};

export const GetCustomReportSchedulerApi = async (
  id: string
): Promise<AxiosResponse<SchedulerGETResponse.Root>> => {
  return axiosReporting.get(`/customSchedulers/${id}`);
};

export const PutSchedulerById = async (id: number, data: any): Promise<any> => {
  return axiosReporting.put(`/schedulers/${id}`, data);
};

export const GetSchedulerApi = async (
  id: string
): Promise<AxiosResponse<SchedulerGETResponse.Root>> => {
  return axiosReporting.get(`/schedulers/${id}`);
};

export const ListReportLogByFilter = async (reportLog: any): Promise<any> => {
  const queryParams = compactObject({
    pageSize: reportLog.pageSize,
    pageOrder: reportLog.pageOrder,
    pageNumber: reportLog.pageNumber,
    reportLogType: reportLog.reportLogType,
    myReport: reportLog.myReport,
    startDate: reportLog.startDate,
    endDate: reportLog.endDate,
    search: reportLog.search,
    status: reportLog.status
  });
  return axiosReporting.get(`/reportLogs`, {
    params: queryParams,
  });
};

export const ListCustomReportColumns = async (): Promise<any> => {
  return axiosReporting.get(`/customReportColumns`);
};

export const ListCustomReportByFilters = async (
  requestBody: any,
  paginationData: any
): Promise<any> => {
  const queryParams = compactObject({
    pageNumber: paginationData.pageNumber,
    pageOffset: paginationData.pageOffset,
    pageSize: paginationData.pageSize,
  });
  return axiosReporting.post("/customReports", requestBody, {
    params: queryParams,
  });
};

export const SaveCustomReportByFilters = async (
  requestBody: any
): Promise<any> => {
  return axiosReporting.post("/customReportFilter", requestBody);
};

export const ListCustomReportSavedFilters = async (
  searchValue: string
): Promise<any> => {
  return axiosReporting.get("/customReportFilters", {
    params: {
      search: searchValue,
      pageOrder: "updated_at DESC",
      pageSize : 500
    },
  });
};

export const GetCustomReportFilterById = async (id: string): Promise<any> => {
  return axiosReporting.get(`/customReportFilter/${id}`);
};

export const UpdateCustomReportFilterById = async (
  id: string,
  reqBody: any
): Promise<any> => {
  return axiosReporting.put(`/customReportFilter/${id}`, reqBody);
};

export const ExportCustomReport = async (
  reqBody: ExportCustomReportApiReqBody
): Promise<any> => {
  return axiosReporting.post("/exportCustomReports", reqBody);
};

export const DownloadCustomReport = async (id: string): Promise<any> => {
  return axiosReporting.get(`/customReportDownloads/${id}`);
};

export const DeleteCustomReportFilter = async (reqBody: any): Promise<any> => {
  return axiosReporting.patch(`/deleteCustomReports`, reqBody);
};

export const GetProgramListByFilter = async (programs: any): Promise<any> => {
  const queryParams = compactObject({
    pageSize: programs.pageSize,
    pageOrder: programs.pageOrder,
    pageNumber: programs.pageNumber,
    accreditation: programs.accreditation,
    classification: programs.classification,
    certificate: programs.certificate,
    reportType: programs.reportType,
    startDate: programs.startDate,
    endDate: programs.endDate,
    search: programs.searchProgram,
    program: programs.program,
  });
  return axiosReporting.get(`/programList`, {
    params: queryParams,
  });
};

export const ListCustomReportSchedulerByFilter = async (filterQuery: {
  name?: string;
  search?: string;
  active?: string;
  pageSize?: number;
  pageOrder?: number;
  pageNumber?: number;
  [key: string]: any;
}): Promise<AxiosResponse<SchedulerResponse.RootObject>> => {
  return axiosReporting.get(`/customSchedulers`, {
    params: compactObject(filterQuery),
  });
};

export const PatchCustomSchedulerById = async (
  id: number,
  formData: any
): Promise<AxiosResponse<SchedulerResponse.RootObject>> => {
  return axiosReporting.patch(`/customSchedulers/${id}`, formData);
};

export const GetCustomSchedulerApi = async (
  id: string
): Promise<AxiosResponse<SchedulerGETResponse.Root>> => {
  return axiosReporting.get(`/customSchedulers/${id}`);
};

export const PutCustomSchedulerById = async (id: number, data: any): Promise<any> => {
  return axiosReporting.put(`/customSchedulers/${id}`, data);
};

