import styled from "styled-components";

const NoRecordFoundWrapper = styled.div`
  .noRecordFound {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
  }
`;
export default NoRecordFoundWrapper;
