import styled from "styled-components";

export const CustomisedTableWrapper = styled.div`
.custom-switch{
  .custom-control-label{
    padding-left: 0;
  }
}
.cpaction-icons {
  list-style: none;
  .position-relative{
      list-style: none;
      + .position-relative{
        margin-left: 10px;
      }
  }
}
.expired-schedule-list-label img{
  width: 16px;
  height: 16px;
}
.td-width{
  width:35%
}
.dot-style {
  position: absolute;
  bottom: -33px;
  font-size: 26px;
  letter-spacing: 3px;
  font-weight: bold;
  color: #c10e21;
  cursor:pointer;
}

span[class*="position-absolute"] {
  word-break: keep-all;
}

.non-desktop-dot-style {
  position: absolute;
  bottom: -25px;
  font-size: 26px;
  letter-spacing: 3px;
  font-weight: bold;
  color: #c10e21;
  cursor:pointer;
}

.sftp-dot-style{
  font-size: 26px;
  letter-spacing: 3px;
  font-weight: bold;
  color: #c10e21;
  cursor:pointer;
  position: absolute;
  margin-top:-2rem;
}
.lable-padding-right{padding-right: 10px;}
.ascends-arrow:focus,.ascends-ul div:focus{outline: 2px solid #F7983A !important;-webkit-box-shadow: 0 0 4px 4px #F7983A !important;box-shadow: 0 0 4px 4px #F7983A !important; }
.aui-responsive-status-table{
  td{
    li{
      span{color:#222328;top: -32px;}
      width:min-content !important;height:20px;cursor:pointer;
    }   
    li:nth-child(1){
    span{width: min-content !important; text-align: center;left: -5px;border: 1px solid #dee2e6;background: #ffffff;font-weight: 500;-webkit-box-shadow: 0rem 0rem 3px #00000029;
      box-shadow: 0rem 0rem 3px #00000029;display:none;}
    }
    li:nth-child(2){
      span{width: min-content !important; text-align: center;left:-28px;border: 1px solid #dee2e6;background: #ffffff;font-weight: 500;-webkit-box-shadow: 0rem 0rem 3px #00000029;
        box-shadow: 0rem 0rem 3px #00000029;display:none;}
    }
    li:nth-child(3){margin-right:0px;width:min-content !important;
      span{width: min-content !important;text-align: center;border: 1px solid #dee2e6;background: #ffffff;font-weight: 500;-webkit-box-shadow: 0rem 0rem 3px #00000029;
        box-shadow: 0rem 0rem 3px #00000029;left:-35px;display:none;}
    } }
}
.unselected-cls{background-color: #fcfcfc !important;}  
th.code-cls{width:10% !important;}
.justify-content-center
th.type-cls{width:11% !important;}
th.sequence-cls{width:13% !important;}
th.filesize-cls{width:14% !important;}
th.filesize-cls{width:10% !important;}
th.createdate-cls{width:13% !important;}
th.updatedate-cls{width:13% !important;}
th.lastreview-cls{width:14% !important;}
th{padding:21px 15px;
  .invisible-cls{opacity: 0;z-index: -5;left: -9999px;}  
  div.ascends-ul{width:116px;-webkit-box-shadow: 0rem 0rem 3px #00000029;box-shadow: 0rem 0rem 3px #00000029;
    font-size: 14px;font-weight: normal;list-style-type: none;background: #ffffff;left: -98px;top: 24px;display:none;
  div{padding: 13px 9px 9px 9px;}
  div:nth-of-type(1){border-bottom: 1px solid #E3E3E3;}}
  .ascends-arrow{width:15px;cursor:pointer;left:5px;    
      &:after{display: inline-block;margin:0px 4px;vertical-align: 0.1875rem;content: "";border-top:2px solid #222328;
    border-right:2px solid #222328;border-bottom: 0;border-left: 0;width: 0.575rem;height: 0.575rem;-webkit-transform: rotate(135deg);
    transform: rotate(135deg);}}      
  }
th.action-cls{width:140px !important;} 
}
@media only screen and (max-width: 991.5px){
  .aui-responsive-status-table tbody tr td:before{width:50%;display:inline-block;}
  .sw-50{float:right;width:50%;}
    .cpaction-icons{justify-content:left !important;}
    .aui-responsive-status-table tbody tr td:before{width:50%;display:inline-block;}
    .sw-50{float:right;width:50%;}
    .aui-responsive-status-table tbody tr td:before {font-weight: 500;font-size: 14px;}
    .cpaction-icons{width:50%;padding:0 !important;} 
}

@media only screen and (max-width: 575px){
  .col-xs-6{
    width:50%;
  }
}

img {
  height: -webkit-fill-available;
}

@media only screen and (min-width: 992px){
  .change-description {
    display: flex;
    flex-direction:column !important;

    .row {
      width: 100%;
    }
  }
}
@media only screen and (max-width: 576px){
  .aui-responsive-status-table td {
    display: flex;
  }
  .aui-responsive-status-table tbody tr td:first-child .aui-td {
    border-top: 1px solid #e3e3e3;
  }

  .aui-responsive-status-table tbody tr td:before{
    border-right: none;
    width : 70%;
  }

  .aui-responsive-status-table tbody tr .aui-td{
    border-left: none;
  }
}
.aui-responsive-status-table{table-layout: auto;}
@-moz-document url-prefix() { 
 .aui-responsive-status-table tbody tr td {
 height: 100%;
 }
 }
 
.text-only-icon-item {
  color: #C10E21 !important;
  width: auto !important;
  
  a:hover {
    text-decoration: none;
  }
}
.conten-space{
  margin-right: 15rem;
}

select {

  -webkit-appearance: none;

  -moz-appearance: none;

  -webkit-appearance: none;

  -moz-appearance: none;

  appearance: none;

  background: url(../.../images/select-arrow.png) white no-repeat 98.5% !important;

  background: url(../../images/select-arrow.png) white no-repeat calc(100% - 10px) !important;
  background-size: 1rem !important;
  text-align: left;

  justify-content: left;

  padding-left: 4px;

  margin: 1px;

  border: 2px solid #e3e3e3;

}
.div-line-hight{
  line-height: 2;
}

.nodatafound{
  padding-top: 15%;
  text-align: center;
  color: #343A40;
  background-image: url("images/no-recs.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 9.375rem 9.375rem;
  height: 200px;
  font-size:16px;

  @media only screen and (max-width: 991.5px){
    border: 0px !important;
    padding-left: 39%;
    padding-top: 10%;
  }

  @media only screen and (max-width: 576px){
    border: 0px !important;
    padding-left: 34%;
    padding-top: 17%;
  }

  @media only screen and (max-width: 426px){
    border: 0px !important;
    padding-left: 28%;
    padding-top: 25%;
  }
}

@media only screen and (max-width: 991.5px){
  .aui-responsive-status-table tbody tr td.nodatafound::before {
    content: normal !important;
  }
}

.show-desktop {
  @media only screen and (max-width: 991px){
     display:none !important;
  }

  @media only screen and (min-width: 992px){
    display:inline !important;
 }

}

.show-mobile {
  @media only screen and (max-width: 991px){
    display:flex !important;
 }

 @media only screen and (min-width: 992px){
  display:none !important;
  }
}

.show-mobile.pad12 {
 ::before {
   padding-bottom:12px;
 }
}

@media only screen and (min-width: 992px){
  .action_column {
    width: auto;
  }
  .aui-responsive-status-table .notd {
		width:10px !important;
	}
  
  div.container {
    max-width: 98% !important;
    min-width: 70% !important;
  }

  .aui-responsive-status-table{
    td{
      li{
        span{
          width:min-content !important;
        }
      } 
      li:hover{
        span{
          width:max-content !important;
        }
      } 
    }
  } 
  #pagelistitems {
    width: max-content;
  }

  .aui-responsive-status-table td li:hover span {
    display: block !important;
  }
}

.scroll-bar-div {
  overflow: auto;
  margin-top: -1.5rem;
  width: 100%;
  .top-scroll {
    width: calc(350% - 50px);
  }
  @media screen and (max-width: 991px) {
    overflow: hidden;
  }
}

.activity-log-learner-td {
  align-items: flex-start !important;
  .name-div-block {
    margin-bottom: 5px;
    margin-left: 3px;
  }
  .email-div-block {
    margin-left: 3px;
  }
}

.activity-desc-col {
  margin-left: -0.3rem;
  word-break: keep-all;
}

.activity-log-modal {
  font: normal normal normal 14px/18px Montserrat;
}

@media only screen and (max-width: 575px){
  .activity-log-modal {
  font: normal normal normal 12px/16px Montserrat;
}
}


.match-width-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}
.label-keys {
  padding: 0.75rem;
  width: 25%;
}

@media only screen and (max-width: 991px){
  .activity-desc-col {
    padding-left: 0px !important;
  }
}

@media only screen and (min-width: 992px){
.custom-report-row{
   white-space: nowrap;
  }
}

.custom-report-header{
  width: max-content;
}

@media only screen and (max-width: 992px){
  .custom-report-row{
    white-space: initial;
  }
}

`;
export default CustomisedTableWrapper;
