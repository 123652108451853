import React, { FunctionComponent, ReactNode } from "react";
import { Wrapper } from "styled";
import Loader from "components/Loader";
import Toast from "components/Toast";
import { RouterProps } from "react-router-dom";

type DefaultLayoutProps = RouterProps & { children: ReactNode };

export const DefaultLayout: FunctionComponent<DefaultLayoutProps> = ({
  children,
}) => {
  return (
    <Wrapper className="d-flex flex-column">
      {/* <Header /> */}
      {/* <ScrollToTop /> */}
      <Toast />
      <main className="d-flex flex-column flex-grow-1" id="maincontent">
        {children}
      </main>
      <Loader />
      {/* <Footer /> */}
    </Wrapper>
  );
};
