import { Token } from './TokenResponse'
import { SSOLoginResponseOld } from "./SSOLoginResponseOld";

export interface SSOLoginResponse {
  status: number
  data: Data
  requestId: string
}

export interface Data {
  user: Token
}

export function isNewSSOLoginResponse(response: SSOLoginResponse | SSOLoginResponseOld): response is SSOLoginResponse {
  return (response as SSOLoginResponse).data?.user?.accessToken !== undefined
}