const LocalStore = {
  set: async (key: string, data: any) => {
    return localStorage.setItem(key, data);
  },
  get: async (key: string) => {
    return localStorage.getItem(key);

    // will use in future
    // const currentState = JSON.parse(
    //   localStorage.getItem(CONSTANTS.LOCAL_STORE_KEY || "{}") || "{}"
    // );
    // const response = {
    //   success: false,
    //   data: {},
    // };
    // const stateHasProperty = Object.prototype.hasOwnProperty.call(
    //   currentState,
    //   key
    // );
    // if (stateHasProperty) {
    //   response.success = true;
    //   response.data = currentState[key];
    //   return response;
    // }
    // return response;
  },
};

export default LocalStore;
