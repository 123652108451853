export const ParentTags = {
  MISMATCH: "Mismatch",
  REJECTED: "Rejected",
} as const;

export type ParentTags = typeof ParentTags[keyof typeof ParentTags];

export const MismatchErrorTags = [
  // { name: "Learner Identifier" },
  { name: "Address", code: "address" },
  { name: "Email", code: "email" },
  { name: "Name", code: "name" },
] as const;

export const RejectedErrorTags = [
  { name: "Classification issue", code: "classificationErrors" },
  { name: "Certificate issue", code: "certificateErrors" },
  { name: "Credit issue", code: "creditErrors" },
  { name: "Date errors", code: "dateErrors" },
  { name: "Character length mismatch", code: "character" },
  { name: "Field empty", code: "emptyFieldErrors" },
  { name: "Others", code: "otherErrors" },
] as const;
