import React from "react";

const SvgCeCmeLogo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="223.582" height="67.571" viewBox="0 0 223.582 67.571" {...props}>
      <defs>
        <linearGradient  x1="-0.004" y1="0.501" x2="1.016" y2="0.501" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#7b1416" />
          <stop offset="1" stopColor="#c10e21" />
        </linearGradient>
      </defs>
      <g  data-name="Group 5716" transform="translate(-24.876 -14.378)">
        <path  data-name="Path 3102" d="M0,0V45.037" transform="translate(88.962 29.453)" fill="none" stroke="#222328" strokeWidth="1" />
        <text  data-name="CE SERVICE" transform="translate(102.458 60.949)" fill="#222328" fontSize="24" fontFamily="Montserrat-Medium, Montserrat" fontWeight="500"><tspan x="0" y="0">CE SERVICE</tspan></text>
        <g  transform="translate(-16.35 -100.922)">
          <g data-name="Group 4758" transform="translate(41.225 115.3)">
            <path  data-name="Path 1698" d="M110.462,239.47c-1.281-16.106-.329-25.4-.329-25.4-.146-1.025-2.526-1.537-5.308-1.171s-4.942,1.5-4.832,2.489h0a193.517,193.517,0,0,1,6.552,27.16C107.936,241.483,109.254,240.459,110.462,239.47Z" transform="translate(-78.479 -177.097)" fill="#fff" />
            <g  data-name="Group 4757">
              <g  data-name="Group 4756" transform="translate(18.851)">
                <g  data-name="Group 4755">
                  <path  data-name="Path 1724" d="M105.637,130.161a7.2,7.2,0,0,0-3.075,7.65c2.928-4.319,11.86-.439,14.825-10.359a8.984,8.984,0,0,1-5.857,1.9c4.868-2.013,7.65-9.151,3.294-14.056,1.281,5.747-6.04,6.259-11.823,7.357-10.8,2.05-12.592,12.043-7.687,17.167-.549-6.113,4.283-9.737,10.322-9.663Z" transform="translate(-92.724 -115.3)" fill="#c10e21" />
                </g>
              </g>
              <path data-name="Path 1725" d="M93.189,193.022c-5.82.769-10.322,3.4-10.1,4.942l1.72,4.612h.11a.862.862,0,0,1-.073-.293c-.256-1.9,3.77-3.99,8.968-4.649,5.2-.7,9.627.293,9.883,2.2v.293h.037l.512-4.9C104.024,193.645,99.009,192.254,93.189,193.022Z" transform="translate(-67.759 -164.435)" fill="#fff" />
              <path data-name="Path 1726" d="M84.152,179.064a13.337,13.337,0,0,0-16.362,4.283c4.978-.439,9.151.7,9.81,2.013h0a.678.678,0,0,1,.073.256l-.512,4.9h-.037v-.293c-.256-1.9-4.685-2.855-9.883-2.2-5.2.7-9.224,2.782-8.968,4.649a.861.861,0,0,0,.073.293h-.11l-1.72-4.612c-.183-1.428,3.7-3.807,8.931-4.759A13.286,13.286,0,0,0,48.9,178.991c-7.1,3.294-10.469,11.677-4.868,21.6,3.99,7.028,10.213,12.372,22.511,21.889.952-.732,1.9-1.464,2.782-2.16a181.8,181.8,0,0,0-6.552-27.16h0c-.146-1.025,2.013-2.123,4.832-2.489,2.782-.366,5.161.146,5.308,1.171,0,0-.073.7-.146,1.977h0a169.8,169.8,0,0,0,.476,23.427c8.016-6.442,12.628-10.981,15.813-16.618a22.363,22.363,0,0,0,1.135-2.306h0C94.036,189.46,90.668,182.1,84.152,179.064Z" transform="translate(-41.225 -154.906)" fill="#c10e21" />
              <path data-name="Path 1727" d="M131.876,198.5a.678.678,0,0,1,.073.256l-.512,4.9H131.4c-.11,1.171-1.83,2.416-4.356,3.294a169.8,169.8,0,0,0,.476,23.426h0c8.016-6.442,12.628-10.981,15.813-16.618a22.349,22.349,0,0,0,1.135-2.306Z" transform="translate(-95.501 -168.045)" fill="url(#linear-gradient)" />
            </g>
          </g>
        </g>
      </g>
    </svg>

  );
};

export default SvgCeCmeLogo;
