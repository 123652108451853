/* eslint-disable no-debugger, no-console */
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { useSelector } from "react-redux";
import { ssoSignUp } from "services/api/adminLogin.api";
import { SSOLoginWrapper } from "./styled";
import { useHistory } from "react-router-dom";
import { LocalStorageKeys } from "common/constants/localStorage";
import { useRBACContext } from "@impelsysinc/react-rbac";
import LocalStore from "common/LocalStore";
import { Routes } from "common/constants";
import { Roles } from "common/constants/roles";
import { isNewSSOLoginResponse } from "common/types/SSOLoginResponse";

export interface Token {
  type: string;
  idToken: string;
}

const Landing: FunctionComponent = () => {
  const loginCountRef = useRef<number>(1);
  const history = useHistory();
  const userState = useSelector((state: any) => {
    return state.userManagement;
  });
  const { setPermissions } = useRBACContext();

  const signIn = useCallback(
    async (tokens: Token) => {
      try {
        const tokenResponse = await ssoSignUp(tokens);

        const result = tokenResponse.data;

        if (isNewSSOLoginResponse(result)) {
          const data = result.data;
          await LocalStore.set(
            LocalStorageKeys.ACCESS_TOKEN,
            data.user?.accessToken
          );
          await LocalStore.set(
            LocalStorageKeys.REFRESH_TOKEN,
            data.user?.refreshToken
          );
        } else {
          // TODO: Remove this after next release
          const data = result.data;
          await LocalStore.set(
            LocalStorageKeys.ACCESS_TOKEN,
            data.user?.AccessToken
          );
          await LocalStore.set(
            LocalStorageKeys.REFRESH_TOKEN,
            data.user?.RefreshToken
          );
        }

        if (isNewSSOLoginResponse(result)) {
          const data = result.data;

          if (data?.user?.permissions?.permissions) {
            const permissions = data?.user?.permissions?.permissions;
            setPermissions(permissions);
            await LocalStore.set(LocalStorageKeys.ROLE, data?.user?.role);
            await LocalStore.set(
              LocalStorageKeys.PERMISSIONS,
              JSON.stringify(permissions)
            );
          }
        } else {
          // TODO: Remove this after next release
          const data = result.data;
          if (data?.user?.Permissions?.permissions) {
            const permissions = data?.user?.Permissions?.permissions;
            setPermissions(permissions);
            await LocalStore.set(LocalStorageKeys.ROLE, data?.user?.Role);
            await LocalStore.set(
              LocalStorageKeys.PERMISSIONS,
              JSON.stringify(permissions)
            );
          }
        }

        if (isNewSSOLoginResponse(result)) {
          const data = result.data;
          if (data?.user?.role === Roles.VENDOR_ADMIN) {
            history.push(Routes.ACTIVITY_LIST_PAGE);
          } else {
            history.push(Routes.LEARNER_LIST_PAGE);
          }
        } else {
          // TODO: Remove this after next release
          const data = result.data;
          if (data?.user?.Role === Roles.VENDOR_ADMIN) {
            history.push(Routes.ACTIVITY_LIST_PAGE);
          } else {
            history.push(Routes.LEARNER_LIST_PAGE);
          }
        }
      } catch {
        history.push(Routes.INVALID_USER_PAGE);
      }
    },
    [history, setPermissions]
  );

  useEffect(() => {
    if (userState.ssoAccessToken && loginCountRef.current === 1) {
      loginCountRef.current++;
      signIn({ type: "assure", idToken: userState.ssoAccessToken });
    }
  }, [userState.ssoAccessToken, signIn]);

  return (
    <SSOLoginWrapper>
      <div className="container">
        <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center progress-welcome">
          <h1 className="h2 mb-0 mr-sm-3 mt-3 font-600  progress-welcome-title">
            Welcome to <span className="font-700">CE Service</span>
          </h1>
          <img
            src="/images/progress_logo_animation.gif"
            alt="logo"
            className="progress-img"
          />
        </div>
      </div>
    </SSOLoginWrapper>
  );
};

export default Landing;
