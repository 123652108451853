import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ManagementState {
  pageNumber: number;
  search: string;
  startDate: string;
  endDate: string;
  reportLogType: string;
  myReport: boolean;
  pageSize: number;
  loader: boolean;
  pageOrder: string;
  status: any;
}

export const initialState: ManagementState = {
  pageNumber: 1,
  search: "",
  startDate: "",
  endDate: "",
  reportLogType: "CUSTOM_MANUAL",
  myReport: true,
  pageSize: 25,
  loader: false,
  status:"",
  pageOrder: "id desc",
};

const managementSlice = createSlice({
  name: "customReportLog",
  initialState,
  reducers: {
    setPageNumber: (state, action: PayloadAction<ManagementState>) => {
      state.pageNumber = action.payload.pageNumber;
    },
    searchReportLog: (state, action: PayloadAction<ManagementState>) => {
      state.pageNumber = 1;
      state.search = action.payload.search;
    },
    searchDate: (state, action: PayloadAction<ManagementState>) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    searchReportType: (state, action: PayloadAction<ManagementState>) => {
      state.reportLogType = action.payload.reportLogType;
    },
    searchMyReport: (state, action: PayloadAction<ManagementState>) => {
      state.pageNumber = 1;
      state.myReport = action.payload.myReport;
    },
    setPageSize: (state, action: PayloadAction<ManagementState>) => {
      state.pageNumber = 1;
      state.pageSize = action.payload.pageSize;
    },
    setPageOrder: (state, action) => {
      state.pageOrder = action.payload;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
    filterByActivityUpdate: (state, action: PayloadAction<ManagementState>) => {
      state.status = action.payload;
      state.pageNumber = 1;
    },
    clearReportLogState: () => {
      return initialState;
    },
  },
});
const { actions, reducer } = managementSlice;

export const {
  setPageNumber,
  setLoader,
  setPageOrder,
  searchReportLog,
  searchDate,
  searchReportType,
  searchMyReport,
  setPageSize,
  filterByActivityUpdate,
  clearReportLogState,
} = actions;
export default reducer;
