import { omitAll, pickAll } from "lodash/fp";

let globalCssModule: any;

export function setGlobalCssModule(cssModule: any) {
  globalCssModule = cssModule;
}

export function mapToCssModules(className = "", cssModule = globalCssModule) {
  if (!cssModule) return className;
  return className
    .split(" ")
    .map((c) => cssModule[c] || c)
    .join(" ");
}

/**
 * Returns a new object with the key/value pairs from `obj` that are not in the array `omitKeys`.
 */
export function omit(obj: any, omitKeys: any[]) {
  return omitAll(omitKeys, obj);
}

// @ts-ignore
/**
 * Returns a filtered copy of an object with only the specified keys.
 */
export function pick(obj: any, keys: any[]) {
  return pickAll(keys, obj);
}

// These are all setup to match what is in the bootstrap _variables.scss
// https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss
export const TransitionTimeouts = {
  Fade: 150, // $transition-fade
  Collapse: 350, // $transition-collapse
  Modal: 300, // $modal-transition
  Carousel: 600, // $carousel-transition
  Offcanvas: 300, // $offcanvas-transition
};

// Duplicated Transition.propType keys to ensure that Reactstrap builds
// for distribution properly exclude these keys for nested child HTML attributes
// since `react-transition-group` removes propTypes in production builds.
export const TransitionPropTypeKeys = [
  "in",
  "mountOnEnter",
  "unmountOnExit",
  "appear",
  "enter",
  "exit",
  "timeout",
  "onEnter",
  "onEntering",
  "onEntered",
  "onExit",
  "onExiting",
  "onExited",
];

export const TransitionStatuses = {
  ENTERING: "entering",
  ENTERED: "entered",
  EXITING: "exiting",
  EXITED: "exited",
};
