import { format } from "date-fns";
import {
  programSubTypeMapping,
  programTypeMapping,
} from "./mappings/programTypeMapping";
import { getCommaSeparatedValues } from "./getCommaSeperatedValues";
import AUDIT_LOG_CONSTANTS from "common/constants/auditLogs";
import { convertISOtoDateTime, convertStringToDateTime } from "./dateBasedOnLocale";

function isValid(item: any) {
  return !(item === "N/A" || item === "");
}

let emptyAccr = {
  meta: {
    max: null,
    min: null,
    moc: "",
    frequency: null,
    cdrCpeLevel: "",
    cehCategory: "",
    providership: "",
    isPartialClaim: "",
    courseApprovalNumber: "",
    cdrPerformanceIndicators: "",
  },
  credit: "",
  category: {
    classification: [],
  },
  isPartial: null,
  certificate: "",
  organizations: "",
  primaryProvider: "",
  accreditationName: "",
  claimPeriodWindow: "",
  programReleaseDate: "",
  accreditationProgramId: "",
  accreditationExpiryDate: "",
  accreditationProgramCode: "",
};

function getDeletedIndexesList(prev: any, curr: any) {
  let res = [];
  for (let i = 0; i < prev.length; i++) {
    let prevCertificate = prev[i]?.certificate;

    let foundInCurr = false;

    for (const element of curr) {
      if (prevCertificate === element?.certificate) {
        foundInCurr = true;
      }
    }

    if (!foundInCurr) {
      res.push(i);
    }
  }
  return res;
}

let excludeKey: string[] = [];
const diff = (
  prev: any,
  curr: any,
  path: string = "",
  comp: string,
  index: number = -1
) => {
  const object = "object";
  const result: any = {};

  if (Object.is(prev, curr)) {
    return undefined;
  }

  if (!curr || typeof curr !== object) {
    return curr;
  }

  // if either of the current or previous value is null, then we need to set the other to empty data of same type
  if (!prev && typeof curr === object) {
    prev = {};
  } else if (typeof prev === object && !curr) {
    curr = {};
  } else if (!prev && typeof curr === "string") {
    prev = "";
  } else if (!curr && typeof prev === "string") {
    curr = "";
  }
  
  if (Array.isArray(prev) && Array.isArray(curr)) {
    if (prev.length > curr.length) {
      let deletedIndexesList = getDeletedIndexesList(prev, curr);
      for (const element of deletedIndexesList) {
        curr.splice(element, 0, emptyAccr);
      }
      for (let i = 0; i < prev.length; i++) {
        let prevValue = prev[i] === undefined ? emptyAccr : prev[i];
        let currValue = curr[i] === undefined ? emptyAccr : curr[i];
        const newResult = diff(prevValue, currValue, "", comp, i + 1);
        Object.assign(result, newResult);
      }
    } else {
      for (let i = 0; i < curr.length; i++) {
        let prevValue = prev[i] === undefined ? emptyAccr : prev[i];
        let currValue = curr[i] === undefined ? emptyAccr : curr[i];
        const newResult = diff(prevValue, currValue, "", comp, i + 1);
        Object.assign(result, newResult);
      }
    }
  }

  !Array.isArray(prev) &&
    !Array.isArray(curr) &&
    Object.keys(prev || {})
      .concat(Object.keys(curr || {}))
      .forEach((key: any) => {
        if (
          AUDIT_LOG_CONSTANTS.GROUPSLIST.includes(key) &&
          !excludeKey.includes(key)
        ) {
          let oldValue = getCommaSeparatedValues(prev[key]);
          let newValue = getCommaSeparatedValues(curr[key]);

          if (oldValue !== newValue) {
            if (index > 0) {
              result[key + "[" + index + "]"] = resolveSentence(
                key,
                oldValue,
                newValue,
                path,
                comp
              );
            } else {
              result[key] = resolveSentence(key, oldValue, newValue, path, comp);
            }
          }
        } else if (typeof curr[key] === object && typeof prev[key] === object) {
          const newResult = diff(
            prev[key],
            curr[key],
            path + "." + key,
            comp,
            index
          );
          Object.assign(result, newResult);
        }

        if (typeof prev[key] !== object && typeof curr[key] === object) {
          curr[key] &&
            Object.keys(curr[key]).forEach((subkey: any) => {
              if (!excludeKey.includes(subkey)) {
                let endpoint = path.split(".");
                result[endpoint[endpoint.length - 1] + "[" + key + "]"] =
                  resolveSentence(key, "", curr[key][subkey], path, comp);
              }
            });
        } else if (typeof prev[key] === object && typeof curr[key] !== object) {
          prev[key] &&
            Object.keys(prev[key]).forEach((subkey: any) => {
              if (!excludeKey.includes(subkey)) {
                let endpoint = path.split(".");
                result[endpoint[endpoint.length - 1] + "[" + key + "]"] =
                  resolveSentence(key, prev[key][subkey], "", path, comp);
              }
            });
        }

        if (comp === "auditLog") {
          prev[key] == null && (prev[key] = "N/A");
          curr[key] == null && (curr[key] = "N/A");
        }

        if (
          curr[key] !== prev[key] &&
          !Object.is(prev[key], curr[key]) &&
          typeof prev[key] !== object &&
          typeof curr[key] !== object &&
          (isValid(prev[key]) || isValid(curr[key]))
        ) {
          let properPath = path.split(".");
          let parentIndex = 1;
          while (!isNaN(Number(properPath[properPath.length - parentIndex]))) {
            parentIndex++;
          }

          if (index > 0 && !excludeKey.includes(key)) {
            result[key + "[" + index + "]"] = resolveSentence(
              key,
              prev[key],
              curr[key],
              path,
              comp
            );
          } else {
            result[key] = resolveSentence(key, prev[key], curr[key], path, comp);
          }
        }
      });

  return result;
};

function getDateTime(date: any) {
  const NA = "N/A";
  return date === NA
    ? NA
    : date != "Invalid Date"
    ?  convertStringToDateTime(date)
    : NA;
}

const resolveSentence = (key: string, from: any, to: string, path: string, comp: string) => {
  let toValue, fromValue, KeyValue;

  if (key.includes("Date") && comp === "auditLog") {
    const NA = "N/A";

    const fromDate: any =
      from != null && from != undefined && from != NA && from != ""
        ? from
        : NA;
    const toDate: any =
      to != null && from != undefined && to != NA && to != ""
        ? to
        : NA;

    if (
      key.includes("accreditationExpiryDate") ||
      key.includes("programExpiryDate") ||
      key.includes("programReleaseDate")
    ) {
      toValue = toDate;
      fromValue = fromDate;
      KeyValue = key;
    } else {
      toValue = getDateTime(toDate);
      fromValue = getDateTime(fromDate);
      KeyValue = key;
    }
  } else if (key === "programType" && comp === "auditLog") {
    toValue = programTypeMapping[to];
    fromValue = programTypeMapping[from];
    KeyValue = key;
  } else if (key === "programSubType") {
    toValue = programSubTypeMapping[to];
    fromValue = programSubTypeMapping[from];
    KeyValue = key;
  } else {
    toValue = to;
    fromValue = from;
    KeyValue = key;
  }
  if (from === false) {
    return `${path} + The "${KeyValue}" has changed from ${
      JSON.stringify(fromValue, null, 0) ?? ""
    } to ${JSON.stringify(toValue, null, 0) ?? ""}`;
  } else if (!from) {
    return `${path} The "${KeyValue}" value is ${JSON.stringify(
      toValue,
      null,
      0
    )}`;
  }
  
  return `${path} The "${KeyValue}" has changed from "${
    JSON.stringify(fromValue, null, 0) ?? ""
  }" to "${JSON.stringify(toValue, null, 0) ?? ""}"`;
};

const Diff = (
  prev: any,
  curr: any,
  excludeKeyList: Array<string>,
  comp = "",
  features?: any
) => {
  excludeKey = excludeKeyList;

  if (
    (prev["programSubType"] == "" && curr["programSubType"] == "0") ||
    (prev["programSubType"] == "0" && curr["programSubType"] == "")
  ) {
    excludeKeyList.push("programSubType");
  }
  if(!features?.moc) {
    excludeKeyList.push("moc");
  }
  if(!features?.cmePassport) {
    excludeKeyList.push("cmePassport");
  }
  if(!features?.moc && !features?.cmePassport) {
    excludeKeyList.push("cmePassport");
    excludeKeyList.push("moc");
  }
  
  const diffObject = diff(prev, curr, "", comp);
  excludeKeyList?.forEach((key) => delete diffObject[key]);
  return diffObject;
};

export default Diff;
