import styled from "styled-components";
export const EditCustomReportSchedulerWrapper = styled.div`
  body {
    overflow-x: hidden;
  }
  .aha-breadcrumb a {
    font-size: 16px;
  }
  h2 {
    font-size: 1.5rem;
  }
  .cp-addbtn:focus {
    outline: 2px solid #f7983a !important;
    -webkit-box-shadow: 0 0 4px 4px #f7983a !important;
    box-shadow: 0 0 4px 4px #f7983a !important;
  }
  .aha-breadcrumb .breadcrumb-item {
    color: #c10e21;
  }
  .aha-breadcrumb .breadcrumb-item:last-child {
    color: #707070;
  }
  .aha-breadcrumb .breadcrumb-item:last-child:after {
    content: " ";
  }
  .sub-heads-pr {
    border-bottom: 2px solid #c10e21;
    h1.cp-h2 {
      font-size: 1.7rem;
      margin: 20px 0px 20px 0px;
    }
  }
  .cp-alink {
    margin-top: 20px;
    .cp-addbtn {
      font-size: 26px;
      cursor: pointer;
    }
  }
  .li-one-ul li {
    margin: 5px 10px 5px 15px !important;
  }

  [class*="MultiValueGeneric"] {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
  }

  [class*="multiValue"] div:first-child {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
  }

  .select-field-link {
    position: absolute;
    bottom: 0;
    right: 0;
    text-decoration: underline;
    color: #c10e21;
    @media screen and (max-width: 991px) {
      top: -0.4rem;
    }
  }

  .disabled-link {
    cursor: not-allowed;
    color: rgb(118, 118, 118);
  }

  .mob_btnLayout {
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      color: #c10e21;
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
      min-width: 6vw;

      > img {
        padding-right: 5px;
        margin-top: -1px;
      }
    }
  }
  .bdr-btm {
    border-bottom: 1px solid #bcc3ca;
    margin-top: 70px;
  }
  .bdr-btm-address {
    border-bottom: 1px solid #bcc3ca;
  }
  .btn-div {
    margin: 40px 0px 185px 0px;
    button {
      width: 141px;
    }
  }
  .iti,
  .iti__flag-container,
  #iti-0__country-listbox {
    width: 100%;
  }
  .iti__selected-flag {
    width: 88px;
  }
  @media screen and (min-width: 1150px) {
    .feild-width {
      max-width: 350px;
      width: 350px;
    }
  }
  @media screen and (max-width: 767.5px) {
    .sub-heads-pr h1.cp-h2 {
      font-size: 24px;
      margin: 40px 0px 20px 0px;
    }
    .no-row-cls {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
    form.row {
      margin-right: 0px !important;
      margin-left: 0px;
      width: 100%;
      .form-group {
        padding: 0px !important;
      }
      .lead-content-wrap {
        display: block !important;
      }
      .businesslead-content {
        margin-left: 0px !important;
      }
      .techlead-content,
      .businesslead-content {
        width: 100% !important;
      }
    }
    .cp-alink {
      margin-top: 40px;
    }
  }
  @media screen and (max-width: 575.5px) {
    .sub-heads-pr {
      margin-bottom: 30px !important;
    }
    .sub-heads {
      margin-bottom: 0px !important;
      padding-left: 0px;
    }
    .no-row-cls label {
      padding-left: 0px;
    }
    .bdr-btm {
      margin-top: 40px;
      margin-left: 0px;
    }
    .btn-div {
      margin: 44px 0px 67px auto !important;
      button {
        width: 140px;
        margin-right: 0px !important;
        margin-left: 10px !important;
      }
      button:nth-of-type(1) {
        margin-left: 0px !important;
      }
    }
  }
  .allow-dropdown.intl-tel-input {
    width: 100%;
    .country-list {
      width: 100%;
    }
  }

  @media screen and (max-width: 576px) {
    .mob_btnLayout {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      > button {
        width: 95%;
        margin-left: 0px !important;
      }

      div {
        margin-bottom: 6%;
      }
    }
  }

  .back_link {
    color: #c10e21;
    cursor: pointer;
    font-weight: 500;
    font-size: 15px;

    :focus {
      outline: 0 !important;
      box-shadow: none !important;
    }
  }
  @media screen and (min-width: 768px) {
    .scheduler-header {
      margin-left: 1rem;
    }
  }

  @media screen and (max-width: 767px) {
    .scheduler-header {
      margin-left: 0px;
    }
  }
  @media screen and (max-width: 992px) {
    .bdr-btm {
      display: none;
    }

    .bdr-btm-address {
      display: none;
    }
  }

  #omitReport,
  #notifyMe {
    top: 406px;
    left: 491px;
    width: 0;
    height: 8px;
    background: #c10e21 0% 0% no-repeat padding-box;
    border: 0.5px solid #c10e21;
    opacity: 0;
  }

  input[type="checkbox"] + label.customCheck_nextSpan::before {
    content: "";
    position: absolute;
    left: -5px;
    top: 20px;
    width: 20px;
    height: 20px;
    border-radius: 1px;
    border: 1px solid #777979;

    @media screen and (max-width: 991px) {
      left: 0px;
    }
  }

  input[type="checkbox"]:checked + label.customCheck_nextSpan::before {
    border: 1px solid #777979;
  }

  input[type="checkbox"]:checked + label.customCheck_nextSpan::after {
    content: "";
    position: absolute;
    left: 1px;
    top: 19px;
    border-style: solid;
    border-color: #d41818;
    width: 8px;
    height: 16px;
    border-width: 0 2.5px 2.5px 0;
    transform: rotate(45deg);

    @media screen and (max-width: 991px) {
      left: 6px;
    }
  }

  .multi {
    [class*="-menu"] {
      input[type="checkbox"] {
        display: none;

        + label {
          margin-top: 8px;
          display: inline-block;
          margin-left: 8px;
          width: 90%;
        }
      }
    }

    input:focus {
      outline: 0 !important;
      box-shadow: none !important;
    }

    [class*="control"] {
      [class*="placeholder"] {
        color: #222328;
      }
      [class*="indicatorContainer"] {
        color: #6b6b6b;
        position: sticky;
        top: 19px;
        bottom: 18px;
        > svg {
          width: 25px;
          height: 25px;
        }
      }

      [class*="indicatorSeparator"] {
        display: none;
      }
    }
  }

  .checkboxDiv {
    @media screen and (min-width: 768px) {
      margin-top: -0.75rem;
    }
  }

  .message-text-editor {
    min-height: 10rem;
    font-family: inherit;
  }
  @media screen and (min-width: 576px) and (max-width: 991px) {
    div.form-group {
      flex: 100%;
      max-width: 100%;
      padding-right: 0 !important;
      padding-left: 15px !important;

      > label {
        flex: 100%;
        max-width: 100%;
        padding-left: 0 !important;
      }

      > div.float-right {
        flex: 100%;
        max-width: 100%;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .no-row-cls {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
    form.row {
      margin-right: 0px !important;
      margin-left: 0px;
      width: 100%;
      .form-group {
        padding: 0px !important;
      }
    }
  }

  select {
    min-height: 40px !important;
  }

  @media only screen and (min-width: 992px) {
    div.container {
      max-width: 98% !important;
      min-width: 70% !important;
    }

    form label {
      padding-right: 0.5px !important;
    }
  }

  .validation-error {
    border: 2px solid #e17509 !important;
  }

  .overlay-hidden {
    display: none;
  }

  .weekly-interval {
    display: none !important;
  }

  .custom-dropdown::before {
    background: white;
    cursor: default;
    border: none;
    margin: 0px;
  }
  .custom-dropdown {
    margin: 0px;
    background: white;
    cursor: default;
  }
  .dropdown-child {
    z-index: 2;
    border: 1px solid #777979;
    border-top: none;
    color: #495057;
    position: absolute;
    width: inherit;
    background: white;
    div {
      padding-left: 0.8rem;
      padding-top: 0.5rem;
    }
    div:hover,
    span:focus {
      background: #495057;
      color: white;
    }
    .custom-span {
      padding-bottom: 0.5rem;
    }
    .select-span {
      padding-bottom: 0.5rem;
    }
  }

  .expired-schedule-label{
    font-size: 12px;
    color: #C10E21;
    opacity: 1;
  }

  .input-field-height{
    height: 40px;
  }


  .invalid-input-text{
    background: #F7AFB638 0% 0% no-repeat padding-box;
    border: 1px solid #C10E21;
  }
`;

export const AnotherWraper = styled.div``;
