import React, { ReactNode } from "react";
import PropTypes from "prop-types";
import { SignInWrapper } from "./style";

interface Props {
  children: ReactNode;
}

export default function AuthLayout({ children }: Props) {
  return (
    <SignInWrapper>
      <div className="login">
        <div>{children}</div>
      </div>
    </SignInWrapper>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
