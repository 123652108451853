import { axiosAccount } from "./ApiServices";
import { TokenResponse } from "../../common/types/TokenResponse";
import { AxiosResponse } from "axios";
import { SSOLoginResponse } from "../../common/types/SSOLoginResponse";
import LocalStore from "../../common/LocalStore";
import { LocalStorageKeys } from "../../common/constants/localStorage";
import { SSOLoginResponseOld } from "../../common/types/SSOLoginResponseOld";

export interface Credentials {
  email: string;
  password: string;
  validate?: boolean;
  touch?: boolean;
  idToken: string;
}
export interface Token {
  type: string;
  idToken: string;
}
export const adminSignIn = async (user: Credentials): Promise<any> => {
  return axiosAccount.post("/adminLogin?permissionData=true", user);
};
export const ssoSignUp = async (token: Token): Promise<AxiosResponse<SSOLoginResponse | SSOLoginResponseOld>> => {
  return axiosAccount.post<SSOLoginResponse | SSOLoginResponseOld>("/ssoLogin", token);
};

export const refreshToken = async (refreshToken?: string): Promise<AxiosResponse<TokenResponse>> => {
  return axiosAccount.post<TokenResponse>("/tokenRefresh", {
    refresh_token: refreshToken || await LocalStore.get(LocalStorageKeys.REFRESH_TOKEN || ""),
  });
}
// export const getClientSecret = async (code :any) : Promise<any> =>{
//     return axiosAccount.get(`/clientCredentials?code=${code.join()}`);
// }
// export const getUserByFilter = async (content: any): Promise<any> => {
//       const vendorId = content.filter.vendorsId;
//     return axiosAccount.get(
//       `/users?pageSize=${content.pageSize}&pageOrder=${content.pageOrder}&q=${content.search}&pageNumber=${content.pageNumber}&vendorId=${vendorId.join(",")}`
//     );
//   };
