import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import InputTextStyle from "./Styled";

interface Props {
  elementObject: any;
  handleData: any;
}

export const RenderTextAreaField = ({ elementObject, handleData }: Props) => {
  const [inputValue, setInputValue] = useState("");
  const stripHtml = (str: any) => {
    setInputValue(str.replace(/(<([^>]+)>)/gi, ""));
  };
  useEffect(() => {
    setInputValue(elementObject.value);
  }, [elementObject.value]);
  return (
    <InputTextStyle>
      <textarea
        className="form-control"
        id={elementObject.id}
        data-testid={elementObject.id}
        name={elementObject.name}
        value={
          typeof inputValue === "string"
            ? inputValue.replace(/(<([^>]+)>)/gi, "")
            : inputValue
        }
        onChange={(e) => stripHtml(e.target.value)}
        onBlur={handleData}
        required={elementObject.required}
        placeholder={elementObject.placeholder}
      ></textarea>
    </InputTextStyle>
  );
};

RenderTextAreaField.propTypes = {
  elementObject: PropTypes.object,
  handleData: PropTypes.func,
};

RenderTextAreaField.defaultProps = {
  elementObject: { id: "", name: "", value: "", required: false },
  handleData: () => null,
};

export default RenderTextAreaField;
