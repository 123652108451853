import React from 'react';

export interface AccordionContextOptions {
  open?: string[] | string;
  toggle?: (id: string) => void;
}

/**
 * AccordionContext
 * {
 *  toggle: PropTypes.func.isRequired,
 *  open: PropTypes.string,
 * }
 */
export const AccordionContext = React.createContext<AccordionContextOptions>({});
