import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { cloneDeep, set } from "lodash";

import Start from "./Start/index";
import Repeat from "./Repeat/index";
import End from "./End/index";
import computeRRuleToString from "../utils/computeRRule/toString/computeRRule";
import computeRRuleFromString from "../utils/computeRRule/fromString/computeRRule";
import configureInitialState from "../utils/configureInitialState";
import translateLabel from "../utils/translateLabel";
import translations from "../translations";
import "../styles/index.css";

class ReactRRuleGenerator extends PureComponent {
  // compute default view based on user's config

  constructor(props) {
    super(props);
    this.state = configureInitialState(
      this.props.config,
      this.props.calendarComponent,
      this.props.id,
      this.props.addSchedulerData,
      this.props.setSchedulerData,
      this.props.formType,
      this.props.formState
    );
  }

  JsonToRuleStr(json) {
    if (json === "") return "";
    var output = `DTSTART:${json["DTSTART"]}` + "\nRRULE:";

    Object.keys(json)?.forEach((key) => {
      if (key !== "DTSTART") output += `${key}=${json[key]};`;
    });

    return output.substr(0, output.length - 1);
  }

  update() {
    if(this.props.addSchedulerData.reportPeriod){
    const value = this.JsonToRuleStr(this.props.addSchedulerData.reportPeriod);
    if (value.indexOf("undefined") === -1) {
      const data = computeRRuleFromString(this.state.data, value);
      this.setState({ data });
    }
  }
  }

  componentDidMount() {
    this.update();
  }
// for proper data painting in popover
  handleChange = ({ target }) => {
    const newData = cloneDeep(this.state.data);
    if (
      target.name !== "start.onDate.date" ||
      target.name !== "end.onDate.date"
    ) {
      //if (this.props.addSchedulerData.endDate || this.props) {
        if (this.props.formState.endDate) {
        //set(newData, "end.onDate.date", this.props.addSchedulerData.endDate);
        set(newData, "end.onDate.date", this.props.formState.endDate);
      }
      // if (this.props.addSchedulerData.startDate) {
        if (this.props.formState.startDate) {
        set(
          newData,
          "start.onDate.date",
          // this.props.addSchedulerData.startDate
          this.props.formState.startDate
        );
      }
    }

    set(newData, target.name, target.value);
    const rrule = computeRRuleToString(newData);
    this.setState({ data: newData });
    this.props.onChange(rrule);
  };

  render() {
    const {
      id,
      data: { start, repeat, end, options, error },
    } = this.state;

    return (
      <div>
        {!options.hideError && error && (
          <div className="alert alert-danger">
            {translateLabel(this.props.translations, "invalid_rrule", {
              value: error.value,
            })}
          </div>
        )}

        <div className="px-0 pt-3 border rounded">
          {!options.hideStart && (
            <div>
              <Start
                id={`${id}-start`}
                start={start}
                handleChange={this.handleChange}
                translations={this.props.translations}
                addSchedulerData={this.props.addSchedulerData}
                setSchedulerData={this.props.setSchedulerData}
                formState={this.props.formState}
              />
              <hr />
            </div>
          )}

          <div>
            <Repeat
              id={`${id}-repeat`}
              repeat={repeat}
              handleChange={this.handleChange}
              translations={this.props.translations}
              addSchedulerData={this.props.addSchedulerData}
              setSchedulerData={this.props.setSchedulerData}
              formType={this.props.formType}
            />
          </div>

          {!options.hideEnd && (
            <div>
              <hr />
              <End
                id={`${id}-end`}
                end={end}
                handleChange={this.handleChange}
                translations={this.props.translations}
                addSchedulerData={this.props.addSchedulerData}
                setSchedulerData={this.props.setSchedulerData}
                formType={this.props.formType}
                formState={this.props.formState}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

ReactRRuleGenerator.propTypes = {
  id: PropTypes.string,
  config: PropTypes.shape({
    frequency: PropTypes.arrayOf(
      PropTypes.oneOf(["Yearly", "Monthly", "Weekly", "Daily", "Hourly"])
    ),
    yearly: PropTypes.oneOf(["on", "on the"]),
    monthly: PropTypes.oneOf(["on", "on the"]),
    end: PropTypes.arrayOf(PropTypes.oneOf(["Never", "After", "On date"])),
    hideStart: PropTypes.bool,
    hideEnd: PropTypes.bool,
    hideError: PropTypes.bool,
    weekStartsOnSunday: PropTypes.bool,
  }),
  value: PropTypes.string,
  onChange: PropTypes.func,
  calendarComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  translations: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};
ReactRRuleGenerator.defaultProps = {
  id: null,
  value: "",
  config: {},
  onChange() {},
  calendarComponent: null,
  translations: translations.english,
};

export default ReactRRuleGenerator;
