import { Routes } from "./routes"

export const CONSTANTS = {
    Categories: "Categories",
    Frequency: "Frequency",
    Vendors : "Vendors",
    Programs: "Programs",
    Accreditations: "Accreditations",
    Classifications: "Classifications",
    Certificates: "Certificates",
    SuccessActivity: "SuccessActivity",
    errorTypes: "Error Types",
    mismatchErrors : "Mismatch Errors",
    rejectedErrors : "Rejected Errors",
    rejected : "Rejected",
    mismatch : "Mismatch",
    ErrorDetails : "Error Details",
    errorFields : "errorFields",
    errorType : "errorType",
    learnerDetails : "learnerDetails"
}

export const  customReportFields = ["accreditations","certificates","classifications","programs","vendors"]

// These are the valid combinations that can enable category fields selection in select report fields page
export const CATEGORY_COMBINATIONS = [
  [],
  [CONSTANTS.Vendors],
  [CONSTANTS.Programs],
  [CONSTANTS.Programs, CONSTANTS.Accreditations],
  [CONSTANTS.Programs, CONSTANTS.Classifications],
  [CONSTANTS.Programs, CONSTANTS.Certificates],
  [CONSTANTS.Programs, CONSTANTS.Accreditations, CONSTANTS.Classifications],
  [CONSTANTS.Programs, CONSTANTS.Accreditations, CONSTANTS.Certificates],
  [CONSTANTS.Programs, CONSTANTS.Classifications, CONSTANTS.Certificates],
  [
    CONSTANTS.Programs,
    CONSTANTS.Accreditations,
    CONSTANTS.Classifications,
    CONSTANTS.Certificates,
  ],
  [CONSTANTS.Accreditations],
  [CONSTANTS.Accreditations, CONSTANTS.Certificates],
  [CONSTANTS.Accreditations, CONSTANTS.Classifications],
  [CONSTANTS.Accreditations, CONSTANTS.Classifications, CONSTANTS.Certificates],
  [CONSTANTS.Classifications],
  [CONSTANTS.Classifications, CONSTANTS.Certificates],
  [CONSTANTS.Certificates],
  [CONSTANTS.errorTypes],
  [CONSTANTS.errorTypes, CONSTANTS.Vendors],
];

type MANDATORY_FIELDS_TYPES = {
    [key : string]: string[]
}

// This is a map having mandatory fields in select report fields page based on category selection
export const COMPONENT_MANDATORY_FIELDS : MANDATORY_FIELDS_TYPES = {
    [CONSTANTS.Vendors] : ["1-1","1-2"],
    [CONSTANTS.Programs] : ["2-1","2-2"],
    [CONSTANTS.Accreditations] : ["2-14-1","2-14-2","4-1","4-2"],
    [CONSTANTS.Classifications] : ["2-14-3","5-1","5-2"],
    [CONSTANTS.Certificates] : ["2-14-6","2-14-7","6-1","6-2"],
}

export const SUCCESS_ACTIVITY_MANDATORY_FIELDS : MANDATORY_FIELDS_TYPES = {
    [CONSTANTS.Vendors] : ["7-6-1","7-6-2"],
    [CONSTANTS.Programs] : ["7-2-1","7-2-2"],
    [CONSTANTS.Accreditations] : ["7-4-1","7-4-2"],
    [CONSTANTS.Classifications] : ["7-1-5"],
    [CONSTANTS.Certificates] : ["7-4-6","7-4-7"]
}

export const ERROR_ACTIVITY_MANDATORY_FIELDS : MANDATORY_FIELDS_TYPES = {
    [CONSTANTS.Vendors] : ["8-1-1","8-1-2"],
    [CONSTANTS.Programs] : ["8-3-1","8-3-2"],
    [CONSTANTS.ErrorDetails] : ["8-7-1"],
    [CONSTANTS.learnerDetails] : ["8-2-1","8-2-4"]
}

type FLAT_NODES_KEY_TYPES = {
    [key : string]: string
}

// select fields page flatNodes key mapping with categories fields
export const FLAT_NODES_KEY : FLAT_NODES_KEY_TYPES = {
    [CONSTANTS.Vendors] : "1",
    [CONSTANTS.Programs] : "2",
    [CONSTANTS.Accreditations]: "4",
    [CONSTANTS.Classifications] : "5",
    [CONSTANTS.Certificates] : "6",
    [CONSTANTS.SuccessActivity]: "7",
    [CONSTANTS.errorTypes]: "8"
} 

// Excluded routes to clear state
export const excludedCustomRoutes: string[] = [Routes.CUSTOM_REPORT_ADD_SCHEDULER, Routes.CUSTOM_REPORT_EDIT_SCHEDULER, Routes.CUSTOM_REPORTS_SELECT_FIELDS,Routes.CUSTOM_REPORT_PREVIEW];