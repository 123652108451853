import React from "react";
import { useTranslation } from "react-i18next";
import NoRecordFoundWrapper from "./styled";

export const PageNotFound = () => {
  const { t } = useTranslation();

  return (
    <NoRecordFoundWrapper>

      <div className="noRecordFound">
        <h1>{t<string>("404")}</h1>
        <h2>{t<string>("404Text")}</h2>
      </div>
    </NoRecordFoundWrapper>
  );
};
export default PageNotFound;
