import { combineReducers } from "@reduxjs/toolkit";
import loaderReducer from "components/Loader/loader.slice";
import toastReducer from "components/Toast/toast.slice";
import classificationReducer from "pages/Classifications/ClassificationSlice";
import accreditationReducer from "pages/Accreditations/AccreditationSlice";
import certificationReducer from "pages/Certifications/CertificationSlice";
import programReducer from "pages/Programs/ProgramSlice";
import vendorReducer from "pages/Vendors/VendorSlice";
import learnerReducer from "pages/Learners/LearnerSlice";
import archiveLearnerListReducer from "pages/ArchiveLearnerList/LearnerSlice";
import learnerActivityReducer from "pages/ViewLearner/LearnerActivitySlice";
import userReducer from "pages/Users/UserSlice";
import userReduce from "pages/SSOLogin/user.slice";
import ActivityErrorListReducer from "pages/ErrorList/ErrorListSlice";
import activityLogReducer from "pages/ActivityLog/ActivitySlice";
import batchLogReducer from "pages/BatchLog/BatchLog.Slice";
import listActivity from "pages/ActivityList/ActivitySlice";
import auditLogReducer from "pages/AuditLog/AuditLogSlice";
import reportLogReducer from "pages/ReportLog/ReportLogSlice";
import customReportLogReducer from "pages/CustomReportLog/CustomReportLogSlice";
import vendorLogReducer from "pages/VendorLog/VendorLogSlice"
import predefinedReportReducer from "pages/Report/PredefinedReportSlice";
import archiveActivityReducer from "pages/ArchiveActivityList/ArchiveActivitySlice";
import archiveErrorListReducer from "pages/ArchiveErrorActivityList/ArchiveErrorActivityListSlice";
import reportSchedulerListReducer from "pages/ReportScheduler/SchedulerSlice";
import customReportReducer from "pages/Report/CustomReport/CustomReportSlice";
import adminLeftPanelReducer from "components/AdminLeftPanel/LeftPanelSlice";

const appReducer = combineReducers({
  loader: loaderReducer,
  toast: toastReducer,
  classification: classificationReducer,
  accreditation: accreditationReducer,
  certification: certificationReducer,
  user: userReducer,
  userManagement: userReduce,
  program: programReducer,
  vendor: vendorReducer,
  learner: learnerReducer,
  archivedLearners: archiveLearnerListReducer,
  learnerActivities: learnerActivityReducer,
  errorList: ActivityErrorListReducer,
  activityLog: activityLogReducer,
  batchLog: batchLogReducer,
  listActivity: listActivity,
  auditLog: auditLogReducer,
  reportLog: reportLogReducer,
  customReportLog: customReportLogReducer,
  vendorActivityLog: vendorLogReducer,
  predefinedReport: predefinedReportReducer,
  archivelistActivity: archiveActivityReducer,
  archiveErrorList: archiveErrorListReducer,
  reportSchedulerList: reportSchedulerListReducer,
  customReport: customReportReducer,
  adminLeftPanel : adminLeftPanelReducer,
});

export type RootState = ReturnType<typeof appReducer>;
const rootReducer = (state: any, action: any) => {
  if (action.type === "LOGGED_OUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
