import styled from "styled-components";

export const DeleteButtonWrapper = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 575px) {
    .btn-cxs {
        font-size: smaller;
        width: max-content;
        padding: 0.001rem 0.5rem;
    }
  }
`;
export default DeleteButtonWrapper;
