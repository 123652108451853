import { Routes } from "common/constants";
import { excludedCustomRoutes } from "common/constants/customReports";
import { clearCustomReportState } from "pages/Report/CustomReport/CustomReportSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";

function ClearCustomReportState({ location,history }: any) {
  const { pathname } = location;
  const dispatch = useDispatch();

  const [previousRoutes, setPreviousRoutes] = useState<string[]>([])

  // exclude certain routes to clear customReportState
  const excludedRoutes = excludedCustomRoutes; // Routes to exclude from clearing the state
  const shouldClearState = !(excludedRoutes.includes(pathname) || pathname.includes("/schedulers/custom/edit") || (previousRoutes[previousRoutes.length-2] === Routes.CUSTOM_REPORTS_SELECT_FIELDS));

  // set previous route in localstorage
  useEffect(() => {

    const unlisten = history.listen((location: any) => {
      setPreviousRoutes((prevRoutes: any) => [
        ...prevRoutes,
        location.pathname,
      ]);
    });

    return () => {
      unlisten();
    };
  },[history])

  useEffect(() => {
    if(previousRoutes.length > 0){
      localStorage.setItem("_prevRoute",previousRoutes[previousRoutes.length-2])
    }
  },[previousRoutes])

  useEffect(() => {
    if (shouldClearState) {
      dispatch(clearCustomReportState());
    }
  }, [location, shouldClearState, dispatch]);

  return null;
}

export default withRouter(ClearCustomReportState);
