import React from "react";
import { useTranslation } from "react-i18next";
import { InvalidUserWrapper } from "./style";
import AdminFooter from "components/AdminFooter";

const Global: any = global;

export const InvalidUser = () => {
  const { t } = useTranslation();
  const signout = () => {
    localStorage.removeItem("Authorization");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("apiPermissions");

    Global.userManager.signoutRedirect();
  };

  return (
    <InvalidUserWrapper>
      <div className="header d-flex">
        <img src={process.env.PUBLIC_URL + "/images/AHA_Full.svg"} alt="" aria-label={t<string>("American Heart Association Logo")}/>
        <div className="sign-out-div">
          <img src={process.env.PUBLIC_URL + "/images/sign-out.svg"} alt="" onClick={signout} aria-label={t<string>("sign out")}></img>
          <span onClick={signout}>{t<string>("Sign Out")}</span>
        </div>
      </div>
      <div className="access-denied d-flex">
        <img src={process.env.PUBLIC_URL + "/images/access-denied.svg"} alt="access-denied" aria-hidden />
        <h3><strong className="red-font">{t<string>("Sorry")}</strong>, {t<string>("you do not have permission to access the application, Please contact your")} <strong>{t<string>("Administrator")}</strong></h3>
      </div>
      <AdminFooter />
    </InvalidUserWrapper>
  );
};
export default InvalidUser;
