import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CertificationManagementState {
  pageNumber: number;
  search: string;
  pageSize: number;
  loader: boolean;
  pageOrder: string;
}

export const initialState: CertificationManagementState = {
  pageNumber: 1,
  search: "",
  pageSize: 25,
  loader: false,
  pageOrder:  "updated_at desc" ,
};

const certificationManagementSlice = createSlice({
  name: "certification",
  initialState,
  reducers: {
    setPageNumber: (
      state,
      action: PayloadAction<CertificationManagementState>
    ) => {
      state.pageNumber = action.payload.pageNumber;
    },
    searchCertification: (
      state,
      action: PayloadAction<CertificationManagementState>
    ) => {
      state.pageNumber = 1;
      state.search = action.payload.search;
    },
    setPageSize: (
      state,
      action: PayloadAction<CertificationManagementState>
    ) => {
      state.pageNumber = 1;
      state.pageSize = action.payload.pageSize;
    },
    setPageOrder: (state, action) => {
      state.pageOrder = action.payload.pageOrder;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
    clearCertificationState: () => {
      return initialState;
    },
  },
});
const { actions, reducer } = certificationManagementSlice;

export const {
  setPageNumber,
  setLoader,
  setPageOrder,
  searchCertification,
  setPageSize,
  clearCertificationState,
} = actions;
export default reducer;
