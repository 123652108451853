import React, {FunctionComponent} from "react";
import AdminFooterWrapper from "./styled";
import { useTranslation } from "react-i18next";

export const AdminFooter: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <AdminFooterWrapper className="relative">
      <span className="footer_text1">{t<string>("nationalCenter")}, </span>
      <span className="footer_text2"> {t<string>("adminFooterAddr2")} </span>
      <span className="paddinglr5">|</span>
      <span className="footer_text1">{t<string>("customerService")}:</span>
      <span className="footer_text2"> {t<string>("adminFooterAddr3")}</span>
      <br />
      <span className="footer_text2">&copy;2022 {t<string>("adminFooterAddr4")}</span>
    </AdminFooterWrapper>
  );
};
export default AdminFooter;
