import React, { useRef } from "react";
const Breadcrumb = (props: any) => {  
  const actionRef = useRef(props?.location?.state);
  const handleClick = (link: string) => {
    props.history.replace({ pathname: link, state: actionRef.current });
  };
  return (
    <nav aria-label="breadcrumb" className="d-md-block d-lg-block">
      <ul className="breadcrumb aha-breadcrumb pl-0 mt-3 pb-1">
        {props.paths.map((path: any, i: number) => (
          <li key={i} className="breadcrumb-item home">
            {path.link ? (
              <a
                href="#"
                title="Go to Home Page"
                onClick={(e: any) => {
                  handleClick(path.link);
                }}
              >
                {path.name}
              </a>
            ) : (
              <span>{path.name}</span>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default React.memo(Breadcrumb);
