import { showToast } from "components/Toast/toast.slice";
import store from "app/store";
import { setAuthStatus } from "app/authReducer";
import { ACCESS_DENIED, NOT_FOUND } from "./errors/errorMessages";

export const SessionExpired = async (history: any, dispatch: any) => {
  store.dispatch(
    showToast({ title: "Invalid Token", message: "Session Expired" })
  );
  dispatch(setAuthStatus({ isAuthenticated: false }));
  history.replace("/");
  sessionStorage.clear();
};

export const HandleCatch = async (error: {
  title?: string;
  message?: string;
}) => {
  store.dispatch(showToast(error));
};

export const handleAPIError = (error: any, history: any, dispatch: any) => {
  if (error.response) {
    try {
      const errorResponse =
        error.response.data.error?.activity?.error ?? error.response.data.error;
      if (errorResponse.code === ACCESS_DENIED.code) {
        SessionExpired(history, dispatch);
      } else {
        if(errorResponse.code === NOT_FOUND.code){
          return;
        } 
        if (errorResponse?.message || errorResponse?.title) {
          if(errorResponse.code === "BAD_REQUEST"){
            errorResponse.code = errorResponse.code.replace('_',' ');
          } 
          HandleCatch(errorResponse);
        } else {
          HandleCatch({
            title: "Bad Request",
            message: "Submit using proper valid data, please try again",
          });
        }
      }
    } catch (error1) {
      HandleCatch({
        title: "Server Error!",
        message: "Something went wrong, please try again later",
      });
    }
  } else {
    if (error.message === "Network Error") {
      HandleCatch({
        title: "Server Error!",
        message: "Something went wrong, please try again later",
      });
    }
  }
};
