import styled from "styled-components";

export const SSOLoginWrapper = styled.div`
body{overflow-x:hidden}
.aha-heart-logo.aha-contentone{background: url(../images/aha-logo.svg) no-repeat;background-size: 200px 109px;
  background-position: -10px 0px;width:200px;height:109px;}
.login-wrapper{margin: 40px auto;position: relative;max-height: 584px;max-width: 73.125rem;
  @media (min-width: 991.5px) and (max-width:1250px){padding-left: 1.875rem;padding-right: 1.875rem;}
  @media only screen and (max-device-width:991px){max-height:850px;max-width: 100%;
    padding-left: 1.875rem;padding-right: 1.875rem;margin-top:24px;} 
  @media (min-width: 576px) and (max-width: 767.5px){max-width: 100%;padding-left: 1.25rem;padding-right: 1.25rem;}
  @media (min-width: 320px)and (max-width: 575.5px){width: 100%;padding-left: 0.9375rem;padding-right: 0.9375rem;max-height: 730px;} 
overflow: hidden;
.left-content{
  .bg-red{z-index: 1;width: 100%;height: 100%;}
  .imgs-left{left:0px;z-index:1;}
  .imgone{height: 548px;background: #f8f8f8 url(../images/login-bg.png) no-repeat;background-position:-166% 32%;
    background-size:84.2%;width:100%;padding: 0px 1.25rem;margin: 0px auto;}  
  }
  .imgone.background-none{background:none;}
  button:disabled{
    cursor:not-allowed;
  }
.right-content{position: absolute;z-index: 1;background: #C10E21 url(../images/Aha-white-logo.png) no-repeat right 30px;
  color: #ffffff;border-radius: 20px;padding: 30px 30px 40px 30px;width: 48.2%;left:46%;top: 14%;box-shadow: rgb(0 0 0 / 16%) 0px 0px 18px;
  background-size:47%;
.login-contents{
  .login-para{font-size:20px;max-height: 210px;overflow-y: auto;padding-right: 10px;}
  .login-para::-webkit-scrollbar{width:12px;border-radius:6px;border:1px solid #F8F8F8;background:#F8F8F8;margin-top:20px;position:absolute;top:10px;}
  .login-para::-webkit-scrollbar-track {background: #F8F8F8;border-radius: 10px;  }
  .login-para::-webkit-scrollbar-thumb {background-color: #E3E3E3;border-radius:20px;border: 3px solid #F8F8F8;}
  .sso-btn{width:234px;height:50px;}
  .bgg{background:rgba(255, 0, 0, 0);width:100%;height:100%;}
  h1{font-weight: normal;font-size: 36px;}}
.submit-btn,.submit-btn:focus,.submit-btn:hover,.submit-btn:active{font-size:18px;color: #C10E21;background-color: #ffffff;border: 2px solid #C10E21;float: right;margin-top: 30px;padding: 0px;
    border-radius: 10em;outline:0;margin-bottom:11px;}
}
@media only screen and (min-device-width: 1100.5px) and (max-device-width:1149.5px){
  .left-content .imgone{background-position:-241% 9%;background-size: 88%;}
} 
@media only screen and (min-device-width:991.5px) and (max-device-width:1050.5px) {
  .right-content .login-contents .login-para{font-size:18px;}
}
@media only screen and (min-device-width: 991.5px) and (max-device-width:1100.5px){
  .left-content .imgone{background-position: -262% 0%;background-size: 89%;}
} 
@media only screen and (max-device-width:991px){
  .right-content{position:relative;left:auto;width:100%;top:65%;}
  .left-content{height:850px;margin-bottom: 447px;
  .imgone{background-position: 75% top;background-size: 104%;width:100%;}
}  }
} 
@media only screen and (min-device-width: 576px) and (max-device-width:767px){
  .right-content.right-content{top:57% !important;
  h1{font-size:26px !important;}  
}}

@media only screen and (max-device-width:575.5px){
  .login-wrapper .right-content{padding-left:20px;padding-right:20px;}
  .login-wrapper .right-content .login-contents .sso-btn{width:100%;height:41px;}
  .left-content .imgone{padding:0px 10px !important;background-position: 75% top !important;
    background-size: 120% !important;}
  .right-content{top:43% !important;
    h3{font-size:26px !important;}  
    .col-padin-mob{padding:0px;}
  } }

@media only screen and (max-device-width:480px){
  .right-content{top:40% !important;}
@media only screen and (max-device-width:380px){
  .right-content{top:30% !important;}
}
}

.progress-welcome {
  padding: 120px 0;
  @media only screen and (min-width: 576px) {
      padding: 180px 0;
  }
  * {
      color: #222328;
  }
  .progress-welcome-title {
      order: 2;
      @media only screen and (min-width: 576px) {
          order: 1;
      }
  }
  .progress-img {
      width: 95px;
      order: 1;
      @media only screen and (min-width: 576px) {
          width: 100px;
          order: 2;
      }
      @media only screen and (min-width: 768px) {
          width: 150px;
      }
  }
}
.progress-title {
  margin: 71px 0;
}
`;
export const AnotherWraper = styled.div``;
