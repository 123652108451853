export function getCommaSeparatedValues(item : any) : string {
    let result = ""
  
    for(let i=0;i<item?.length;i++){
      result = result+item[i].code
  
      if(i !== item.length-1){
        result += ", "
      }
    }
    
    return result
  }