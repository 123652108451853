// import 'polyfills'
import "whatwg-fetch";
import React from "react";
import { Provider } from "react-redux";
import store from "./app/store";
import App from "./app/App";
import "./i18n";
import { createRoot } from "react-dom/client";

// @ts-ignore
window.process = {};

const container = document.getElementById("root");

function render() {
  createRoot(container!).render(
    <Provider store={store}>
      <App />
    </Provider>
  );
}

render();

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./app/App", render);
}
