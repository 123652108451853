import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AccreditationManagementState {
  pageNumber: number;
  search: string;
  pageSize: number;
  loader: boolean;
  pageOrder: string;
}

export const initialState: AccreditationManagementState = {
  pageNumber: 1,
  search: "",
  pageSize: 25,
  loader: false,
  pageOrder: "updated_at DESC",
};

const accreditationManagementSlice = createSlice({
  name: "accreditation",
  initialState,
  reducers: {
    setPageNumber: (
      state,
      action: PayloadAction<AccreditationManagementState>
    ) => {
      state.pageNumber = action.payload.pageNumber;
    },
    searchAccreditation: (
      state,
      action: PayloadAction<AccreditationManagementState>
    ) => {
      state.pageNumber = 1;
      state.search = action.payload.search;
    },
    setPageSize: (
      state,
      action: PayloadAction<AccreditationManagementState>
    ) => {
      state.pageSize = action.payload.pageSize;
      state.pageNumber = 1;
    },
    setPageOrder: (state, action) => {
      state.pageOrder = action.payload.pageOrder;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
    clearAccreditationState: () => {
      return initialState;
    },
  },
});
const { actions, reducer } = accreditationManagementSlice;

export const {
  setPageNumber,
  setLoader,
  setPageOrder,
  searchAccreditation,
  setPageSize,
  clearAccreditationState,
} = actions;
export default reducer;
